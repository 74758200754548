import { Col, Row, Spin, Typography } from "antd";
import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import BannerImg from "../../assets/images/loginImage.svg";
import {
  fetchAccountByDomain,
  fetchApplications,
  switchApplication,
} from "../../redux/reducers/auth.slice";
import { LOGO_URL, RootState } from "../../shared/constants";
import { ROUTE_CRM_LOGIN } from "../../shared/routes/crm.routes.constants";
import "./index.less";

const { Title } = Typography;

function AuthLayout({
  children,
  otpSessionId,
  isotp,
}: {
  children: ReactNode;
  otpSessionId: any;
  isotp: boolean;
}) {
  const dispatch = useDispatch();
  const { account, applications } = useSelector(
    (state: RootState) => state.auth
  );
  const RouteParams: any = useParams();
  useEffect(() => {
    account?.id && localStorage.setItem("account_id", account?.id);
    account?.id && dispatch(fetchApplications());

    if (RouteParams["source"] !== "crm") {
      window.location.href = ROUTE_CRM_LOGIN(account.slug);
      return;
    }
  }, [account]);
  useEffect(() => {
    if (applications?.length > 0 && account?.id) {
      const application = applications?.filter((val: any) => {
        return (
          val.slug == RouteParams["source"] && val.account_id === account?.id
        );
      });
      if (application?.length > 0) {
        dispatch(
          switchApplication({
            slug: application[0].slug,
            application_id: application[0].id,
          })
        );
      }
    }

    // debugger
  }, [account, applications]);
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  useEffect(() => {
    dispatch(fetchAccountByDomain());
  }, []);

  return (
    <>
      <Row
        className={
          screenType.desktop ? "" : "padding20 " + "full-height auth-layout "
        }
        align="middle"
      >
        <Col span={14} xl={14} sm={0} xs={0}>
          <div
            className="main-image-wrapper"
            style={{
              backgroundImage: `url(${BannerImg})`,
              height: "100vh",
              backgroundColor: "#C1DAE8",
              backgroundSize: `cover`,
            }}
          />
        </Col>

        <Col xl={10} sm={24}>
          <Row>
            <Col
              span={18}
              xs={24}
              offset={screenType.desktop ? 3 : 0}
              style={screenType.desktop ? { maxWidth: "400px" } : {}}
            >
              {
                <>
                  <Title
                    className={screenType.desktop ? "bold" : "bold"}
                    level={3}
                    style={{ marginBottom: "0px" }}
                  >
                    Welcome To
                  </Title>
                  <img
                    src={LOGO_URL}
                    // width="130"
                    height={screenType.desktop ? "70" : "50"}
                  ></img>
                </>
              }
              {/* <Col span={0} xs={24} xl={0} xxl={0} sm={0}>
                {screenType.desktop ? (
                  <img src={BannerImg} style={{ width: "100%" }} />
                ) : !otpSessionId ? (
                  <LoginMobileBanner />
                ) : (
                  ""
                )}
              </Col> */}

              {!applications?.length || !account ? <Spin /> : children}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default AuthLayout;
