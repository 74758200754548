import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  List,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { ReactComponent as EditOutlinedIcon } from "../../../../src/assets/icons/editOutlinedIcon.svg";
import { ReactComponent as SuccessGreen } from "../../../../src/assets/icons/successGreen.svg";
import {
  delinkCallLog,
  fetchCalllogDetails,
  linkCallLog,
} from "../../../redux/reducers/calllogs.slice";
import { fetchContactListFiltered } from "../../../redux/reducers/contacts.slice";
import { fetchLeadsList } from "../../../redux/reducers/leads.slice";
import {
  fetchMasterTaskDropDown,
  fetchMasterTaskTypesLead
} from "../../../redux/reducers/master.slice";
import {
  contactNameFormat,
  formatDate,
  formatDropDownValue,
  titleCase,
} from "../../../shared/Utils/utilities";
import { RootState } from "../../../shared/constants";
import FormBar from "../../../shared/layouts/formbar.layout";
import PaginationLayout from "../../../shared/layouts/pagination.layout";
import ResultModalLayout from "../../../shared/layouts/resultmodal.layout";
import SpinLoadingLayout from "../../../shared/layouts/spinloading.layout";
import { ROUTE_CRM_LEAD_DETAILS } from "../../../shared/routes/crm.routes.constants";
import UpdateLeadForm from "../Leads/components/updateleadform";
const { Option } = Select;

const menuDetail = (item: any) => {
  return (
    <div className="more_profiles_dd">
      <div className="list-container">
        <div className="list-item">
          <p className="title">Follow Up Due</p>
          <p>Due on {item?.followupDate}</p>
        </div>
        <div className="list-item">
          <p className="title">Service Details</p>
          <p className="mb-0">{item?.serviceDetails?.type}</p>
          <p className="mb-0">{item?.serviceDetails?.doctor}</p>
          <p>Appt. Time: {item?.serviceDetails?.appt_time}</p>
        </div>
      </div>
    </div>
  );
};
type Props = {
  linkedStates?: any;
  phone?: string;
  callLogDetails?: any;
  action?: string;
  excludeLeadId?: string;
  onLinkTicketLinkContact?: (val: any) => void;
};
function AssociatedLeads({
  linkedStates,
  phone,
  callLogDetails = null,
  action = "",
  excludeLeadId = "",
  onLinkTicketLinkContact,
}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, leads, pagination } = useSelector(
    (state: RootState) => state.leads
  );
  const { loading_filtered, contactFiltered } = useSelector(
    (state: RootState) => state.contact
  );
  const [leadList, setLeadList] = useState([] as any);
  const [loadingModal, setLoadingModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const { taskDropDown, taskTypesLead } = useSelector(
    (state: RootState) => state.master
  );
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedForUpdate, setSelectedForUpdate] = useState(false);
  const [filters, setFilters] = useState(null as any);
  const [taskTypesList, setTaskTypes] = useState([] as any);
  const [leadStatusList, setLeadStatusList] = useState([] as any);
  const [formElements, setFormElements] = useState(null as any);
  const [contactList, setContactList] = useState([] as any);
  const [isModalVisibleLink, setisModalVisibleLink] = useState(false);
  const [callLogId, setcallLogId] = useState(null as any);
  const [showLoaderLinking, setshowLoaderLinking] = useState(true);
  const [linkLoaderMessage, setlinkLoaderMessage] = useState(null as any);
  const [successLoaderMessage, setsuccessLoaderMessage] = useState(null as any);
  let pageNumber =
    pagination?.offset && pagination?.limit
      ? pagination.offset / pagination.limit
      : 0;
  const pageChange = (e: number) => {
    pageNumber = e - 1;
    let filter = filters
      ? Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != null)
      )
      : {};
    phone !== "" &&
      dispatch(
        fetchLeadsList({
          phone: phone,
          offset: pageNumber * 10,
          limit: pagination.limit,
          ...(callLogDetails?.id
            ? {
              call_id: callLogDetails?.id,
              disable_scope: true,
            }
            : { disable_scope: true }),
          ...filter,
        })
      );
  };
  useEffect(() => {
    let filter = filters
      ? Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != null)
      )
      : {};
    phone !== "" &&
      dispatch(
        fetchLeadsList({
          phone: phone,
          offset: pagination.offset,
          limit: pagination.limit,
          ...(callLogDetails?.id
            ? {
              call_id: callLogDetails?.id,
              disable_scope: true,
            }
            : { disable_scope: true }),
          ...filter,
        })
      );
  }, [phone, filters]);

  useEffect(() => {
    phone !== "" &&
      dispatch(
        fetchContactListFiltered({ phone: phone, offset: 0, limit: 20 })
      );
  }, [phone]);
  useEffect(() => {
    phone !== "" &&
      contactFiltered?.length > 0 &&
      setContactList(
        contactFiltered.map((val: any) => {
          return {
            name: formatDropDownValue(val.first_name + " " + val.last_name),
            value: val.id,
          };
        })
      );
  }, [contactFiltered]);
  useEffect(() => {
    taskDropDown.length == 0 && dispatch(fetchMasterTaskDropDown());
    dispatch(fetchMasterTaskTypesLead({ mode: "lead" }));
  }, []);
  useEffect(() => {
    setTaskTypes(
      taskTypesLead?.map((val: any) => {
        return { name: formatDropDownValue(val.name), value: val.id };
      })
    );
  }, [taskTypesLead]);
  useEffect(() => {
    if (taskDropDown?.length > 0) {
      let temp = taskDropDown?.filter((val: any) => {
        return val.name == "status" && val.mode == "lead";
      });
      let vals = temp[0]?.values?.map((val: any) => {
        return { name: formatDropDownValue(val.name), value: val.id };
      });
      setLeadStatusList(vals);
    }
  }, [taskDropDown]);
  useEffect(() => {
    if (!loading) {
      let listData: any = [];
      leads.forEach((val: any) => {
        listData.push({
          ...val,
          title: val.svc_type.name,
          type: val.svc_sub_type.name,
          status_id: val.status_id,
          status_name: val.status.name,
          assignedTo: val.assigned_user?.first_name
            ? `${val.assigned_user?.first_name
              ? val.assigned_user?.first_name
              : ""
            } ${val.assigned_user?.last_name ? val.assigned_user?.last_name : ""
            }`
            : `Not Assigned`,
          contact_name: contactNameFormat(
            val.contact?.first_name,
            val.contact?.last_name,
            val.contact?.gender?.name,
            val.contact?.dob
          ),
          createdOn: formatDate(val.created_at),
          updatedOn: formatDate(val.updated_at),
          history: formatDate(val.history),
          action: action,
          // followupDate: '24 Sep\'21',
          // serviceDetails: {
          //     type:'Video Consultation',
          //     doctor: 'Sr. Sanchit Singhal',
          //     appt_time: '22 Sep\'21 10:00 AM'
          // }
        });
      });
      setLeadList(listData);
      // console.log("listData", listData);
    }
  }, [leads]);

  const fetchCallDet = async () => {
    await dispatch(fetchCalllogDetails(callLogDetails.id));
    setResultModal(false);
    setsuccessLoaderMessage(null);
  };

  function linkAction(id: string, display_id: any, action: string) {
    // console.log("clicked");
    if (action === "link") {
      onLinkTicketLinkContact && onLinkTicketLinkContact("true");
      setLoadingModal(true);
      setlinkLoaderMessage(
        `Linking Call Log #${callLogDetails?.call_id} with Ticket #${display_id}`
      );
      setcallLogId(display_id);
      let data = {
        call_log_id: callLogDetails.id,
        lead_id: id,
        type: "lead",
      };

      dispatch(linkCallLog(data)).then((val: any) => {
        if (val.payload.data.statusCode === 200) {
          setLoadingModal(false);
          setResultModal(true);
          setsuccessLoaderMessage(
            `Call Log #${callLogDetails?.call_id} Linked successfully with Ticket #${display_id}`
          );
        }
      });
    }
    if (action === "delink") {
      setLoadingModal(true);
      setcallLogId(display_id);
      setlinkLoaderMessage(
        `De-Linking Call Log #${callLogDetails?.call_id} with Ticket #${display_id}`
      );
      let selectedData = linked_leads.filter((val: any) => {
        return id === val.lead_id;
      });
      dispatch(delinkCallLog(selectedData[0].id)).then((val: any) => {
        if (val.payload.data.statusCode === 200) {
          setLoadingModal(false);
          setResultModal(true);
          setsuccessLoaderMessage(
            `Call Log #${callLogDetails?.call_id} De-Linked successfully with Ticket #${display_id}`
          );
        }
      });
    }
  }

  let linked_leads: any[] = [];
  if (callLogDetails) {
    linked_leads = callLogDetails?.links?.filter((val: any) => {
      return val.type === "lead";
    });
  }
  const updateLead = (item: any) => {
    // if (item.action !== "link_lead") {
    setSelectedForUpdate(item);
    setUpdateModal(true);
    // }
  };
  const parentCallbackUpdate = () => {
    setUpdateModal(false);
    dispatch(
      fetchLeadsList({
        phone: phone,
        offset: pagination.offset,
        limit: pagination.limit,
        ...(callLogDetails?.id
          ? {
            call_id: callLogDetails?.id,
            disable_scope: true,
          }
          : { disable_scope: true }),
      })
    );
  };

  function filterFormSubmit(changed: any, all: any) {
    let filtersObj = {} as any;
    for (let key of Object.keys(all)) {
      if (all[key] === "" || all[key] === undefined) {
      } else {
        if (key == "range") {
          filtersObj.from_date = all.range ? all.range[0].format() : null;
          filtersObj.to_date = all.range ? all.range[1].format() : null;
        } else {
          filtersObj[key] = all[key];
        }
      }
    }
    setFilters(filtersObj);
  }

  useEffect(() => {
    setFormElements({
      onFinish: () => { },
      onFinishFailed: () => { },
      onValuesChange: filterFormSubmit,
      initialValues: {},
      setValues: {},
      formElements: [
        {
          type: "Select",
          name: "status_id",
          colSpan: 5,
          allowClear: true,
          placeholder: "Status",
          options: leadStatusList,
        },
        {
          type: "Select",
          name: "svc_type_id",
          colSpan: 5,
          allowClear: true,
          placeholder: "Service Type",
          options: taskTypesList,
        },
        {
          type: "Select",
          name: "contact_id",
          colSpan: 5,
          allowClear: true,
          placeholder: "Contacts",
          options: contactList,
        },
        {
          type: "RangePicker",
          name: "range",
          colSpan: 8,
        },
      ],
    });
  }, [leadStatusList, taskTypesList, contactList]);

  const handleCancelLink = () => {
    setisModalVisibleLink(false);
  };
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  return (
    <>
      <Modal
        title={false}
        centered
        visible={isModalVisibleLink}
        closable={showLoaderLinking ? false : true}
        footer={false}
        onCancel={handleCancelLink}
      >
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            {showLoaderLinking ? (
              <LoadingOutlined style={{ fontSize: "64px", color: "#37C2D8" }} />
            ) : (
              <SuccessGreen />
            )}
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <p className="center headColor font20 ">
              Linking Call Log #{callLogDetails?.call_id}
              <br /> with Ticket #{callLogId}
            </p>
          </Col>
        </Row>
      </Modal>
      <Row
        className="bg-app crm-table associated-leads"
        align="middle"
        style={{ paddingTop: "14px" }}
      >
        <Col span={24}>
          <Row align="bottom">
            <Col
              {...{ xs: 12, sm: 12, md: 16, lg: 16, xl: 16 }}
              className="filter-bar"
            >
              <p className="paddingx20 bold-600 text-secondary">
                All leads associated with {phone}
              </p>
              {formElements && !screenType?.mobile && (
                <FormBar
                  name={"generic_filters"}
                  onFinish={formElements.onFinish}
                  onFinishFailed={formElements.onFinishFailed}
                  formElements={formElements.formElements}
                  initialValues={formElements.initialValues}
                  onValuesChange={formElements.onValuesChange}
                  buttonText={""}
                  setValues={
                    formElements.setValues ? formElements.setValues : null
                  }
                ></FormBar>
              )}
            </Col>
            <Col
              {...{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}
              className="paddingy15 text-right paddingx20 pagination-bar"
            >
              <PaginationLayout
                onChange={(e: any) => pageChange(e)}
                total={pagination.total}
                defaultCurrent={pageNumber + 1}
              ></PaginationLayout>
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{ maxHeight: "calc(100vh - 250px)", overflowY: "scroll" }}>
        {/* <div className='fixed-header-comp'> */}
        <Row>
          <Col span={24} className="padding-bottom-20 paddingx20">
            {!loading && (
              <List
                itemLayout="vertical"
                dataSource={leadList}
                renderItem={(item: any, index) =>
                  excludeLeadId !== item.id && (
                    <List.Item key={index}>
                      <Row align="middle">
                        <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                          <a
                            onClick={() =>
                              history.push(ROUTE_CRM_LEAD_DETAILS(item.id))
                            }
                            className="bold-600"
                          >
                            #{item.display_id}
                          </a>{" "}
                          <Divider type="vertical" className="dark"></Divider>{" "}
                          <span className="bold-600">
                            {item.display_name ? formatDropDownValue(item.display_name) : '-'}
                          </span>
                          {/* <Dropdown overlay={menuDetail(item)} placement="bottomLeft"><a className="drop-down-text text-secondary"><EyeFilled className="paddingl10"  /></a></Dropdown> */}
                          <br />
                          <>{item.contact_name}</>
                          <br />
                          <small>
                            <span className="text-secondary">
                              Service Type: {formatDropDownValue(item.type)}
                            </span>
                          </small>
                        </Col>
                        <Col
                          {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}
                          className="text-secondary"
                        >
                          <small className="capitalize">
                            Assigned To: {!screenType?.mobile && <br />}
                            {item.assignedTo}
                            <br />
                            Updated on: {item.updatedOn}
                          </small>
                        </Col>
                        {!screenType?.mobile && (
                          <Col span={8} className="text-right">
                            <small className="text-secondary mb-10 d-block text-right">
                              Created on {item.createdOn}
                            </small>
                            <Space size={20}>
                              <Button
                                style={{
                                  maxWidth: "150px",
                                  height: "auto",
                                  cursor: "default",
                                }}
                                onClick={() => updateLead(item)}
                              >
                                {item.status_name !== "follow-up" ? (
                                  <>
                                    {" "}
                                    {item.status_name !== "new"
                                      ? "Status: "
                                      : ""}{" "}
                                    {titleCase(item.status_name)}{" "}
                                    {
                                      // item.action !== "link_lead" &&
                                      <EditOutlinedIcon
                                        width={13.5}
                                        height={13.5}
                                        className="text-primary"
                                      />
                                    }
                                  </>
                                ) : (
                                  <>
                                    {item.status_name !== "new"
                                      ? "Status: "
                                      : ""}
                                    {titleCase(item.status_name)}{" "}
                                    {/* {item.action !== "link_lead" && ( */}
                                    <EditOutlinedIcon
                                      width={13.5}
                                      height={13.5}
                                      className="text-primary"
                                    />
                                    {/* )} */}
                                    <br />
                                    <small className="em text-secondary">
                                      {item?.history.length > 0 &&
                                        item?.history[item?.history.length - 1]
                                          ?.follow_up_date !== null
                                        ? formatDate(
                                          item?.history[
                                            item?.history.length - 1
                                          ]?.follow_up_date
                                        )
                                        : ""}
                                    </small>
                                  </>
                                )}
                              </Button>
                              {item.action == "link_lead" &&
                                linked_leads
                                  ?.map((val: any) => {
                                    return val.lead_id;
                                  })
                                  .indexOf(item.id) !== -1 && (
                                  <Button
                                    onClick={() =>
                                      linkAction(
                                        item.id,
                                        item.display_id,
                                        "delink"
                                      )
                                    }
                                    danger
                                    className="wide-btn "
                                    ghost
                                  >
                                    De-Link
                                  </Button>
                                )}
                              {item.action == "link_lead" &&
                                linked_leads
                                  ?.map((val: any) => {
                                    return val.lead_id;
                                  })
                                  .indexOf(item.id) == -1 &&
                                (linkedStates ? (
                                  <Button
                                    onClick={() =>
                                      linkAction(
                                        item.id,
                                        item.display_id,
                                        "link"
                                      )
                                    }
                                    type="primary"
                                    className="wide-btn crm-outline-btn"
                                    ghost
                                  >
                                    Link
                                  </Button>
                                ) : (
                                  <Tooltip
                                    title="Link a contact with phone number before linking ticket/lead"
                                    color={"#E1454E"}
                                    key={"#E1454E"}
                                  >
                                    <Button
                                      // disabled
                                      // onClick={() =>
                                      //   linkAction(
                                      //     item.id,
                                      //     item.display_id,
                                      //     "link"
                                      //   )
                                      // }
                                      type="primary"
                                      className="wide-btn crm-outline-btn"
                                      ghost
                                    >
                                      Link
                                    </Button>
                                  </Tooltip>
                                ))}
                            </Space>
                          </Col>
                        )}
                      </Row>
                    </List.Item>
                  )
                }
              />
            )}
            {loading && <Skeleton></Skeleton>}
          </Col>
        </Row>
      </div>
      {loadingModal && (
        <SpinLoadingLayout
          modalVisible={loadingModal}
          message={linkLoaderMessage}
        ></SpinLoadingLayout>
      )}
      {resultModal && (
        <ResultModalLayout
          type="success"
          modalVisible={resultModal}
          setModalVisible={(data) => fetchCallDet()}
          title={successLoaderMessage}
          subtitle="Call log status updated to closed"
        ></ResultModalLayout>
      )}
      {updateModal && (
        <Modal
          centered
          title={"Update Lead"}
          visible={updateModal}
          onCancel={() => setUpdateModal(false)}
          width={"1000px"}
          className="modalFormButton form-modal"
          footer={false}
          style={{ paddingBottom: "0px" }}
        >
          <UpdateLeadForm
            details={selectedForUpdate}
            layout="horizontal"
            parentCallback={parentCallbackUpdate}
          ></UpdateLeadForm>
        </Modal>
      )}
    </>
  );
}

export default AssociatedLeads;
