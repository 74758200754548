import {
  CloseOutlined,
  EditOutlined,
  MoreOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  PageHeader,
  Row,
  message,
} from "antd";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addNewContact,
  fetchContactList,
  patchContact,
} from "../../../redux/reducers/contacts.slice";
import {
  fetchMasterGender,
  fetchMasterNationality,
} from "../../../redux/reducers/master.slice";
import { dobToAge, formatDate } from "../../../shared/Utils/utilities";
import {
  TASK_MODULE_ACTIONS_HIDE_AGE,
  TASK_MODULE_ACTIONS_HIDE_BRANCH,
  TASK_MODULE_ACTIONS_HIDE_CALLING_NUMBER,
  TASK_MODULE_ACTIONS_HIDE_CLIENT_DOCTOR_CODE,
  TASK_MODULE_ACTIONS_HIDE_GENDER,
  TASK_MODULE_ACTIONS_HIDE_NATIONALITY,
  TASK_MODULE_ACTIONS_HIDE_THB_ID,
  TASK_MODULE_ACTIONS_HIDE_UHID,
  TASK_MODULE_ACTIONS_SHOW_BHQ,
  TASK_MODULE_ACTIONS_SHOW_CITY,
  TASK_MODULE_ACTIONS_SHOW_CLASS,
  TASK_MODULE_ACTIONS_SHOW_COUNTRY,
  TASK_MODULE_ACTIONS_SHOW_REVISED_UNIQUE_ROLE,
  TASK_MODULE_ACTIONS_SHOW_SPECIALIZATION,
  TASK_MODULE_ACTIONS_SHOW_STATE,
  TASK_MODULE_ACTIONS_SHOW_THERAPY_FIELD,
  TASK_MODULE_ACTIONS_UPDATE_CONTACT,
} from "../../../shared/components/permission.constants";
import { IS_JARVIS, RootState } from "../../../shared/constants";
import TablePhoneNumberCopier from "../../../shared/layouts/table-phone-number-copier.layout";
import TableLayout from "../../../shared/layouts/table.layout";
import { ROUTE_CRM_CONTACTS_DETAILS } from "../../../shared/routes/crm.routes.constants";
import CreateLeadForm from "../Leads/components/createleadform";
import AddTicket from "../Tickets/components/add-ticket";
import HeaderButtons from "../components/headerbuttons";
import CreateContactForm from "./components/createcontactform";
import NotificationSettings from "./components/notificationSettings";

function CRMContactsPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [editData, seteditData] = useState();
  const [actionType, setactionType] = useState("add");
  const [editId, seteditId] = useState();
  const [showNotificationSettings, setShowNotificationSettings] = useState(
    false as any
  );

  const { loading, contacts, pagination } = useSelector(
    (state: RootState) => state.contact
  );
  const { gender, nationality } = useSelector(
    (state: RootState) => state.master
  );

  let pageNumber =
    pagination?.offset && pagination?.limit
      ? pagination.offset / pagination.limit
      : 0;
  const onChange = (e: any) => {
    pageNumber = e - 1;
    let params = { offset: pageNumber * 10, limit: pagination.limit } as any;
    if (inputValue) {
      params.search_val = inputValue;
    }

    dispatch(fetchContactList(params));
  };
  const handleEditContact = (e: any, data: any) => {
    // alert('edit Clicked')
    seteditId(data?.id);
    seteditData(data);
    setactionType("edit");
    setmodalVisible(true);
  };

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  // console.log("myPermissions", pagination);

  useEffect(() => {
    // !contacts?.length && dispatch(fetchContactList({ offset: pagination.offset, limit: pagination.limit }))
    gender?.length == 0 && dispatch(fetchMasterGender({}));
    nationality?.length == 0 && dispatch(fetchMasterNationality({}));
  }, []);

  function handleSearch(selectedKeys: any, confirm: any, dataIndex: any) {
    confirm();
    switch (dataIndex) {
      case "name":
        break;
      case "phone":
        break;
      case "email":
        break;
      case "gender":
        break;
    }
    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex)
  }

  function handleReset(clearFilters: any) {
    clearFilters();
    // setSearchText('')
  }
  // let searchInput:any;
  let getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input.Group compact style={{ width: "300px" }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ display: "inline-block", width: "235px" }}
          />
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            type="primary"
            icon={<SearchOutlined />}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon={<CloseOutlined />}
          />
        </Input.Group>
        {/* <Input
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space> */}
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  // console.log(
  //   "contact",
  //   myPermissions?.indexOf("task_module") !== -1,
  //   myPermissions?.indexOf(TASK_MODULE_ACTIONS_UPDATE_CONTACT) !== -1
  // );

  const columns = [
    {
      title: t("CRM.Contacts.Fields.Contact Name"),
      key: "name",
      width: 200,
      fixed: "left",
      className: "capitalize",
      render: (a: any, record: any) => {
        return (
          <>
            <a
              onClick={() =>
                history.push(ROUTE_CRM_CONTACTS_DETAILS(record.id))
              }
            >
              {a.first_name} {a.last_name}
            </a>{" "}
            {myPermissions?.indexOf("task_module") !== -1 ||
            myPermissions?.indexOf(TASK_MODULE_ACTIONS_UPDATE_CONTACT) !==
              -1 ? (
              <a
                onClick={(e) => {
                  handleEditContact(e, record);
                }}
              >
                <EditOutlined />{" "}
              </a>
            ) : (
              ""
            )}{" "}
            {a?.patient?.uhid && (
              <>
                <br />
                <small>
                  {t("CRM.Common.Fields.UHID")}: {a?.patient?.uhid}
                </small>
              </>
            )}
          </>
        );
      },
      // { !a.patient?.uhid && <a onClick={(e)=>{handleEditContact(e,record)}} href="#"><EditOutlined /> </a>}
    },
    {
      title: t("CRM.Common.Fields.Phone No."),
      width: 200,
      render: (a: any) => {
        return (
          <>
            <TablePhoneNumberCopier
              phone={"+" + a?.country_code + a?.phone}
            ></TablePhoneNumberCopier>
            {/* {a.alternate_phone && <><br />
                        <TablePhoneNumberCopier phone={'+' + a.country_code + a.alternate_phone}></TablePhoneNumberCopier></>
                        } */}
          </>
        );
      },
    },

    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_CALLING_NUMBER) !== -1
      ? []
      : [
          {
            title: "Calling Number",
            width: 150,
            render: (a: any) => {
              return <>{a?.alternate_phone ? a?.alternate_phone : "-"}</>;
            },
          },
        ]),
    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_UHID) !== -1
      ? []
      : [
          {
            title: t("CRM.Common.Fields.UHID"),
            width: 150,
            render: (a: any) => {
              return <>{a?.uhid ? a?.uhid : "-"}</>;
            },
          },
        ]),
    {
      title: t("CRM.Common.Fields.Email ID"),
      width: 150,
      dataIndex: "email",
    },
    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_BRANCH) !== -1
      ? []
      : [
          {
            title: t("CRM.Common.Fields.Branch"),
            width: 150,
            render: (a: any) => {
              return <>{a?.branch?.name ? a?.branch?.name : "-"}</>;
            },
          },
        ]),
    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_THB_ID) !== -1
      ? []
      : [
          {
            title: "THB Id",
            width: 150,
            render: (a: any) => {
              return <>{a?.thb_id ? a?.thb_id : "-"}</>;
            },
          },
        ]),
    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_CLIENT_DOCTOR_CODE) !== -1
      ? []
      : [
          {
            title: "Client Doctor Code",
            width: 150,
            render: (a: any) => {
              return <>{a?.uhid ? a?.uhid : "-"}</>;
            },
          },
        ]),

    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_REVISED_UNIQUE_ROLE) !== -1
      ? [
          {
            title: "Revised unique role",
            width: 150,
            render: (a: any) => {
              return (
                <>{a?.revised_unique_role ? a?.revised_unique_role : "-"}</>
              );
            },
          },
        ]
      : []),

    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_THERAPY_FIELD) !== -1
      ? [
          {
            title: "Therapy field",
            width: 150,
            render: (a: any) => {
              return <>{a?.therapy_field ? a?.therapy_field : "-"}</>;
            },
          },
        ]
      : []),

    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_GENDER) !== -1
      ? []
      : [
          {
            title: t("CRM.Common.Fields.Gender"),
            width: 100,
            render: (a: any) => {
              return <>{a.gender?.name}</>;
            },
          },
        ]),
    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_AGE) !== -1
      ? []
      : [
          {
            title: t("CRM.Common.Fields.Age"),
            width: 100,
            render: (a: any) => {
              return <>{a.dob ? dobToAge(a.dob) : ""}</>;
            },
          },
        ]),
    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_NATIONALITY) !== -1
      ? []
      : [
          {
            title: t("CRM.Common.Fields.Nationality"),
            width: 150,
            render: (a: any) => {
              return <>{a.nationality?.name}</>;
            },
          },
        ]),
    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_COUNTRY) !== -1
      ? [
          {
            title: "Country",
            width: 150,
            render: (a: any) => {
              return <>{a?.country ? a?.country : "-"}</>;
            },
          },
        ]
      : []),
    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_STATE) !== -1
      ? [
          {
            title: "State",
            width: 150,
            render: (a: any) => {
              return <>{a?.state ? a?.state : "-"}</>;
            },
          },
        ]
      : []),
    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_CITY) !== -1
      ? [
          {
            title: "City",
            width: 120,
            render: (a: any) => {
              return <>{a?.city ? a?.city : "-"}</>;
            },
          },
        ]
      : []),

    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_CLASS) !== -1
      ? [
          {
            title: "Class",
            width: 100,
            render: (a: any) => {
              return <>{a?.class ? a?.class : "-"}</>;
            },
          },
        ]
      : []),
    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_SPECIALIZATION) !== -1
      ? [
          {
            title: "Specialization",
            width: 150,
            render: (a: any) => {
              return <>{a?.specialization ? a?.specialization : "-"}</>;
            },
          },
        ]
      : []),
    ...(myPermissions?.indexOf("task_module") !== -1 ||
    myPermissions?.indexOf(TASK_MODULE_ACTIONS_SHOW_BHQ) !== -1
      ? [
          {
            title: "BHQ",
            width: 100,
            render: (a: any) => {
              return <>{a?.city ? a?.city : "-"}</>;
            },
          },
        ]
      : []),

    {
      title: t("CRM.Common.Fields.Created On"),
      width: 150,
      render: (a: any) => {
        return <>{formatDate(a.created_at)}</>;
      },
    },
    {
      title: t("CRM.Common.Fields.Action"),
      width: 260,
      fixed: "right",
      render: (a: any) => {
        return (
          // <Space size={16}>
          //     <a type="link" className='bold-600 text-primary' onClick={()=>ticketFormOpen(a)} >{t('CRM.Common.Actions.Create Ticket')}</a>
          //     <a type="link" className='bold-600 text-success' onClick={()=>leadFormOpen(a)}>{t('CRM.Common.Actions.Create Lead')}</a>
          // </Space>
          <>
            <HeaderButtons
              setLeadFormVisible={leadFormOpen}
              setTicketFormVisible={ticketFormOpen}
              link={true}
              contactData={a}
            />
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={() => openNotificationSettings(a)}>
                    Notification Settings
                  </Menu.Item>
                </Menu>
              }
            >
              <Button icon={<MoreOutlined />} type="link"></Button>
            </Dropdown>
          </>
        );
      },
    },
  ];
  function openNotificationSettings(contact: any) {
    setShowNotificationSettings(true);
    setSelectedContact(contact);
  }
  function leadFormOpen(contact: any) {
    setSelectedContact(contact === true ? false : contact);
    setLeadFormVisible(true);
  }
  function ticketFormOpen(contact: any) {
    setSelectedContact(contact === true ? false : contact);
    setTicketFormVisible(true);
  }

  const [modalVisible, setmodalVisible] = useState(false);
  const [leadFormVisible, setLeadFormVisible] = useState(false);
  const [ticketFormVisible, setTicketFormVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState(false as any);
  const [formLoading, setFormLoading] = useState(false);

  const onSaveContact = (values: any) => {
    values = { ...values, country_code: "91" };
    if (IS_JARVIS) {
      values.first_name = "Project ";
    }

    setFormLoading(true);
    dispatch(addNewContact(values)).then((val: any) => {
      setFormLoading(false);
      if (val?.payload?.data?.statusCode == 200) {
        setmodalVisible(false);
        message.success({
          content: t("CRM.Contacts.Messages.Contact created successfully"),
          style: {
            marginBottom: "10vh",
          },
        });
        dispatch(
          fetchContactList({ offset: pageNumber * 10, limit: pagination.limit })
        );
      }
    });
  };
  const onSaveEditContact = (values: any) => {
    values = { ...values, country_code: "91" };
    if (IS_JARVIS) {
      values.first_name = "Project ";
    }
    setFormLoading(true);
    dispatch(patchContact({ id: editId, data: values })).then((val: any) => {
      setFormLoading(false);
      if (val?.payload?.data?.statusCode == 200) {
        setmodalVisible(false);
        message.success({
          content: t("Contact Updated successfully"),
          style: {
            marginBottom: "10vh",
          },
        });
        dispatch(
          fetchContactList({ offset: pageNumber * 10, limit: pagination.limit })
        );
      }
    });
  };
  const onSaveContactFailed = (errorInfo: any) => {
    //console.log('Failed:', errorInfo);
  };

  const [inputValue, setinputValue] = useState("" as any);

  const filterFormSubmit = (e: any) => {
    let filterValue = e.target.value;
    setinputValue(filterValue);
  };

  useEffect(() => {
    // debugger
    // This effect runs whenever the inputValue changes.
    const delay = 1000; // Adjust the delay time (in milliseconds) as needed.
    let timeoutId = null as any;

    if (inputValue) {
      // Clear the previous timeout if there was any.
      clearTimeout(timeoutId);

      // Set a new timeout to call the API after the delay.
      timeoutId = setTimeout(() => {
        callingContact();
      }, delay);
    } else {
      timeoutId = setTimeout(() => {
        dispatch(
          fetchContactList({ offset: pageNumber * 10, limit: pagination.limit })
        );
      }, delay);
    }

    // Clean up the timeout when the component unmounts or when inputValue changes.
    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputValue]);

  const callingContact = async () => {
    await dispatch(
      fetchContactList({
        search_val: inputValue,
        offset: 0,
        limit: 10,
      })
    );
  };

  const handleButtonEvt = () => {
    setmodalVisible(true);
    setactionType("add");
  };
  return (
    <Fragment>
      <PageHeader
        ghost={false}
        title={t("CRM.Contacts.Titles.Contact List")}
        extra={
          <HeaderButtons
            setLeadFormVisible={leadFormOpen}
            setTicketFormVisible={ticketFormOpen}
          />
        }
      ></PageHeader>
      <Row className="padding10">
        <Col span={24}>
          <TableLayout
            maxWidth={1200}
            defaultCurrentPage={pageNumber + 1}
            filterFormSubmit={filterFormSubmit}
            loading={loading}
            dataSource={contacts}
            columns={columns}
            onChange={(e) => onChange(e)}
            buttonEvt={() => handleButtonEvt()}
            type="contactsfilter"
            total={pagination.total}
            pagination={{ position: ["none", "none"] }}
            offsetHeight={180}
          />
        </Col>
      </Row>
      {modalVisible && (
        <CreateContactForm
          actionType={actionType}
          editData={editData}
          onSaveContact={(e: any) => onSaveContact(e)}
          onSaveEditContact={(e: any) => onSaveEditContact(e)}
          onSaveContactFailed={(e: any) => onSaveContactFailed(e)}
          modalVisible={modalVisible}
          setmodalVisible={(e: any) => setmodalVisible(e)}
          formLoading={formLoading}
          gender={gender}
          nationality={nationality}
          buttonText={
            actionType === "edit"
              ? `Edit ${t("CRM.Contacts.contact")}`
              : `Create ${t("CRM.Contacts.contact")}`
          }
        ></CreateContactForm>
      )}
      {leadFormVisible && (
        <CreateLeadForm
          saveLeadSuccess={() => setLeadFormVisible(false)}
          setLeadFormVisible={(val: any) => setLeadFormVisible(val)}
          leadFormVisible={leadFormVisible}
          phone={selectedContact?.phone}
          defaultContactId={selectedContact?.id}
        ></CreateLeadForm>
      )}
      {ticketFormVisible && (
        <AddTicket
          onTicketSave={() => setTicketFormVisible(false)}
          setTicketFormVisible={(val: any) => setTicketFormVisible(val)}
          ticketFormVisible={ticketFormVisible}
          phoneNumber={selectedContact?.phone}
          defaultContactId={selectedContact?.id}
        ></AddTicket>
      )}
      {showNotificationSettings && (
        <NotificationSettings
          contactDetails={selectedContact}
          modalVisible={showNotificationSettings}
          closeModal={() => {
            setShowNotificationSettings(false);
            setSelectedContact(null);
          }}
        />
      )}
    </Fragment>
  );
}

export default CRMContactsPage;
