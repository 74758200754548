import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Modal,
  PageHeader,
  Row,
  Space,
  Tooltip,
  message,
} from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useParams } from "react-router-dom";
import {
  createKnowledgeBank,
  fetchKnowledgeBankDocumentChunks,
  fetchKnowledgeBankDocumentList,
  fetchKnowledgeBankDocumentURL,
  patchKnowledgeBankDocument,
} from "../../../redux/reducers/knowledge-bank.slice";
import { formatDate } from "../../../shared/Utils/utilities";
import TableLayout from "../../../shared/layouts/table.layout";
import UploadDocumentComponent from "./upload-file.component";

function KnowledgeBankDocumentListPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();

  const [listData, setListData] = useState([] as any);
  const [newDocumentModal, setNewDocumentModal] = useState(false as any);
  const [chunkData, setChunkData] = useState(null as any);
  const [chunkModal, setChunkModal] = useState(false as any);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    total: 0,
  });
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  useEffect(() => {
    params?.id && loadList();
  }, []);
  const loadList = () => {
    dispatch(fetchKnowledgeBankDocumentList(params?.id)).then((val: any) => {
      if (val?.payload?.data) {
        setListData(val?.payload?.data);
        setPagination({
          offset: 0,
          limit: 10,
          total: val?.payload?.data?.length,
        });
      }
    });
  };
  const columns: any = [
    {
      title: "Name",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "capitalize",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (a: any) => formatDate(a),
    },
    {
      title: "Enabled",
      dataIndex: "disabled",
      key: "disabled",
      render: (a: any) => (a ? "No" : "Yes"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: any) => (
        <>
          {record?.status == "completed" && (
            <Space>
              <Tooltip title="View Document">
                <Button
                  onClick={() => {
                    dispatch(
                      fetchKnowledgeBankDocumentURL(record?.file_id)
                    ).then((val: any) => {
                      if (val?.payload?.data?.url) {
                        window.open(val?.payload?.data?.url);
                      }
                    });
                  }}
                  type="link"
                  icon={<EyeOutlined />}
                ></Button>
              </Tooltip>
              <Tooltip title="View Document Snippets">
                <Button
                  onClick={() => {
                    dispatch(
                      fetchKnowledgeBankDocumentChunks(record?.file_id)
                    ).then((val: any) => {
                      if (val?.payload?.data?.chunks) {
                        setChunkData({
                          document: record,
                          chunks: val?.payload?.data?.chunks,
                        });
                        setChunkModal(true);
                      }
                    });
                  }}
                  type="link"
                  icon={<FileSearchOutlined />}
                ></Button>
              </Tooltip>
              <Tooltip title={record?.disabled ? "Enabled" : "Disable"}>
                <Button
                  type="link"
                  onClick={() => {
                    // toggleDocumentStatus(record);
                    dispatch(
                      patchKnowledgeBankDocument({
                        id: record?.file_id,
                        body: { disabled: !record?.disabled },
                      })
                    ).then((val: any) => {
                      if (val?.payload?.data) {
                        message.success("Document Updated Successfully");
                        loadList();
                      }
                    });
                  }}
                  icon={
                    record?.disabled ? (
                      <CheckCircleOutlined />
                    ) : (
                      <CloseCircleOutlined />
                    )
                  }
                ></Button>
              </Tooltip>
            </Space>
          )}
        </>
      ),
    },
  ];
  const loading = false;

  //   const listData = [] as any;
  const formElements = {
    onFinish: () => {},
    onFinishFailed: () => {},
    onValuesChange: () => {},

    filtersApplied: 0,
    initialValues: {},
    setValues: {} as any,
    formElements: [],
  };

  const onCreateDocument = (values: any) => {
    const payload = {
      ...values,
      knowledge_type: "document",
      tags: [],
      disabled: false,
    };
    dispatch(createKnowledgeBank(payload)).then((val: any) => {
      if (val?.payload?.data) {
        setNewDocumentModal(false);
        message.success("Document added successfully");
        loadList();
      }
    });
  };

  return (
    <>
      <Fragment>
        <PageHeader
          ghost={false}
          onBack={() => history.goBack()}
          title={
            <Row
              style={screenType.mobile ? { paddingLeft: "25px" } : {}}
              align="middle"
            >
              <Col className="title-text">
                {params?.bank_name} &gt; Document List
              </Col>
            </Row>
          }
          extra={
            <Row align="middle">
              <Col className="title-text">
                <Button
                  key="1"
                  className="ant-btn-hover"
                  onClick={() => {
                    setNewDocumentModal(true);
                  }}
                >
                  Add New Document
                </Button>
              </Col>
            </Row>
          }
        ></PageHeader>

        <Row className="padding10 paddingx20 ">
          <Col span={24}>
            {columns && (
              <TableLayout
                // defaultCurrentPage={pagination.offset / pagination.limit + 1}
                loading={loading}
                buttonEvt={() => {}}
                dataSource={listData}
                // filters="multiple"
                columns={columns}
                // onChange={(e, ee) => onPageChange(e, ee)}
                type="null"
                total={pagination.total}
                showTotal={true}
                pagination={{
                  position: ["topRight"],
                  pageSize: pagination.limit,
                }}
                // formData={formElements}
                maxWidth={1000}
                defaultPageSize={10}
                showSizeChangerFlag={false}
              />
            )}
          </Col>
        </Row>
        {chunkModal && (
          <Modal
            visible={chunkModal}
            onCancel={() => {
              setChunkModal(false);
              setChunkData(null);
            }}
            footer={false}
            className="form-modal crm-modal"
            title={chunkData?.document?.file_name}
          >
            <div
              className="pd20 crm-chat-widget"
              style={{
                background: "#fafafe",
                maxHeight: "90vh",
                overflowY: "auto",
              }}
            >
              {chunkData?.chunks?.map((val: any, index: number) => (
                <Row align="middle">
                  <Col span={2}>
                    <Avatar className="mb20">{index + 1}</Avatar>
                  </Col>
                  <Col span={22}>
                    <div className="snippet-container">{val?.chunk_text}</div>
                  </Col>
                </Row>
              ))}
            </div>
          </Modal>
        )}
        {newDocumentModal && (
          <UploadDocumentComponent
            formVisible={newDocumentModal}
            setFormVisible={setNewDocumentModal}
            reloadList={() => loadList()}
            knowledge_bank_id={params?.id}
          />
        )}
      </Fragment>
    </>
  );
}
export default KnowledgeBankDocumentListPage;
