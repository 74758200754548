import { Button, Col, message, PageHeader, Row, Space } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addNewActionMap, editActionMap, fetchActionMap } from '../../../redux/reducers/actionmap.slice';
import { RootState } from '../../../shared/constants';
import TableLayout from '../../../shared/layouts/table.layout';
import { formatDropDownValue } from '../../../shared/Utils/utilities';
import CreateAction from './components/createAction';

function ActionMap() {
    const dispatch = useDispatch();
    const [actionType, setactionType] = useState('add')
    const [editData, seteditData] = useState()
    const [editId, seteditId] = useState('')

    const { loading, actionmap, pagination } = useSelector((state: RootState) => state.actionmap);

    const { gender, nationality } = useSelector((state: RootState) => state.master);

    let pageNumber =
        pagination?.offset && pagination?.limit
            ? pagination.offset / pagination.limit
            : 0;
    const onChange = (e: any) => {
        pageNumber = e - 1;
        // dispatch(fetchActionMap())

        dispatch(fetchActionMap({ offset: pageNumber * 10, limit: pagination.limit }))
    }

    const handleEdit = (value: any) => {
        setactionType('edit')
        setmodalVisible(true)
        seteditData(value)
        seteditId(value.id)
    }

    const columns = [
        {
            title: 'Mode',
            dataIndex: 'mode',
            render: (a: any) => { return (<>{a}</>) }

        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (a: any) => { return (<>{a?.name}</>) }

        },
        {
            title: 'Subtype',
            dataIndex: 'sub_type',
            render: (a: any) => { return (<>{a?.name}</>) }

        },

        {
            title: 'Category',
            dataIndex: 'category',
            render: (a: any) => { return (<>{a?.name}</>) }

        },
        {
            title: 'Display Name',
            dataIndex: 'display_name',
            render: (a: any) => { return (<>{formatDropDownValue(a)}</>) }

        },
        {
            title: 'Action Name',
            dataIndex: 'action_name',
            // render: (a: any) => { return (<>{a?.name}</>) }

        },
        {
            title: 'Event Name',
            dataIndex: 'event_name',
            // render: (a: any) => { return (<>{a?.name}</>) }

        },
        {
            title: 'Action',
            render: (a: any) => {
                return (
                    <Space>
                        <Button onClick={() => { handleEdit(a) }}  >Edit</Button>
                    </Space>
                )
            }
        },
    ]
    const [modalVisible, setmodalVisible] = useState(false);
    const [formLoading, setFormLoading] = useState(false);

    const onSaveContact = (values: any) => {
        values = { ...values, ui_actions_dropdown: values?.tagsStateUI?.tags, comm_actions: values?.tagsStateComm?.tagsComm }
        delete values.tagsStateUI;
        delete values.tagsStateComm;
        console.log("values", values)
        setFormLoading(true);
        dispatch(addNewActionMap(values)).then((val: any) => {
            setFormLoading(false);
            if (val?.payload?.data?.statusCode === 200) {
                setmodalVisible(false);
                message.success({
                    content: 'Assignment created successfully',
                    style: {
                        marginBottom: '10vh',
                    },
                });
                // dispatch(fetchActionMap())
                dispatch(fetchActionMap({ offset: pageNumber * 10, limit: pagination.limit, }))
            }
        })
    };

    const onEditContact = (values: any) => {
        values = { ...values, ui_actions_dropdown: values?.tagsStateUI?.tags, comm_actions: values?.tagsStateComm?.tagsComm }
        delete values.tagsStateUI;
        delete values.tagsStateComm;
        // console.log("values", values)
        setFormLoading(true);
        dispatch(editActionMap({ id: editId, data: values })).then((val: any) => {
            setFormLoading(false);
            if (val?.payload?.data?.statusCode === 200) {
                setmodalVisible(false);
                message.success({
                    content: 'Action updated successfully',
                    style: {
                        marginBottom: '10vh',
                    },
                });
                dispatch(fetchActionMap({ offset: pageNumber * 10, limit: pagination.limit, }))
            }
        })
    };



    const onSaveContactFailed = (errorInfo: any) => {
        //console.log('Failed:', errorInfo);
    };

    const handleBtnEvent = () => {
        setmodalVisible(true)
        setactionType('add')
    }

    useEffect(() => {
        // dispatch(fetchActionMap())
        dispatch(fetchActionMap({ offset: pagination.offset, limit: pagination.limit, }))

    }, [])


    return (
        <Fragment>
            <PageHeader ghost={false}
                title='Action'
            >
            </PageHeader>
            <Row className='padding10'>
                <Col span={24}>
                    <TableLayout
                        loading={loading}
                        defaultCurrentPage={pageNumber + 1}
                        dataSource={actionmap}
                        columns={columns}
                        onChange={(e) => onChange(e)}
                        buttonEvt={() => handleBtnEvent()}
                        type='actionfilter'
                        total={pagination.total}
                        pagination={{ position: ['none', 'none'] }} />
                </Col>
            </Row>
            {modalVisible && <CreateAction editData={editData} typess={actionType} onEditContact={(e: any) => onEditContact(e)} onSaveContact={(e: any) => onSaveContact(e)} onSaveContactFailed={(e: any) => onSaveContactFailed(e)} modalVisible={modalVisible} setmodalVisible={(e: any) => setmodalVisible(e)} formLoading={formLoading} gender={gender} nationality={nationality}></CreateAction>}
        </Fragment>
    );
}

export default ActionMap;
