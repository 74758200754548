import {
  Button,
  Col,
  Divider,
  List,
  Modal,
  Row,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { ReactComponent as EditOutlinedIcon } from "../../../../src/assets/icons/editOutlinedIcon.svg";
import {
  delinkCallLog,
  fetchCalllogDetails,
  linkCallLog,
} from "../../../redux/reducers/calllogs.slice";
import { fetchContactListFiltered } from "../../../redux/reducers/contacts.slice";
import {
  fetchMasterTaskDropDown,
  fetchMasterTaskTypesTicket
} from "../../../redux/reducers/master.slice";
import { fetchTicketsList } from "../../../redux/reducers/tickets.slice";
import {
  contactNameFormat,
  formatDate,
  formatDropDownValue,
} from "../../../shared/Utils/utilities";
import { RootState } from "../../../shared/constants";
import FormBar from "../../../shared/layouts/formbar.layout";
import PaginationLayout from "../../../shared/layouts/pagination.layout";
import ResultModalLayout from "../../../shared/layouts/resultmodal.layout";
import { ROUTE_CRM_TICKET_DETAILS } from "../../../shared/routes/crm.routes.constants";
import UpdateTicketForm from "../Tickets/components/updateticketform";

type Props = {
  phone?: string;
  action?: string;
  callLogDetails?: any;
  excludeTicketId?: string;
  onLinkTicketLinkContact?: (val: any) => void;
  linkedStates?: any;
};

function TicketsTab({
  linkedStates,
  phone,
  action = "",
  callLogDetails = null,
  excludeTicketId = "",
  onLinkTicketLinkContact,
}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, tickets, pagination } = useSelector(
    (state: RootState) => state.tickets
  );
  const { taskTypesTicket, taskDropDown } = useSelector(
    (state: RootState) => state.master
  );
  const { loading_filtered, contactFiltered } = useSelector(
    (state: RootState) => state.contact
  );

  const [ticketList, setTicketList] = useState([] as any);
  const [loadingModal, setLoadingModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [filters, setFilters] = useState(null as any);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedForUpdate, setSelectedForUpdate] = useState(false);
  const [ticketStatusList, setTicketStatusList] = useState([] as any);
  const [taskTypesList, setTaskTypes] = useState([] as any);
  const [formElements, setFormElements] = useState(null as any);
  const [contactList, setContactList] = useState([] as any);
  const { t } = useTranslation();
  let pageNumber =
    pagination?.offset && pagination?.limit
      ? pagination.offset / pagination.limit
      : 0;
  const pageChange = (e: number) => {
    pageNumber = e - 1;
    let filter = filters
      ? Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != null)
      )
      : {};
    phone !== "" &&
      dispatch(
        fetchTicketsList({
          phone: phone,
          offset: pageNumber * 10,
          limit: pagination.limit,
          ...(callLogDetails?.id
            ? {
              call_id: callLogDetails?.id,
              disable_scope: true,
            }
            : { disable_scope: true }),
          ...filter,
        })
      );
  };
  useEffect(() => {
    let filter = filters
      ? Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != null)
      )
      : {};
    phone !== "" &&
      dispatch(
        fetchTicketsList({
          phone: phone,
          offset: pagination.offset,
          limit: pagination.limit,
          ...(callLogDetails?.id
            ? {
              call_id: callLogDetails?.id,
              disable_scope: true,
            }
            : { disable_scope: true }),
          ...filter,
        })
      );
  }, [phone, filters]);
  console.log("ticketList", ticketList)
  useEffect(() => {
    phone !== "" &&
      dispatch(
        fetchContactListFiltered({ phone: phone, offset: 0, limit: 20 })
      );
  }, [phone]);
  useEffect(() => {
    phone !== "" &&
      contactFiltered?.length > 0 &&
      setContactList(
        contactFiltered.map((val: any) => {
          return {
            name: formatDropDownValue(val.first_name + " " + val.last_name),
            value: val.id,
          };
        })
      );
  }, [contactFiltered]);
  useEffect(() => {
    taskDropDown.length == 0 && dispatch(fetchMasterTaskDropDown());
    dispatch(fetchMasterTaskTypesTicket({ mode: "ticket" }));
  }, []);
  useEffect(() => {
    setTaskTypes(
      taskTypesTicket.map((val: any) => {
        return { name: formatDropDownValue(val.name), value: val.id };
      })
    );
  }, [taskTypesTicket]);
  useEffect(() => {
    if (taskDropDown?.length > 0) {
      let temp = taskDropDown.filter((val: any) => {
        return val.name == "status" && val.mode == "ticket";
      });
      let vals = temp[0].values.map((val: any) => {
        return { name: val.name, value: val.id };
      });
      setTicketStatusList(vals);
    }
  }, [taskDropDown]);
  useEffect(() => {
    if (!loading) {
      let listData: any = [];
      tickets.forEach((val: any) => {
        listData.push({
          all_data: val,
          id: val.id,
          display_id: val.display_id,
          title: val.title ? val.title : formatDropDownValue(val.sub_type.name),
          type: val?.sub_type?.name,
          status: val?.status?.name,
          assignedTo: val?.assigned_user?.first_name
            ? `${val?.assigned_user?.first_name
              ? val?.assigned_user?.first_name
              : ""
            } ${val?.assigned_user?.last_name &&
              val?.assigned_user?.last_name !== "null"
              ? val?.assigned_user?.last_name
              : ""
            }`
            : `Not Assigned`,
          patient: contactNameFormat(
            val?.contact?.first_name,
            val?.contact?.last_name,
            val?.contact?.gender?.name,
            val?.contact?.dob
          ),
          createdOn: formatDate(val.created_at),
          updatedOn: formatDate(val.updated_at),
          history: val.history,
          serviceDetails: {
            type: val && val?.services?.length > 0 && val?.services[0]?.type,
            doctor:
              val &&
              val?.services?.length > 0 &&
              val?.services[0]?.appointment?.doctor?.name,
            appt_time: formatDate(
              val &&
              val?.services?.length > 0 &&
              val?.services[0]?.appointment?.appointment_date
            ),
          },
        });
      });
      setTicketList(listData);
    }
  }, [tickets]);
  function linkAction(id: string, action: string) {
    if (action == "link") {
      onLinkTicketLinkContact && onLinkTicketLinkContact("true");
      let data = {
        call_log_id: callLogDetails.id,
        ticket_id: id,
        type: "ticket",
      };
      dispatch(linkCallLog(data)).then((val: any) => {
        dispatch(fetchCalllogDetails(callLogDetails.id));
        setResultModal(true);
      });
    }
    if (action == "delink") {
      let selectedData = linked_tickets.filter((val: any) => {
        return id == val.ticket_id;
      });
      console.log(selectedData[0].id);
      dispatch(delinkCallLog(selectedData[0].id)).then((val: any) => {
        dispatch(fetchCalllogDetails(callLogDetails.id));
      });
    }
  }
  let linked_tickets: any[] = [];
  if (callLogDetails) {
    linked_tickets = callLogDetails.links.filter((val: any) => {
      return val.type == "ticket";
    });
  }
  const updateTicket = (item: any) => {
    if (item.action !== "link_ticket") {
      setSelectedForUpdate(item.all_data);
      setUpdateModal(true);
    }
  };
  const parentCallbackUpdate = () => {
    setUpdateModal(false);
    dispatch(
      fetchTicketsList({
        phone: phone,
        offset: pagination.offset,
        limit: pagination.limit,
        ...(callLogDetails?.id
          ? {
            call_id: callLogDetails?.id,
            disable_scope: true,
          }
          : { disable_scope: true }),
      })
    );
  };
  function filterFormSubmit(changed: any, all: any) {
    let filtersObj = {} as any;
    for (let key of Object.keys(all)) {
      if (all[key] === "" || all[key] === undefined) {
      } else {
        if (key == "range") {
          filtersObj.from_date = all.range ? all.range[0].format() : null;
          filtersObj.to_date = all.range ? all.range[1].format() : null;
        } else {
          filtersObj[key] = all[key];
        }
      }
    }
    setFilters(filtersObj);
  }

  useEffect(() => {
    setFormElements({
      onFinish: () => { },
      onFinishFailed: () => { },
      onValuesChange: filterFormSubmit,
      initialValues: {},
      setValues: {},
      formElements: [
        {
          type: "Select",
          name: "status_id",
          colSpan: 5,
          allowClear: true,
          placeholder: "Status",
          options: ticketStatusList,
        },
        {
          type: "Select",
          name: "type_id",
          colSpan: 5,
          allowClear: true,
          placeholder: "Type",
          options: taskTypesList,
        },
        {
          type: "Select",
          name: "contact_id",
          colSpan: 5,
          allowClear: true,
          placeholder: "Contacts",
          options: contactList,
        },
        {
          type: "RangePicker",
          name: "range",
          colSpan: 8,
        },
      ],
    });
  }, [ticketStatusList, taskTypesList]);
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  return (
    <>
      <Row
        className="bg-app crm-table associated-leads"
        align="middle"
        style={{ paddingTop: "14px" }}
      >
        <Col span={24}>
          <Row align="bottom">
            <Col
              {...{ xs: 12, sm: 12, md: 16, lg: 16, xl: 16 }}
              className="filter-bar"
            >
              <p className="paddingx20 bold-600 text-secondary">
                All {t("CRM.Tickets.Fields.Tickets")} associated with {phone}
              </p>
              {formElements && !screenType?.mobile && (
                <FormBar
                  name={"generic_filters"}
                  onFinish={formElements.onFinish}
                  onFinishFailed={formElements.onFinishFailed}
                  formElements={formElements.formElements}
                  initialValues={formElements.initialValues}
                  onValuesChange={formElements.onValuesChange}
                  buttonText={""}
                  setValues={
                    formElements.setValues ? formElements.setValues : null
                  }
                ></FormBar>
              )}
            </Col>
            <Col
              {...{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}
              className="paddingy15 text-right paddingx20 pagination-bar"
            >
              <PaginationLayout
                onChange={(e: any) => pageChange(e)}
                total={pagination.total}
                defaultCurrent={pageNumber + 1}
              ></PaginationLayout>
            </Col>
          </Row>
        </Col>
      </Row>

      <div style={{ maxHeight: "calc(100vh - 250px)", overflowY: "scroll" }}>
        {/* <div className='fixed-header-comp'> */}
        <Row>
          <Col span={24} className="padding-bottom-20 paddingx20">
            {!loading && (
              <List
                itemLayout="vertical"
                dataSource={ticketList}
                renderItem={(item: any) =>
                  excludeTicketId !== item.id && (
                    <List.Item key={item.id}>
                      <Row align="middle">
                        <Col
                          {...{
                            xs: 24,
                            sm: 24,
                            md: item.serviceDetails?.type ? 6 : 8,
                            lg: item.serviceDetails?.type ? 6 : 8,
                            xl: item.serviceDetails?.type ? 6 : 8,
                          }}
                        >
                          <a
                            onClick={() =>
                              history.push(ROUTE_CRM_TICKET_DETAILS(item.id))
                            }
                            className="bold-600"
                          >
                            #{item.display_id}
                          </a>
                          {item?.all_data?.display_name && (
                            <>
                              <Divider
                                className="dark"
                                type="vertical"
                              ></Divider>{" "}
                              <span className="bold-600">
                                {/* {item?.all_data?.display_name} */}
                                {(item?.all_data?.display_name !== null && item?.all_data?.display_name !== "null") ?
                                  formatDropDownValue(item?.all_data?.display_name) :
                                  item?.all_data?.type?.name ?
                                    formatDropDownValue(item?.all_data?.type?.name) :
                                    '-'}

                              </span>
                            </>
                          )}
                          {/* <Dropdown overlay={menuDetail} placement="bottomLeft"><a className="drop-down-text text-secondary"><EyeFilled className="paddingl10"  /></a></Dropdown> */}
                          <br />
                          <>{item.patient} </>
                        </Col>
                        {item.serviceDetails?.type && !screenType?.mobile && (
                          <Col
                            {...{ xs: 24, sm: 24, md: 7, lg: 7, xl: 7 }}
                            className="text-secondary"
                          >
                            <small className="capitalize">
                              Service Details:
                              <br />
                              {item.serviceDetails.type === "order" && (
                                <>
                                  {item.serviceDetails.type}
                                  <br />

                                  {item?.all_data?.services?.map(
                                    (itms: any) => {
                                      return (
                                        // "Count" +itms?.order?.items.length + "Date" + itms?.order?.order_date
                                        ` ${itms?.order?.items?.length > 0
                                          ? "Count: " +
                                          itms?.order?.items?.length
                                          : ""
                                        }  ${itms?.order?.order_date
                                          ? ", Date: " +
                                          formatDate(
                                            itms?.order?.order_date
                                          )
                                          : ""
                                        } `
                                      );
                                    }
                                  )}
                                  {/* {"Count "+item.all_data.services.length} {"Date "+item.all_data.services.length} */}
                                </>
                              )}
                              {item.serviceDetails.type == "appointment" && (
                                <>
                                  {item.serviceDetails.type}{" "}
                                  <Divider type="vertical"></Divider>
                                  {item.serviceDetails.doctor}
                                  <br />
                                  Appt. Time: {item.serviceDetails.appt_time}
                                </>
                              )}
                            </small>
                          </Col>
                        )}
                        <Col
                          {...{
                            xs: 24,
                            sm: 24,
                            md: item.serviceDetails?.type ? 6 : 9,
                            lg: item.serviceDetails?.type ? 6 : 9,
                            xl: item.serviceDetails?.type ? 6 : 9,
                          }}
                          className="text-secondary"
                        >
                          {item?.assignedTo && (
                            <small className="capitalize">
                              Assigned To: {!screenType?.mobile && <br />}
                              {item.assignedTo}
                              <br />
                              Updated on: {item.updatedOn}
                            </small>
                          )}
                        </Col>
                        {!screenType?.mobile && (
                          <Col
                            span={item.serviceDetails?.type ? 5 : 7}
                            className="text-right"
                          >
                            <small className="text-secondary mb-10 d-block text-right">
                              Created on {item.createdOn}
                            </small>
                            <Space size={20}>
                              <Button
                                // style={{ maxWidth: "150px", height: "auto" }}
                                onClick={() => updateTicket(item)}
                              >
                                {item.status !== "follow-up" ? (
                                  <>
                                    {" "}
                                    {formatDropDownValue(item.status)}{" "}
                                    {action !== "link_ticket" && (
                                      <EditOutlinedIcon
                                        width={13.5}
                                        height={13.5}
                                        className="text-primary"
                                      />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {formatDropDownValue(item.status)}{" "}
                                    {action !== "link_ticket" && (
                                      <EditOutlinedIcon
                                        width={13.5}
                                        height={13.5}
                                        className="text-primary"
                                      />
                                    )}
                                    <br />
                                    <small className="em text-secondary">
                                      {item?.history?.length > 0 &&
                                        item?.history[item?.history?.length - 1]
                                          ?.follow_up_date !== null
                                        ? formatDate(
                                          item?.history[
                                            item?.history?.length - 1
                                          ]?.follow_up_date
                                        )
                                        : ""}
                                    </small>
                                  </>
                                )}
                              </Button>
                              {action == "link_ticket" &&
                                linked_tickets
                                  ?.map((val: any) => {
                                    return val.ticket_id;
                                  })
                                  .indexOf(item.id) !== -1 && (
                                  <Button
                                    onClick={() =>
                                      linkAction(item.id, "delink")
                                    }
                                    type="primary"
                                    danger
                                    className="wide-btn crm-outline-btn"
                                    ghost
                                  >
                                    De-Link
                                  </Button>
                                )}
                              {action == "link_ticket" &&
                                linked_tickets
                                  ?.map((val: any) => {
                                    return val.ticket_id;
                                  })
                                  .indexOf(item.id) == -1 &&
                                (linkedStates ? (
                                  <Button
                                    onClick={() => linkAction(item.id, "link")}
                                    type="primary"
                                    className="wide-btn crm-outline-btn"
                                    ghost
                                  >
                                    Link
                                  </Button>
                                ) : (
                                  <Tooltip
                                    title="Link a contact with phone number before linking ticket/lead"
                                    color={"#E1454E"}
                                    key={"#E1454E"}
                                  >
                                    <Button
                                      // disabled
                                      // onClick={() => linkAction(item.id, "link")}
                                      type="primary"
                                      className="wide-btn crm-outline-btn"
                                      ghost
                                    >
                                      Link
                                    </Button>
                                  </Tooltip>
                                ))}
                            </Space>
                          </Col>
                        )}
                      </Row>
                    </List.Item>
                  )
                }
              />
            )}
            {loading && <Skeleton></Skeleton>}
          </Col>
        </Row>
      </div>
      {resultModal && (
        <ResultModalLayout
          type="success"
          modalVisible={resultModal}
          setModalVisible={(data) => setResultModal(data)}
          title="Call Log linked successfully with Lead"
          subtitle="Call log status updated to closed"
        ></ResultModalLayout>
      )}
      {updateModal && (
        <Modal
          centered
          title={`Update ${t("CRM.Tickets.Fields.Ticket")}`}
          visible={updateModal}
          onCancel={() => setUpdateModal(false)}
          width={"1000px"}
          className="modalFormButton form-modal"
          footer={false}
          style={{ paddingBottom: "0px" }}
        >
          <UpdateTicketForm
            ticket={selectedForUpdate}
            layout="horizontal"
            parentCallback={parentCallbackUpdate}
          ></UpdateTicketForm>
        </Modal>
      )}
    </>
  );
}

export default TicketsTab;
