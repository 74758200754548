import { PhoneFilled } from "@ant-design/icons";
import { Col, Divider, Row, Skeleton, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { formatDropDownValue } from "../../../shared/Utils/utilities";
import initiateCallFn from "../../../shared/common/initCall";

const TopbarNamecard = (props: any) => {
  const initCall = async (phone: any) => {
    // await dispatch(initiateCall({ cli: phone, ...props?.details?.callData }));
    // message.success("Call initiated.");
    await initiateCallFn(phone, props?.details?.callData);
  };
  const { t } = useTranslation();
  console.log("dddddddddd", props)
  return (

    <>
      {props.loading === true ? (
        <Skeleton.Input style={{ width: 200 }} active={true} size={"small"} />
      ) : (
        <Row className="namecard-topbar" gutter={64}>
          <Col className="gutter-row border-right">
            <Row>
              <Col>
                <span className="title bold-600">
                  {formatDropDownValue(props.details.type)} #
                  {props.details.display_id}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="capitalize">{props.details.name}</span>{" "}

                <a onClick={() => initCall(props.details.mobile)}>
                  <Divider type={"vertical"}></Divider>
                  <PhoneFilled className="flipIcon" /> {props.details.mobile}
                </a>{" "}
                <span className="text-secondary">
                  {props.details.uhid && (
                    <>
                      <Divider type="vertical"></Divider>{t("CRM.Common.Fields.UHID")} :{" "}
                      {props.details.uhid}
                    </>
                  )}
                </span>
              </Col>
            </Row>
          </Col>
          {props?.details?.doctor && (
            <Col className="gutter-row">
              <Row>
                <Col>
                  <span className="title capitalize">
                    {props.details.doctor}
                  </span>

                  <a className="">
                    <Divider type="vertical"></Divider>
                    {`( ${props?.details?.doctorCountryCode} ${props?.details?.doctorPhone} )`}
                  </a>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="text-secondary">
                    Appt. Time : {props.details.appTime}{" "}
                    <Divider type="vertical"></Divider> {props.details.hospital}
                  </span>
                  <span>
                    <Divider type="vertical"></Divider>
                    <Tag className="solid" color="blue">
                      {formatDropDownValue(props.details.status) === 'CONSULTING' ? 'CHECKED-IN' : formatDropDownValue(props.details.status)}
                    </Tag>
                  </span>

                </Col>
              </Row>
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export default TopbarNamecard;
