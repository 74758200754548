import { Modal } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAllAgents } from "../../../../redux/reducers/agents.slice";
import {
  fetchReasonListLead,
  patchLead,
} from "../../../../redux/reducers/leads.slice";
import { fetchMasterTaskDropDown } from "../../../../redux/reducers/master.slice";
import {
  fetchTeamById,
  fetchTeams,
  reset,
} from "../../../../redux/reducers/team.slice";
import { formatDropDownValue } from "../../../../shared/Utils/utilities";
import {
  TASK_MODULE_ACTIONS_DISABLE_ASSIGNMENT_IN_LEAD,
  TASK_MODULE_ACTIONS_DISABLE_ASSIGNMENT_IN_TICKET,
  TASK_MODULE_ACTIONS_HIDE_ATTACHMENT,
  TASK_MODULE_ACTIONS_MANDATORY_REMARKS_LEAD,
  TASK_MODULE_ACTIONS_UPDATE_LEAD,
} from "../../../../shared/components/permission.constants";
import { RootState } from "../../../../shared/constants";
import FormBar from "../../../../shared/layouts/formbar.layout";
import ResultModalLayout from "../../../../shared/layouts/resultmodal.layout";
import UploadPrescription from "../../../../shared/layouts/uploadprescription";
import { ROUTE_CRM_LEADS_LIST } from "../../../../shared/routes/crm.routes.constants";

type Props = {
  details: any;
  parentCallback?: (e: any) => void;
  layout?: string;
};

function UpdateLeadForm({
  details,
  parentCallback = (e: any) => {},
  layout = "vertical",
}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { taskDropDown } = useSelector((state: RootState) => state.master);
  const { teams, team } = useSelector((state: RootState) => state.team);
  const { agentsAll } = useSelector((state: RootState) => state.agents);
  const [subDispositionData, setsubDispositionData] = useState(null as any);
  const [updateBtnDisabled, setupdateBtnDisabled] = useState(null as any);

  const [priorityList, setPriorityList] = useState(null as any);
  const [leadStatusList, setLeadStatusList] = useState(null as any);
  const [userList, setUserList] = useState(null as any);
  const [formLoading, setFormLoading] = useState(false);
  const [teamList, setTeamList] = useState(null as any);
  const [assigneeList, setAssigneeList] = useState([] as any);
  const [selectedStatus, setSelectedStatus] = useState(
    details?.status?.name as any
  );
  const [assignTo, setAssignTo] = useState(null as any);
  const [opportunitiesList, setOpportunitiesList] = useState([] as any);
  const [modalVisible, setModalVisible] = useState(false as any);

  const [hours, sethours] = useState([] as any);
  const [excludedMinutes, setexcludedMinutes] = useState(0);
  const [excludedHours, setexcludedHours] = useState(0);
  const [reasonList, setreasonList] = useState(null as any);
  const [formData, setFormData] = useState(false as any);
  const [actualReasonList, setactualReasonList] = useState([] as any);

  const [currentStatus, setcurrentStatus] = useState(null as any);
  const [taskDropDownReasons, settaskDropDownReasons] = useState(null as any);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setfiles] = useState(null as any);
  const [documentName, setdocumentName] = useState(null as any);

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  const onClickUpladFile = () => {
    setIsModalOpen(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fileUploaded = async (response: any) => {
    handleCancel();
    setfiles(response);
  };
  const getDescription = (value: any) => {
    setdocumentName(value);
  };

  const range = (start: any, end: any) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  useEffect(() => {
    setcurrentStatus(details?.status_id);
  }, []);

  useEffect(() => {
    dispatch(fetchReasonListLead()).then((val: any) => {
      setsubDispositionData(val?.payload?.data?.data?.items);
    });
  }, []);

  useEffect(() => {
    if (files) {
      if (formatDropDownValue(selectedStatus) == "Follow-up")
        setFormData(formFollowup);
      else setFormData(formBasic);
    }
  }, [files, selectedStatus]);

  const handleDateChangeArray = (e: any) => {
    const hourStarter =
      moment().format("A") === "PM" && Number(moment().format("hh")) !== 12
        ? 12
        : 0;
    const selectedDate = e.format("YYYY-MM-DD");
    const selectedHours = Number(e.format("hh"));
    const currentDate = moment().format("YYYY-MM-DD");

    if (selectedDate > currentDate) {
      setexcludedHours(0);
      setexcludedMinutes(0);
    } else {
      setexcludedHours(hourStarter + Number(moment().format("hh")));
      setexcludedMinutes(Number(moment().format("mm")));

      if (Number(moment().format("hh")) !== 12) {
        if (selectedHours > Number(moment().format("hh"))) {
          setexcludedMinutes(0);
        } else {
          setexcludedMinutes(Number(moment().format("mm")));
        }
      } else {
        setexcludedMinutes(Number(moment().format("mm")));
        if (selectedHours === 12) {
          setexcludedMinutes(Number(moment().format("mm")));
        } else {
          setexcludedMinutes(0);
        }
      }
    }
  };

  useEffect(() => {
    if (taskDropDown.length > 0) {
      let temp = taskDropDown.filter((val: any) => {
        return val.name == "priority" && val.mode == "lead";
      });
      let vals;
      if (temp.length > 0) {
        vals = temp[0].values.map((val: any) => {
          return { name: formatDropDownValue(val.name), value: val.id };
        });
        setPriorityList(vals);
      }
      temp = taskDropDown.filter((val: any) => {
        return val.name == "status" && val.mode == "lead";
      });
      if (temp.length > 0) {
        vals = temp[0].values.map((val: any) => {
          return { name: formatDropDownValue(val.name), value: val.id };
        });
        setLeadStatusList(vals);
      }
      temp = taskDropDown.filter((val: any) => {
        return val.name == "opportunity" && val.mode == "lead";
      });
      if (temp.length > 0) {
        vals = temp[0].values.map((val: any) => {
          return { name: formatDropDownValue(val.name), value: val.id };
        });
        setOpportunitiesList(vals);
      }

      temp = taskDropDown?.filter((val: any) => {
        return val.name === "reason" && val.mode === "lead";
      });
      let test1 = temp[0]?.values?.map((val: any) => {
        return { name: formatDropDownValue(val.name), value: val.id };
      });

      dispatch(fetchReasonListLead()).then((val: any) => {
        let data = [] as any;
        if (selectedStatus === "denied" || selectedStatus === "DENIED") {
          data = val?.payload?.data?.data?.items?.filter((val: any) => {
            return val.mode === "lead" && val.status_id === currentStatus;
          })[0]?.reason_ids;
        } else {
          data = val?.payload?.data?.data?.items?.filter((val: any) => {
            return (
              val.mode === "lead" &&
              val.type.id === details.svc_type_id &&
              val.sub_type.id === details.svc_sub_type_id &&
              val.status_id === currentStatus
            );
          })[0]?.reason_ids;
        }

        const datas = test1?.filter((element: any) =>
          data?.some((e: any) => element?.value === e)
        );
        setactualReasonList(datas ? datas : []);
      });
    }

    dispatch(fetchAllAgents({ limit: 500 }));
  }, [taskDropDown, currentStatus]);

  useEffect(() => {
    if (agentsAll.length > 0) {
      let vals = agentsAll.map((val: any) => {
        return {
          name: `${
            val?.user?.first_name && val?.user?.first_name !== "null"
              ? val?.user?.first_name
              : ""
          } ${
            val.user?.last_name && val.user?.last_name !== "null"
              ? val.user?.last_name
              : ""
          }`,
          value: val.user_id,
        };
      });
      setUserList(vals);
      if (details?.assigned_user_id) {
        setAssigneeList(vals || []);
      }
    } else {
      setUserList([]);
    }
  }, [agentsAll]);
  useEffect(() => {
    if (teams?.length > 0) {
      let vals = teams?.map((val: any) => {
        return { name: formatDropDownValue(val.name), value: val.id };
      });
      setTeamList(vals || []);
    }
  }, [teams]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(reset());
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    let vals =
      team?.users.length > 0 &&
      team?.users?.map((val: any) => {
        return {
          name: formatDropDownValue(
            `${val?.user?.first_name} ${val?.user?.last_name}`
          ),
          value: val.user_id,
        };
      });
    setAssigneeList(vals || []);
  }, [team]);

  useEffect(() => {
    details?.id &&
      taskDropDown.length == 0 &&
      dispatch(fetchMasterTaskDropDown());

    if (details?.assigned_team_id) {
      dispatch(fetchTeamById(details?.assigned_team_id));
      dispatch(fetchTeams());
    }
  }, [details]);

  const onFinish = (value: any) => {
    console.log(value);
    setFormLoading(true);
    let data: any;
    let selectedStatus = leadStatusList.filter((val: any) => {
      return val.value == value.status;
    });
    if (selectedStatus[0].name == "FOLLOW-UP") {
      data = {
        assigned_user_id: value.assignee || null,
        assigned_team_id: value.assign_to === "team" ? value.teams : null,
        follow_up_date: value.followup_date.format(),
        follow_up_time: value.followup_date.format("LT"),
        due_date: value.due_date.format(),
        priority_id: value.priority,
        remarks: value.remarks,
        status_id: value.status,
        reason_id: value.reason_id,
        lead_id: details.id,
        opportunities: value?.opportunities?.map((val: string) => {
          return { opportunity_id: val };
        }),
      };
    } else {
      data = {
        assigned_user_id: value.assign_to == "agent" ? value.assignee : null,
        assigned_team_id: value.assign_to == "team" ? value.assignee : null,
        due_date: value.due_date.format(),
        priority_id: value.priority,
        remarks: value.remarks,
        reason_id: value.reason_id,
        status_id: value.status,
        lead_id: details.id,
        opportunities: value?.opportunities?.map((val: string) => {
          return { opportunity_id: val };
        }),
      };
    }

    if (files) {
      data.file_upload_ids = [files];
    }

    dispatch(patchLead({ id: details.id, data: data })).then((val: any) => {
      setModalVisible(true);
      setTimeout(() => {
        onCancelModal();
      }, 2500);
      // message.success({
      //     content: 'Lead updated successfully',
      //     style: {
      //         marginBottom: '10vh',
      //     },
      // });
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  const onStatusChange = (e: any) => {
    let selectedStatus = leadStatusList.filter((val: any) => {
      return val.value == e;
    });
    setSelectedStatus(selectedStatus[0]?.name);
    setcurrentStatus(selectedStatus[0]?.value);
    if (selectedStatus[0].name == "FOLLOW-UP") {
      setFormData(formFollowup);
    } else {
      setFormData(formBasic);
    }
  };

  // console.log("dddddddddddddddddddd", details)
  async function changeSelectedAssignType(e: any) {
    setAssignTo(e);
    if (e == "agent") {
      setAssigneeList(userList || []);
      setTeamList(null);
    }
    if (e == "team") {
      await dispatch(fetchTeams({ limit: 500 }));
      setAssigneeList([]);
    }
  }

  const onChangeTeams = async (teamId: any) => {
    await dispatch(fetchTeamById(teamId));
  };

  useEffect(() => {
    if (
      myPermissions?.indexOf("task_module") !== -1 ||
      myPermissions?.indexOf(TASK_MODULE_ACTIONS_UPDATE_LEAD) !== -1
    ) {
      setupdateBtnDisabled(false);

      // if (ticket?.allowed_permission && ticket?.allowed_permission?.update_ticket_view === false) {
      //   setupdateBtnDisabled(true)
      //   debugger
      // }
      // if (ticket?.allowed_permission && ticket?.allowed_permission?.update_ticket_view === true) {
      //   setupdateBtnDisabled(false)
      //   debugger
      // }
    }
  }, [details]);

  const formBasic = {
    heading: "Update Lead" as any,
    onFinish: onFinish,
    onFinishFailed: onFinishFailed,
    initialValues: {
      priority: details?.priority_id,
      status: details?.status_id,
      assign_to: details?.assigned_team_id
        ? "team"
        : details?.assigned_user_id
        ? "agent"
        : null,
      assignee: details?.assigned_user_id || null,
      teams: details?.assigned_team_id,
      due_date: moment(
        details?.due_date ? new Date(details?.due_date) : new Date(),
        "YYYY-MM-DD"
      ),
      remarks: details?.remarks,
      reason_id: details.reason_id,
      opportunities: details?.opportunities?.map((val: any) => {
        return val.opportunity_id;
      }),
    },
    setValues: {
      assignee: !assignTo
        ? details?.assigned_user_id
          ? details?.assigned_user_id
          : details?.assigned_team_id
          ? details?.assigned_team_id
          : null
        : null,
    },
    buttonText: "Update Lead",
    disabedSubmitButton: updateBtnDisabled,
    formElements: [
      {
        label: "Opportunities",
        type: "Select",
        name: "opportunities",
        colSpan: layout == "vertical" ? 24 : 8,
        mode: "multiple",
        options: opportunitiesList || [],
      },
      {
        label: "Lead Status",
        type: "Select",
        name: "status",
        colSpan: layout == "vertical" ? 24 : 8,
        options: leadStatusList,
        onChange: onStatusChange,
        rules: [{ required: true, message: "Please select status" }],
      },
      ...(actualReasonList?.length > 0
        ? [
            {
              label: "Reasons",
              type: "Select",
              name: "reason_id",
              colSpan: layout == "vertical" ? 24 : 8,
              options: actualReasonList,
              // onChange: onStatusChange,
              // rules: [{ required: true, message: "Please select status" }],
            },
          ]
        : []),
      {
        label: "Due Date",
        type: "DateTimePicker",
        name: "due_date",
        rules: [{ required: true, message: "Please select due date" }],
        colSpan: layout == "vertical" ? 24 : 8,
        disabledDate: (d: any) => {
          return d < moment(moment().format("YYYY-MM-DD"));
        },
      },
      ...(myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_ATTACHMENT) !== -1 ||
      myPermissions?.indexOf(
        TASK_MODULE_ACTIONS_HIDE_ATTACHMENT?.split(".")[0]
      ) !== -1
        ? []
        : [
            {
              label: "Select File",
              type: "Button",
              name: `${
                documentName
                  ? "Attached File: " + documentName
                  : "Add Attachment"
              } `,
              colSpan: layout == "vertical" ? 24 : 8,
              // options: leadStatusList,
              onClick: onClickUpladFile,
            },
          ]),

      {
        label: "Priority",
        type: "Select",
        name: "priority",
        colSpan: layout == "vertical" ? 24 : 8,
        options: priorityList,
        rules: [{ required: true, message: "Please select priority" }],
      },
      {
        label: "Assign To",
        type: "Select",
        name: "assign_to",
        rules: [{ required: true, message: "Please select assignment type" }],
        colSpan: layout == "vertical" ? 24 : 4,
        options: [
          { value: "agent", name: "Agent" },
          { value: "team", name: "Team" },
        ],
        onChange: changeSelectedAssignType,
      },
      //show team list
      ...(teamList?.length > 0
        ? [
            {
              label: "Teams",
              type: "Select",
              name: "teams",
              // rules: [{ required: true, message: "Please select assignee" }],
              colSpan: layout == "vertical" ? 24 : 8,
              options: teamList,
              onChange: onChangeTeams,
            },
          ]
        : []),
      {
        label: "Assignee",
        type: "Select",
        showSearch: true,
        name: "assignee",
        disabled:
          myPermissions?.indexOf("task_module") !== -1 ||
          myPermissions?.indexOf(
            TASK_MODULE_ACTIONS_DISABLE_ASSIGNMENT_IN_LEAD
          ) !== -1
            ? true
            : false,
        rules: [{ required: true, message: "Please select assignee" }],
        colSpan: layout == "vertical" ? 24 : 4,
        options: assigneeList,
      },
      {
        label: "Remarks",
        type: layout == "vertical" ? "TextArea" : "Input",
        name: "remarks",
        rules: [
          {
            required:
              myPermissions?.indexOf(
                TASK_MODULE_ACTIONS_MANDATORY_REMARKS_LEAD
              ) !== -1 ||
              myPermissions?.indexOf(
                TASK_MODULE_ACTIONS_MANDATORY_REMARKS_LEAD?.split(".")[0]
              ) !== -1
                ? true
                : false,
            message: "Please select assignment type",
          },
        ],

        placeholder: "Type remarks here...",
        colSpan: layout == "vertical" ? 24 : 16,
      },
    ],
  };

  const formFollowup = {
    heading: "Update Lead" as any,
    onFinish: onFinish,
    onFinishFailed: onFinishFailed,
    initialValues: {
      priority: details?.priority_id,
      status: details?.status_id,
      reason_id: details.reason_id,
      assign_to: details?.assigned_team_id
        ? "team"
        : details?.assigned_user_id
        ? "agent"
        : null,
      teams: details?.assigned_team_id,
      assignee: details?.assigned_user_id || null,
      due_date: moment(
        details?.due_date ? new Date(details?.due_date) : new Date(),
        "YYYY-MM-DD"
      ),
      remarks: details?.remarks,
      ...(details?.follow_up_date && details?.follow_up_time
        ? {
            followup_date: moment(
              moment(details?.follow_up_date).format("YYYY-MM-DD") +
                " " +
                details?.follow_up_time
            ),
          }
        : {}),
      opportunities: details?.opportunities?.map((val: any) => {
        return val.opportunity_id;
      }),
    },
    setValues: {
      assignee: !assignTo
        ? details?.assigned_user_id
          ? details?.assigned_user_id
          : details?.assigned_team_id
          ? details?.assigned_team_id
          : null
        : null,
    },
    buttonText: "Update Lead",
    disabedSubmitButton: updateBtnDisabled,
    formElements: [
      {
        label: "Opportunities",
        type: "Select",
        name: "opportunities",
        colSpan: layout == "vertical" ? 24 : 8,
        mode: "multiple",
        options: opportunitiesList,
      },
      {
        label: "Lead Status",
        type: "Select",
        name: "status",
        colSpan: layout == "vertical" ? 24 : 8,
        options: leadStatusList,
        onChange: onStatusChange,
        rules: [{ required: true, message: "Please select status" }],
      },

      {
        label: "Follow-Up Date Time",
        type: "DateTimePicker",
        name: "followup_date",
        rules: [{ required: true, message: "Please select follow up date" }],
        colSpan: layout == "vertical" ? 24 : 8,
        disabledDate: (d: any) => {
          return d < moment(moment().format("YYYY-MM-DD"));
        },
        disabledTime: () => {
          return {
            disabledHours: () => range(0, 24).splice(0, excludedHours),
            disabledMinutes: () => range(0, excludedMinutes),
          };
        },
      },
      ...(actualReasonList?.length > 0
        ? [
            {
              label: "Reasons",
              type: "Select",
              name: "reason_id",
              colSpan: layout == "vertical" ? 24 : 8,
              options: actualReasonList,
              // onChange: onStatusChange,
              // rules: [{ required: true, message: "Please select status" }],
            },
          ]
        : []),
      {
        label: "Due Date",
        type: "DateTimePicker",
        name: "due_date",
        rules: [{ required: true, message: "Please select due date" }],
        colSpan: layout == "vertical" ? 24 : 8,
        disabledDate: (d: any) => {
          return d < moment(moment().format("YYYY-MM-DD"));
        },
      },
      ...(myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_ATTACHMENT) !== -1 ||
      myPermissions?.indexOf(
        TASK_MODULE_ACTIONS_HIDE_ATTACHMENT?.split(".")[0]
      ) !== -1
        ? []
        : [
            {
              label: "Select File",
              type: "Button",
              name: `${
                documentName
                  ? "Attached File: " + documentName
                  : "Add Attachment"
              } `,
              colSpan: layout == "vertical" ? 24 : 8,
              // options: leadStatusList,
              onClick: onClickUpladFile,
            },
          ]),
      {
        label: "Priority",
        type: "Select",
        name: "priority",
        colSpan: layout == "vertical" ? 24 : 8,
        options: priorityList,
        rules: [{ required: true, message: "Please select priority" }],
      },
      {
        label: "Assign To",
        type: "Select",
        name: "assign_to",
        rules: [{ required: true, message: "Please select assignment type" }],
        colSpan: layout == "vertical" ? 24 : 4,
        options: [
          { value: "agent", name: "Agent" },
          { value: "team", name: "Team" },
        ],
        onChange: changeSelectedAssignType,
      },
      //show team list
      ...(teamList?.length > 0
        ? [
            {
              label: "Teams",
              type: "Select",
              name: "teams",
              // rules: [{ required: true, message: "Please select assignee" }],
              colSpan: layout == "vertical" ? 24 : 8,
              options: teamList,
              onChange: onChangeTeams,
            },
          ]
        : []),
      {
        label: "Assignee",
        type: "Select",
        showSearch: true,
        name: "assignee",
        disabled:
          myPermissions?.indexOf("task_module") !== -1 ||
          myPermissions?.indexOf(
            TASK_MODULE_ACTIONS_DISABLE_ASSIGNMENT_IN_TICKET
          ) !== -1
            ? true
            : false,
        rules: [{ required: true, message: "Please select assignee" }],
        colSpan: layout == "vertical" ? 24 : 4,
        options: assigneeList,
      },
      {
        label: "Remarks",
        type: layout == "vertical" ? "TextArea" : "Input",
        name: "remarks",
        placeholder: "Type remarks here...",
        rules: [
          {
            required:
              myPermissions?.indexOf(
                TASK_MODULE_ACTIONS_MANDATORY_REMARKS_LEAD
              ) !== -1 ||
              myPermissions?.indexOf(
                TASK_MODULE_ACTIONS_MANDATORY_REMARKS_LEAD?.split(".")[0]
              ) !== -1
                ? true
                : false,
            message: "Please select assignment type",
          },
        ],

        colSpan: layout == "vertical" ? 24 : 16,
      },
    ],
  };
  if (layout == "horizontal") {
    formFollowup.heading = null;
    formBasic.heading = null;
  }
  useEffect(() => {
    if (priorityList && leadStatusList && userList && selectedStatus) {
      if (formatDropDownValue(selectedStatus) == "Follow-up")
        setFormData(formFollowup);
      else setFormData(formBasic);
    }
  }, [userList, assigneeList, actualReasonList]);
  const onCancelModal = () => {
    setModalVisible(false);
    // parentCallback({})
    history.push(ROUTE_CRM_LEADS_LIST);
  };
  console.log("cccccccccccc", formData);

  return (
    <>
      {formData && (
        <FormBar
          name={"update_lead"}
          loading={formLoading}
          initialValues={formData.initialValues}
          onClick={() => onClickUpladFile()}
          heading={formData.heading}
          onFinish={formData.onFinish}
          onFinishFailed={formData.onFinishFailed}
          disabedSubmitButton={formData?.disabedSubmitButton}
          formElements={formData.formElements}
          buttonText={formData.buttonText}
          setValues={formData.setValues}
          onDateTimeChange={handleDateChangeArray}
          disabledTimeValues={() => {
            return {
              disabledHours: () => range(0, 24).splice(0, excludedHours),
              disabledMinutes: () => range(0, excludedMinutes),
            };
          }}
        ></FormBar>
      )}
      <Modal
        title="Upload Document"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <UploadPrescription
          getDescription={getDescription}
          fileUploaded={fileUploaded}
          handleCancel={handleCancel}
        />
      </Modal>
      {modalVisible && (
        <ResultModalLayout
          type="success"
          modalVisible={modalVisible}
          message=""
          setModalVisible={onCancelModal}
          title="Success"
          subtitle="Lead updated successfully"
        />
      )}
    </>
  );
}

export default UpdateLeadForm;
