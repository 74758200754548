import { SettingOutlined } from "@ant-design/icons";
import { Button, Col, Menu, message, Modal, Row } from "antd";
import Password from "antd/es/input/Password";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
//import PermissionComponent from '../components/permission.component';
import { useMsal } from "@azure/msal-react";
import { ReactComponent as ChatAdmin } from "../../assets/icons/adminchatIcon.svg";
import { ReactComponent as Agent } from "../../assets/icons/agent.svg";
import { ReactComponent as Agents } from "../../assets/icons/agents.svg";
import { ReactComponent as CallLogs } from "../../assets/icons/calllogs1.svg";
// import { ReactComponent as Chat1 } from "../../assets/icons/chat-icon.svg";
import { ReactComponent as Chat } from "../../assets/icons/chatIcon.svg";
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as KBank } from "../../assets/icons/knowledge-bank.svg";
import { ReactComponent as Leads } from "../../assets/icons/leads.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings1.svg";
import { ReactComponent as Tickets } from "../../assets/icons/tickets.svg";

import {
  ROUTE_CRM_AGENTS,
  ROUTE_CRM_BOT_CHAT,
  ROUTE_CRM_DASHBOARD,
  ROUTE_CRM_GOOGLE_CONNECT,
  ROUTE_CRM_KNOWLEDGE_BANK_LIST,
  ROUTE_CRM_LEAD_PAGES,
  ROUTE_CRM_LOGIN,
  ROUTE_CRM_MASTER_DATA_UPLOAD,
  ROUTE_CRM_SLARULES,
} from "../../shared/routes/crm.routes.constants";

import { useTranslation } from "react-i18next";
import { logoutUser } from "../../redux/reducers/auth.slice";
import {
  fetchCurrentUser,
  unPauseShift,
} from "../../redux/reducers/user.slice";
import { ACCOUNT_PREFIX, RootState } from "../../shared/constants";
import {
  RAG_MODULE_PERMISSIONS,
  SETTINGS_MODULE_ACTIONS_READ,
  TASK_MODULE_ACTIONS_ADMIN_READ_CHAT,
  TASK_MODULE_ACTIONS_DISABLE_PAUSE_SHIFT,
  TASK_MODULE_ACTIONS_FACEBOOK_PAGE_MENU,
  TASK_MODULE_ACTIONS_GET_DASHBOARD_DATA,
  TASK_MODULE_ACTIONS_READ_AGENT_PROFILE,
  TASK_MODULE_ACTIONS_READ_ASSIGNMENT_SETTINGS,
  TASK_MODULE_ACTIONS_READ_CALL_LOGS,
  TASK_MODULE_ACTIONS_READ_CHAT,
  TASK_MODULE_ACTIONS_READ_CONTACT,
  TASK_MODULE_ACTIONS_READ_LEAD,
  TASK_MODULE_ACTIONS_READ_TASK_TO_ACTION_MAPPING,
  TASK_MODULE_ACTIONS_READ_TICKET,
  TASK_MODULE_ACTIONS_SHOW_SLA_PAGE,
  TASK_MODULE_ACTIONS_SOCIAL_PROFILE_MENU,
  TASK_MODULE_GOOGLE_MY_BUSINESS_ACCOUNT,
} from "../components/permission.constants";
import {
  ROUTE_CRM_ACTIONMAP,
  ROUTE_CRM_ADMIN_CHAT,
  ROUTE_CRM_ASSIGNMENT,
  ROUTE_CRM_CALL_LOGS,
  ROUTE_CRM_CHAT,
  ROUTE_CRM_CONTACTS,
  ROUTE_CRM_LEADS_LIST,
  ROUTE_CRM_METADATA,
  ROUTE_CRM_SOCIAL_PROFILES,
  ROUTE_CRM_TICKET_LIST,
} from "../routes/crm.routes.constants";

const { SubMenu } = Menu;


let MENU_ITEMS_CRM = {} as any

export const getAllowedPageByPermissions = (permissions: Array<string>) => {
  let allowedPages: any = [];
  MENU_ITEMS_CRM.map((item: any) => {
    if (
      (permissions.includes(item.permission) ||
        permissions.includes(item.permission?.split(".")[0])) &&
      item?.children?.length == 0
    ) {
      allowedPages.push(item);
    }
    if (item?.children?.length > 0) {
      item?.children?.map((val: any) => {
        if (
          permissions.includes(val.permission) ||
          permissions.includes(val.permission?.split(".")[0])
        ) {
          allowedPages.push(val);
        }
      });
    }
  });
  return allowedPages;
};
const Sidebar = (props: any) => {

  const { t } = useTranslation()

  MENU_ITEMS_CRM = [
    {
      name: "Dashboard",
      key: "dashboard",
      permission: TASK_MODULE_ACTIONS_GET_DASHBOARD_DATA,
      icon: <Dashboard />,
      route: ROUTE_CRM_DASHBOARD,
      children: [],
      mobileView: false,
    },
    {
      name: `All ${t("CRM.Tickets.Fields.Tickets")}`,
      permission: TASK_MODULE_ACTIONS_READ_TICKET,
      key: "tickets",
      icon: <Tickets />,
      route: ROUTE_CRM_TICKET_LIST,
      children: [],
      mobileView: true,
    },
    {
      name: "Call Log",
      permission: TASK_MODULE_ACTIONS_READ_CALL_LOGS,
      key: "call-logs",
      icon: <CallLogs />,
      route: ROUTE_CRM_CALL_LOGS,
      children: [],
      mobileView: false,
    },
    {
      name: "Leads",
      permission: TASK_MODULE_ACTIONS_READ_LEAD,
      key: "leads",
      icon: <Leads />,
      route: ROUTE_CRM_LEADS_LIST,
      children: [],
      mobileView: true,
    },
    {
      name: `${t("CRM.Contacts.Titles.Contact List")}`,
      permission: TASK_MODULE_ACTIONS_READ_CONTACT,
      key: "contacts",
      icon: <Agents />,
      route: ROUTE_CRM_CONTACTS,
      children: [],
      mobileView: false,
    },
    {
      name: "Agents",
      permission: TASK_MODULE_ACTIONS_READ_AGENT_PROFILE,
      key: "agents",
      icon: <Agent />,
      route: ROUTE_CRM_AGENTS,
      children: [],
      mobileView: false,
    },
    // {
    //   name: "Posts",
    //   // permission: TASK_MODULE_ACTIONS_READ_TICKET,
    //   key: "post",
    //   icon: <Leads />,
    //   route: "nopath",
    //   children: [
    //     {
    //       name: "Calendar",
    //       // permission: TASK_MODULE_ACTIONS_READ_CHAT,
    //       key: "feed",
    //       icon: <Agents />,
    //       route: ROUTE_CRM_POSTS_CALENDAR,
    //       children: [],
    //       mobileView: false,
    //     },
    //     {
    //       name: "My Feed",
    //       // permission: TASK_MODULE_ACTIONS_READ_CHAT,
    //       key: "feed",
    //       icon: <Agents />,
    //       route: ROUTE_CRM_POSTS_FEED,
    //       children: [],
    //       mobileView: false,
    //     },
    //     {
    //       name: "New Post",
    //       // permission: TASK_MODULE_ACTIONS_READ_CHAT,
    //       key: "new-post",
    //       icon: <Agents />,
    //       route: ROUTE_CRM_POST_NEW,
    //       children: [],
    //       mobileView: false,
    //     },
    //   ],
    //   mobileView: true,
    // },
    {
      name: "Chat",
      permission: TASK_MODULE_ACTIONS_READ_CHAT,
      key: "chat",
      icon: <Chat />,
      route: ROUTE_CRM_CHAT,
      children: [],
      mobileView: false,
    },
    {
      name: "Admin Chat",
      permission: TASK_MODULE_ACTIONS_ADMIN_READ_CHAT,
      key: "adminChat",
      icon: <ChatAdmin />,
      route: ROUTE_CRM_ADMIN_CHAT,
      children: [],
      mobileView: false,
    },

    {
      name: "Chat Bot",
      permission: RAG_MODULE_PERMISSIONS.ask_question,
      key: "chatbot",
      icon: <Chat />,
      route: ROUTE_CRM_BOT_CHAT,
      children: [],
      mobileView: false,
    },
    // {
    //   name: "Conversations",
    //   // permission: TASK_MODULE_ACTIONS_READ_CHAT,
    //   key: "converse",
    //   icon: <Chat />,
    //   route: "nopath",
    //   children: [
    //     {
    //       name: "My Chats",
    //       // permission: TASK_MODULE_ACTIONS_READ_CHAT,
    //       key: "chat",
    //       icon: <Chat />,
    //       route: ROUTE_CRM_CHAT,
    //       children: [],
    //       mobileView: false,
    //     },
    //     {
    //       name: "All Chats",
    //       // permission: TASK_MODULE_ACTIONS_ADMIN_READ_CHAT,
    //       key: "adminChat",
    //       icon: <ChatAdmin />,
    //       route: ROUTE_CRM_ADMIN_CHAT,
    //       children: [],
    //       mobileView: false,
    //     },
    //   ],
    //   mobileView: true,
    // },
    // {
    //   name: "Advertise",
    //   permission: ROUTE_CRM_LEAD_PAGES,
    //   key: "ads",
    //   icon: <Tickets />,
    //   route: "nopath",
    //   children: [
    //     // {
    //     //   name: "Facebook Audience",
    //     //   // permission: TASK_MODULE_ACTIONS_READ_CHAT,
    //     //   key: "fb-audience",
    //     //   icon: <Agents />,
    //     //   route: ROUTE_CRM_FACEBOOK_AUDIENCE_MANAGE,
    //     //   children: [],
    //     //   mobileView: false,
    //     // },
    //     {
    //       name: "Facebook Pages",
    //       // permission: TASK_MODULE_ACTIONS_READ_CHAT,
    //       key: "forms",
    //       icon: <Agents />,
    //       route: ROUTE_CRM_LEAD_PAGES,
    //       children: [],
    //       mobileView: false,
    //     },
    //   ],
    //   mobileView: true,
    // },
    // {
    //   name: "Chat",
    //   permission: TASK_MODULE_ACTIONS_READ_CHAT,
    //   key: "chat",
    //   icon: <Agents />,
    //   route: ROUTE_CRM_CHAT,
    //   children: [],
    //   mobileView: false,
    // },
    // {
    //   name: "Admin Chat",
    //   permission: TASK_MODULE_ACTIONS_ADMIN_READ_CHAT,
    //   key: "adminChat",
    //   icon: <Leads />,
    //   route: ROUTE_CRM_ADMIN_CHAT,
    //   children: [],
    //   mobileView: false,
    // },

    {
      name: "Settings",
      permission: SETTINGS_MODULE_ACTIONS_READ,
      key: "settings",
      icon: <Settings />,
      route: "nopath",
      mobileView: false,
      children: [
        {
          name: "Social Profiles",
          permission: TASK_MODULE_ACTIONS_SOCIAL_PROFILE_MENU,
          key: "social-profiles",
          icon: <SettingOutlined />,
          route: ROUTE_CRM_SOCIAL_PROFILES,
          children: [],
        },
        {
          name: "Facebook Pages",
          permission: TASK_MODULE_ACTIONS_FACEBOOK_PAGE_MENU,
          key: "forms",
          icon: <Agents />,
          route: ROUTE_CRM_LEAD_PAGES,
          children: [],
          mobileView: false,
        },
        {
          name: "SLA Rules",
          key: "sla",
          // icon: <SettingOutlined />,
          permission: TASK_MODULE_ACTIONS_SHOW_SLA_PAGE,
          route: ROUTE_CRM_SLARULES,
          children: [],
        },
        // {
        //   name: "Lead Forms",
        //   permission: TASK_MODULE_ACTIONS_READ_CHAT,
        //   key: "forms",
        //   icon: <Agents />,
        //   route: ROUTE_CRM_LEAD_FORMS,
        //   children: [],
        //   mobileView: false,
        // },
        {
          name: "Action Map",
          permission: TASK_MODULE_ACTIONS_READ_TASK_TO_ACTION_MAPPING,
          key: "actionmap",
          icon: <SettingOutlined />,
          route: ROUTE_CRM_ACTIONMAP,
          children: [],
        },
        // ...(false ),
        {
          name: "Seed data upload",
          permission: TASK_MODULE_ACTIONS_READ_TASK_TO_ACTION_MAPPING,
          key: "seeddataupload",
          icon: <SettingOutlined />,
          route: ROUTE_CRM_MASTER_DATA_UPLOAD,
          children: [],
        },
        {
          name: "Google My Business",
          permission: TASK_MODULE_GOOGLE_MY_BUSINESS_ACCOUNT,
          key: "gmb-connect",
          icon: <SettingOutlined />,
          route: ROUTE_CRM_GOOGLE_CONNECT,
          children: [],
        },
        {
          name: "Assignments",
          permission: TASK_MODULE_ACTIONS_READ_ASSIGNMENT_SETTINGS,
          key: "assignments",
          icon: <SettingOutlined />,
          route: ROUTE_CRM_ASSIGNMENT,
          children: [],
        },
        {
          name: "Metadata",
          key: "metadata",
          icon: <SettingOutlined />,
          route: ROUTE_CRM_METADATA,
          children: [],
        },
        {
          name: "Knowledge Bank",
          permission: RAG_MODULE_PERMISSIONS.knowledge_bank_read,
          key: "kbank",
          icon: <KBank />,
          route: ROUTE_CRM_KNOWLEDGE_BANK_LIST,
          children: [],
          mobileView: false,
        },
      ],
    },
  ];
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const history = useHistory();
  const { connection } = useSelector((state: RootState) => state.chat);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [changePasswordModalVisialbe, setchangePasswordModalVisialbe] =
    useState(false);
  const [passwordValues, setpasswordValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const { profile } = useSelector((state: RootState) => state.profile);
  const [currentUserData, setcurrentUserData] = useState([] as any);
  /*const { loading, currentUser, pagination } = useSelector(
    (state: RootState) => state.user
  );*/
  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;
  // console.log("llllllllllllllllll", myPermissions);

  const MENU_ITEMS = [
    ...MENU_ITEMS_CRM,
    {
      name: `${profile?.first_name ? profile?.first_name : ""}  ${profile?.last_name ? profile?.last_name : ""
        }`,
      key: "settings",
      className: "settings capitalize",
      icon: (
        <span className="user-letter-circle capitalize">
          {(profile?.first_name ? profile?.first_name?.split("")[0] : "") +
            (profile?.last_name ? profile?.last_name?.split("")[0] : "")}
        </span>
      ),
      route: "nopath",
      mobileView: true,
      children: [
        {
          name: (
            <div className="text-secondary">
              {profile?.first_name ? profile?.first_name : ""}{" "}
              {profile?.last_name ? profile?.last_name : ""}
              <br />
              <small>{profile?.phone ? profile?.phone : ""}</small>
            </div>
          ),
          key: "name",
          route: "name",
          children: [],
        },
        {
          name:
            currentUserData?.active === false ? "Unpause Shift" : "Pause Shift",
          key: "pauseShift",
          icon: <SettingOutlined />,
          route: "nopath",
          children: [],
        },
        {
          name: "Change Password",
          key: "changePassword",
          icon: <SettingOutlined />,
          route: "nopaths",
          children: [],
        },

        {
          name: "Logout",
          key: "logout",
          icon: <SettingOutlined />,
          route: "logout",
          children: [],
        },
      ],
    },
  ];
  const handleClicks = (item: any, event: any) => {
    if (item.key === "pauseShift") {
      return setIsModalVisible(true);
    }

    if (item.key === "changePassword") {
      return (
        // alert('Modal visiable')
        setchangePasswordModalVisialbe(true)
      );
    }

    if (item.key === "logout") {
      if (
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_PAUSE_SHIFT) ===
        -1 &&
        myPermissions?.indexOf(
          TASK_MODULE_ACTIONS_DISABLE_PAUSE_SHIFT?.split(".")[0]
        ) === -1
      ) {
        dispatch(unPauseShift({ active: false }));
      }
      return logOut();
    }
  };

  const handleOk = () => {
    const pauseData = {
      active: currentUserData?.active === false ? true : false,
    };
    setIsModalVisible(false);
    dispatch(unPauseShift(pauseData)).then((val: any) => {
      if (val?.payload?.data?.statusCode === 200) {
        // setIsModalVisible(false);
        message.success({
          content: "Agent status updated successfully",
          style: {
            marginBottom: "10vh",
          },
        });
        window.location.reload();
      }
    });
  };

  const handleOkChangePassword = () => {
    if (
      passwordValues.oldPassword === "" ||
      passwordValues.newPassword === "" ||
      passwordValues.confirmPassword === ""
    ) {
      message.error({
        content: "All fields are required.",
      });
    } else if (passwordValues.newPassword !== passwordValues.confirmPassword) {
      message.error({
        content: "Password does not match.",
      });
    } else {
      Auth.currentAuthenticatedUser()
        .then((user: any) => {
          return Auth.changePassword(
            user,
            passwordValues.oldPassword,
            passwordValues.newPassword
          );
        })
        .then((data: any) => {
          // console.log("ddddddddddddddddddddddddddddddddd", data)
          setchangePasswordModalVisialbe(false);
          message.success({
            content: "Password changed successfully.",
          });
          console.log(data);
        })
        .catch((err: any) => {
          message.error({
            content: err,
          });
          console.log(err);
        });
    }
  };

  // useEffect(() => {
  //   const wsCurrent = connection;
  //   if (!wsCurrent) return;
  //   wsCurrent.onmessage = (e: any) => {
  //     const _message = JSON.parse(e.data);
  //     console.log("xxxxxxxxxxx", _message)
  //     if (_message.type === 'new_assigned_notification') {
  //       notification.open({
  //         message: (
  //           <div style={{ color: 'white' }}>
  //             {_message.message}
  //           </div>
  //         ),
  //         icon: null,
  //         duration: 5,
  //         style: {
  //           backgroundColor: '#223042',
  //         },

  //       });
  //     }
  //   };

  // });

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelChangePassword = () => {
    setchangePasswordModalVisialbe(false);
  };

  useEffect(() => {
    dispatch(fetchCurrentUser()).then((item: any) => {
      setcurrentUserData(
        item?.payload?.data?.data ? item?.payload?.data?.data : []
      );
    });
  }, []);
  const logOut = () => {
    if (localStorage.getItem("auth-type") === "ad") {
      instance
        .logoutPopup({
          // @ts-ignore
          mainWindowRedirectUri: `${ROUTE_CRM_LOGIN(ACCOUNT_PREFIX)}/`,
        })
        .then(async () => {
          console.log("logout");
          await dispatch(logoutUser({ id: profile.id }));
          localStorage.clear();
        });
    } else {
      dispatch(logoutUser({ id: profile.id }));
    }
  };

  const setValuePassword = (e: any) => {
    const val = e.target.value;
    const name = e.target.name;

    setpasswordValues({ ...passwordValues, [name]: val });
  };
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  return (
    <>
      <Modal
        title="Change Password"
        visible={changePasswordModalVisialbe}
        onCancel={handleCancelChangePassword}
        footer={[
          <Button
            style={{ backgroundColor: "#37C2D8" }}
            block
            size="large"
            key="submit"
            type="primary"
            onClick={handleOkChangePassword}
          >
            Save
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <label className="headColor headColorss">Old Password</label>
            <Password name="oldPassword" onChange={setValuePassword} />
          </Col>
          <br />
          <br />
          <br />
          <Col span={24}>
            <label className="headColor">New Password</label>
            <Password name="newPassword" onChange={setValuePassword} />
          </Col>
          <br />
          <br />
          <br />
          <Col span={24}>
            <label className="headColor">Confirm Password</label>
            <Password name="confirmPassword" onChange={setValuePassword} />
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={isModalVisible}
        centered
        width={"340px"}
        footer={null}
        closable={false}
      >
        <p>
          Are you sure you want to{" "}
          {currentUserData?.active === false ? `unpause` : `pause`} this shift ?
        </p>
        <Button onClick={handleCancel} style={{ width: "48%" }}>
          No
        </Button>{" "}
        <Button onClick={handleOk} style={{ width: "48%" }} type="primary">
          Yes
        </Button>
      </Modal>

      <Menu
        id="crm-menu"
        onClick={(e: any) => {
          if (
            e.key !== "nopath" &&
            e.key !== "nopaths" &&
            e.key !== "name" &&
            e.key !== "logout"
          ) {
            history.push(e.key);
          }
        }}
        defaultOpenKeys={[MENU_ITEMS[0].name]}
        mode="inline"
        theme="light"
        itemIcon={true}
      >
        {MENU_ITEMS.map((menuGroup: any) => {
          if (
            (screenType.mobile && menuGroup.mobileView) ||
            !screenType.mobile
          ) {
            if (menuGroup?.children?.length === 0) {
              if (
                menuGroup?.permission &&
                myPermissions?.indexOf(menuGroup?.permission) === -1 &&
                myPermissions?.indexOf(menuGroup?.permission?.split(".")[0]) ===
                -1
              ) {
                return <></>;
              } else {
                return (
                  <Menu.Item
                    key={menuGroup.route}
                    icon={menuGroup.icon}
                    title={t(menuGroup.name)}
                  >
                    {t(menuGroup.name)}
                  </Menu.Item>
                );
              }
            } else {
              if (
                menuGroup?.permission &&
                myPermissions?.indexOf(menuGroup?.permission) === -1 &&
                myPermissions?.indexOf(menuGroup?.permission?.split(".")[0]) ===
                -1
              ) {
                return <></>;
              } else {
                return (
                  <SubMenu
                    key={menuGroup.name}
                    icon={menuGroup.icon}
                    title={
                      t(menuGroup?.name)?.length <= 12
                        ? t(menuGroup?.name)
                        : t(menuGroup?.name)?.slice(0, 12) + "..."
                    }
                    className={menuGroup?.className}
                  >
                    {menuGroup.children &&
                      menuGroup.children.map((menuItem: any, index: number) => {
                        if (
                          menuItem?.permission &&
                          myPermissions?.indexOf(menuItem?.permission) === -1 &&
                          myPermissions?.indexOf(
                            menuItem?.permission?.split(".")[0]
                          ) === -1
                        ) {
                          return <></>;
                        } else {
                          return menuItem.key === "name" ? (
                            <li
                              className="crm-size-bar-namecard ant-menu-item ant-menu-item-only-child"
                              key={index}
                            >
                              {menuItem.name}
                            </li>
                          ) : (
                            <Menu.Item
                              onClick={(e) => {
                                handleClicks(menuItem, e);
                              }}
                              key={menuItem.route}
                            >
                              {t(menuItem.name)}
                            </Menu.Item>
                          );
                        }
                      })}
                  </SubMenu>
                );
              }
            }
          }
        })}
      </Menu>
    </>
  );
};

export default Sidebar;
