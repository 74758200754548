import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_RAG_API_URL } from "../../shared/constants";

import { Client } from "../../shared/Utils/api-client";
import {
  API_CRM_ANSWER_BY_QUESTION_ID_KNOWLEDGE_BANK,
  API_CRM_ASK_QUESTION_KNOWLEDGE_BANK,
  API_CRM_GET_MODELS_KNOWLEDGE_BANK,
  API_CRM_GET_PROMPTS_KNOWLEDGE_BANK,
  API_CRM_KNOWLEDGE_BANK_CREATE,
  API_CRM_KNOWLEDGE_BANK_DOCUMENTS_CHUNKS,
  API_CRM_KNOWLEDGE_BANK_DOCUMENTS_GET_UPLOAD_SIGNED_URL,
  API_CRM_KNOWLEDGE_BANK_DOCUMENTS_GET_URL,
  API_CRM_KNOWLEDGE_BANK_DOCUMENTS_LIST,
  API_CRM_KNOWLEDGE_BANK_DOCUMENTS_PROCESS_FILE,
  API_CRM_KNOWLEDGE_BANK_DOCUMENT_UPDATE,
  API_CRM_KNOWLEDGE_BANK_LIST,
  API_CRM_KNOWLEDGE_BANK_UPDATE,
  API_CRM_RECENT_QUESTION_KNOWLEDGE_BANK,
  API_CRM_SIMILAR_QUESTION_KNOWLEDGE_BANK,
} from "../../shared/routes/crm.routes.constants";
const config = {
  name: "knowledgebank",
};
export const fetchKnowledgeBankModels: any = createAsyncThunk(
  `${config.name}/fetchKnowledgeBankModels`,
  async (params: any) => {
    return await Client.get(API_CRM_GET_MODELS_KNOWLEDGE_BANK, {
      params,
      baseUrl: BASE_RAG_API_URL,
    });
  }
);
export const fetchKnowledgeBankPrompts: any = createAsyncThunk(
  `${config.name}/fetchKnowledgeBankPrompts`,
  async () => {
    return await Client.get(API_CRM_GET_PROMPTS_KNOWLEDGE_BANK, {
      baseUrl: BASE_RAG_API_URL,
    });
  }
);
export const fetchKnowledgeBankList: any = createAsyncThunk(
  `${config.name}/fetchKnowledgeBankList`,
  async (params: any) => {
    return await Client.get(API_CRM_KNOWLEDGE_BANK_LIST, {
      params,
      baseUrl: BASE_RAG_API_URL,
    });
  }
);
export const createKnowledgeBank: any = createAsyncThunk(
  `${config.name}/createKnowledgeBank`,
  async (body: any) => {
    return await Client.post(API_CRM_KNOWLEDGE_BANK_CREATE, body, {
      baseUrl: BASE_RAG_API_URL,
    });
  }
);
export const updateKnowledgeBank: any = createAsyncThunk(
  `${config.name}/updateKnowledgeBank`,
  async ({ id, body }: any) => {
    return await Client.put(API_CRM_KNOWLEDGE_BANK_UPDATE(id), body, {
      baseUrl: BASE_RAG_API_URL,
    });
  }
);
export const fetchKnowledgeBankDocumentList: any = createAsyncThunk(
  `${config.name}/fetchKnowledgeBankDocumentList`,
  async (id: any) => {
    return await Client.get(API_CRM_KNOWLEDGE_BANK_DOCUMENTS_LIST(id), {
      baseUrl: BASE_RAG_API_URL,
    });
  }
);
export const fetchKnowledgeBankDocumentChunks: any = createAsyncThunk(
  `${config.name}/fetchKnowledgeBankDocumentChunks`,
  async (id: any) => {
    return await Client.get(API_CRM_KNOWLEDGE_BANK_DOCUMENTS_CHUNKS(id), {
      baseUrl: BASE_RAG_API_URL,
    });
  }
);
export const fetchKnowledgeBankDocumentURL: any = createAsyncThunk(
  `${config.name}/fetchKnowledgeBankDocumentURL`,
  async (id: any) => {
    return await Client.get(API_CRM_KNOWLEDGE_BANK_DOCUMENTS_GET_URL(id), {
      baseUrl: BASE_RAG_API_URL,
    });
  }
);
export const fetchKnowledgeBankDocumentUploadSignedUrl: any = createAsyncThunk(
  `${config.name}/fetchKnowledgeBankDocumentUploadSignedUrl`,
  async (params: any) => {
    return await Client.get(
      API_CRM_KNOWLEDGE_BANK_DOCUMENTS_GET_UPLOAD_SIGNED_URL,
      { params, baseUrl: BASE_RAG_API_URL }
    );
  }
);
export const fetchKnowledgeBankDocumentProcessFile: any = createAsyncThunk(
  `${config.name}/fetchKnowledgeBankDocumentProcessFile`,
  async (id: any) => {
    return await Client.post(
      API_CRM_KNOWLEDGE_BANK_DOCUMENTS_PROCESS_FILE,
      {},
      { params: { file_id: id }, baseUrl: BASE_RAG_API_URL }
    );
  }
);
export const patchKnowledgeBankDocument: any = createAsyncThunk(
  `${config.name}/patchKnowledgeBankDocument`,
  async ({ id, body }: any) => {
    return await Client.patch(
      API_CRM_KNOWLEDGE_BANK_DOCUMENT_UPDATE(id),
      body,
      { baseUrl: BASE_RAG_API_URL }
    );
  }
);
export const askQuestionKnowledgeBank: any = createAsyncThunk(
  `${config.name}/askQuestionKnowledgeBank`,
  async (data: any) => {
    return await Client.post(API_CRM_ASK_QUESTION_KNOWLEDGE_BANK, data?.body, {
      params: data?.params,
      baseUrl: BASE_RAG_API_URL,
    });
  }
);
export const getSimilarQuestionsKnowledgeBank: any = createAsyncThunk(
  `${config.name}/getSimilarQuestionsKnowledgeBank`,
  async (params: any) => {
    return await Client.get(API_CRM_SIMILAR_QUESTION_KNOWLEDGE_BANK, {
      params,
      baseUrl: BASE_RAG_API_URL,
    });
  }
);
export const getRecentQuestionsKnowledgeBank: any = createAsyncThunk(
  `${config.name}/getRecentQuestionsKnowledgeBank`,
  async (params: any) => {
    return await Client.get(API_CRM_RECENT_QUESTION_KNOWLEDGE_BANK, {
      params,
      baseUrl: BASE_RAG_API_URL,
    });
  }
);
export const getAnswerByQuestionIdKnowledgeBank: any = createAsyncThunk(
  `${config.name}/getAnswerByQuestionIdKnowledgeBank`,
  async (id: string) => {
    return await Client.get(API_CRM_ANSWER_BY_QUESTION_ID_KNOWLEDGE_BANK(id), {
      baseUrl: BASE_RAG_API_URL,
    });
  }
);

export const agents = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    pagination: { offset: 0, limit: 10, total: 0 },
    error: "" as string,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.pagination = { offset: 0, limit: 10, total: 0 };
      state.error = "";
    },
  },
  extraReducers(builder) {},
});

//export const {} = agents.actions;

export default agents.reducer;
