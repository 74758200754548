import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  calculateDueDate,
  fetchMasterCategories,
  fetchMasterTaskDropDown,
  fetchMasterTaskSubTypes,
  fetchMasterTaskTypesTicket
} from "../../../../redux/reducers/master.slice";
import { fetchTeams } from "../../../../redux/reducers/team.slice";
import { formatDropDownValue } from "../../../../shared/Utils/utilities";
import { IS_CIPLA, IS_JARVIS, RootState } from "../../../../shared/constants";

import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { fetchActionMap } from "../../../../redux/reducers/actionmap.slice";
import { fetchAllAgents } from "../../../../redux/reducers/agents.slice";
import { fetchBranches } from "../../../../redux/reducers/branch.slice";
import {
  TASK_MODULE_ACTIONS_CALCULATE_DUE_DATE,
  TASK_MODULE_ACTIONS_DISABLE_ASSIGNEE_IN_CREATE_TICKET,
  TASK_MODULE_ACTIONS_DISABLE_ASSIGN_TO_IN_CREATE_TICKET,
  TASK_MODULE_ACTIONS_DISABLE_ATTACHMENT,
  TASK_MODULE_ACTIONS_DISABLE_DUE_DATE_IN_CREATE_TICKET,
  TASK_MODULE_ACTIONS_DISABLE_SOURCE_IN_CREATE_TICKET,
  TASK_MODULE_ACTIONS_HIDE_ATTACHMENT,
  TASK_MODULE_ACTIONS_HIDE_BRANCH,
  TASK_MODULE_ACTIONS_HIDE_DEFAULT_VALUE_SOURCE,
  TASK_MODULE_ACTIONS_HIDE_TITLE,
} from "../../../../shared/components/permission.constants";
import { GenericFormItems } from "../../../../shared/layouts/formbar.layout";
import UploadPrescription from "../../../../shared/layouts/uploadprescription";

const { Option } = Select;
const { TextArea } = Input;

export function processCustomFieldsValues(values: any, _custom_fields: any) {
  _custom_fields?.map((val: any) => {
    let key = val?.name?.[1];
    if (values.meta?.[key]) {
      switch (val.type) {
        case "DatePicker":
          values.meta[key] = moment(values.meta[key]).format("DD MMM'YY");
          break;
        case "DateTimePicker":
          values.meta[key] = moment(values.meta[key]).format("DD MMM'YY, LT");
          break;
        case "TimePicker":
          values.meta[key] = moment(values.meta[key]).format("LT");
          break;
        case "RangePicker":
          if (values.meta[key][0]) {
            values.meta[key] = moment(values.meta[key][0]).format("DD MMM'YY");
          }
          if (values.meta[key][1]) {
            values.meta[key] +=
              " to " + moment(values.meta[key][1]).format("DD MMM'YY");
          }
          break;
        case "Cascader":
          let names = val.nameArray;
          for (let k = 0; k < names?.length; ++k) {
            values.meta[names[k]] = values.meta[key][k];
          }
          delete values.meta[key];
          break;
        case "FormList": {
          values.meta[key]?.data?.map((subFormValues: any, index: any) => {
            val?.form_elements?.map((subFormElement: any) => {
              let subKey = subFormElement?.name;
              let value = values.meta[key].data[index][subKey];
              if (index == 0)
                values.meta[key].order = [
                  ...(values.meta[key].order ? values.meta[key].order : []),
                  ...(subFormElement.nameArray?.length
                    ? subFormElement.nameArray
                    : [subFormElement.name]),
                ];
              if (value) {
                switch (subFormElement.type) {
                  case "DatePicker":
                    values.meta[key].data[index][subKey] = moment(
                      values.meta[key].data[index][subKey]
                    ).format("DD MMM'YY");
                    break;
                  case "DateTimePicker":
                    values.meta[key].data[index][subKey] = moment(
                      values.meta[key].data[index][subKey]
                    ).format("DD MMM'YY, LT");
                    break;
                  case "TimePicker":
                    values.meta[key].data[index][subKey] = moment(
                      values.meta[key].data[index][subKey]
                    ).format("LT");
                    break;
                  case "RangePicker":
                    if (values.meta[key].data[index][subKey][0]) {
                      values.meta[key].data[index][subKey] = moment(
                        values.meta[key].data[index][subKey][0]
                      ).format("DD MMM'YY");
                    }
                    if (values.meta[key].data[index][subKey][1]) {
                      values.meta[key].data[index][subKey] +=
                        " to " +
                        moment(values.meta[key].data[index][subKey][1]).format(
                          "DD MMM'YY"
                        );
                    }
                    break;
                  case "Cascader":
                    let names = subFormElement.nameArray;
                    for (let k = 0; k < names?.length; ++k) {
                      values.meta[key].data[index][names[k]] =
                        values.meta[key].data[index][subKey][k];
                    }
                    delete values.meta[key].data[index][subKey];
                    break;
                }
              }
            });
          });
        }
      }
    }
  });
}

type Props = {
  type?: any;
  createTicket: (values: any) => void;
  selectedService: any;
};
const TicketForm: React.FunctionComponent<Props> = ({
  type,
  createTicket,
  selectedService,
}) => {
  // console.log(createTicket)
  const dispatch = useDispatch();
  const [form] = useForm();
  const { taskTypesTicket, taskSubTypes, taskCategories, taskDropDown } = useSelector(
    (state: RootState) => state.master
  );
  const { branches } = useSelector((state: RootState) => state.branch);
  const { agentsAll } = useSelector((state: RootState) => state.agents);
  const { teams } = useSelector((state: RootState) => state.team);
  const [userList, setUserList] = useState(false as any);
  const [type_id, setType_id] = useState(null as any);
  const [sub_type_id, setSubType_id] = useState(null as any);
  const [category_id, setcategory_id] = useState(null as any);
  const [custom_fields, set_custom_fields] = useState([] as any);

  const [priorityList, setPriorityList] = useState([] as any);
  const [sourceList, setSourceList] = useState([] as any);
  const [teamList, setTeamList] = useState(null as any);
  const [assigneeList, setAssigneeList] = useState([] as any);
  const [statusList, setStatusList] = useState([] as any);
  const [showFollowUp, setshowFollowUp] = useState(false);
  const [showDoctorDetails, setshowDoctorDetails] = useState(false);
  const { t } = useTranslation();

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const [dueDateDefault, setdueDateDefault] = useState(null as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setfiles] = useState(null as any);
  const [documentName, setdocumentName] = useState(null as any);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fileUploaded = async (response: any) => {
    // debugger
    form.setFieldsValue({
      file_upload_ids: response,
    });
    handleCancel();
    setfiles(response);
  };
  const getDescription = (value: any) => {
    setdocumentName(value);
  };

  useEffect(() => {
    (async () => {
      if (
        myPermissions?.indexOf("task_module") !== -1 ||
        myPermissions?.indexOf(TASK_MODULE_ACTIONS_CALCULATE_DUE_DATE) !== -1
      ) {
        if (type_id && sub_type_id) {
          const params = {} as any;
          params.mode = "ticket";
          if (type_id) {
            params.type_id = type_id;
          }
          if (sub_type_id) {
            params.sub_type_id = sub_type_id;
          }
          if (category_id) {
            params.category_id = category_id;
          }
          if (sourceList?.length) {
            params.source_id = sourceList[0]?.value;
          }

          const res = await dispatch(calculateDueDate(params));
          if (res?.payload?.data?.statusCode === 200) {
            setdueDateDefault(res?.payload?.data?.data);

            form.setFieldsValue({
              due_date: moment(res?.payload?.data?.data),
            });
          }
        }
        if (
          type_id === undefined ||
          type_id === null ||
          type_id === "" ||
          sub_type_id === undefined ||
          sub_type_id === null ||
          sub_type_id === ""
        ) {
          setdueDateDefault(moment().add(24, "h"));
          form.setFieldsValue({
            due_date: moment().add(24, "h"),
          });
        }
      }
    })();
  }, [type_id, sub_type_id, category_id]);

  useEffect(() => {
    if (taskDropDown.length > 0) {
      let temp = taskDropDown.filter((val: any) => {
        return val.name == "priority" && val.mode == "ticket";
      });
      let vals;
      if (temp.length > 0) {
        vals = temp[0].values.map((val: any) => {
          return { name: val.name, value: val.id };
        });
        setPriorityList(vals);
      }

      temp = taskDropDown.filter((val: any) => {
        return val.name == "status" && val.mode == "ticket";
      });
      setStatusList(temp[0]?.values);

      temp = taskDropDown.filter((val: any) => {
        return val.name == "status" && val.mode == "ticket";
      });
      setStatusList(temp[0]?.values);
      temp = taskDropDown.filter((val: any) => {
        return val.name == "source" && val.mode == "ticket";
      });
      if (temp.length > 0) {
        vals = temp[0].values.map((val: any) => {
          return { name: val.name, value: val.id };
        });
        setSourceList(vals);
      }
    }
  }, [taskDropDown]);

  useEffect(() => {
    taskDropDown.length == 0 && dispatch(fetchMasterTaskDropDown());
    branches?.length == 0 && dispatch(fetchBranches({}));
    dispatch(fetchMasterTaskTypesTicket({ mode: "ticket" }));
    // dispatch(fetchAgents({limit: 500}))
    dispatch(fetchAllAgents({ limit: 15000 }));
    dispatch(fetchTeams({ limit: 15000 }));
  }, []);
  useEffect(() => {
    // if (agents.length > 0) {
    let vals = agentsAll.map((val: any) => {
      return {
        name: `${val?.user?.first_name && val?.user?.first_name !== "null"
          ? val?.user?.first_name
          : ""
          } ${val.user?.last_name && val.user?.last_name !== "null"
            ? val.user?.last_name
            : ""
          }`,
        value: val.user_id,
      };
    });
    setUserList(vals);
    // }
  }, [agentsAll]);
  useEffect(() => {
    if (teams.length > 0) {
      let vals = teams.map((val: any) => {
        return { name: formatDropDownValue(val.name), value: val.id };
      });
      setTeamList(vals);
    }
  }, [teams]);
  const getSubCategory = (type: any) => {
    setType_id(type);
    type && dispatch(fetchMasterTaskSubTypes({ task_type_id: type }));
  };

  const getCategory = (type: any) => {
    setSubType_id(type);
    type && dispatch(fetchMasterCategories({ task_sub_type_id: type }));
  };

  const onChangeCategory = (category: any) => {
    setcategory_id(category);
    dispatch(
      fetchActionMap({
        mode: "ticket",
        type_id: type_id,
        sub_type_id: sub_type_id,
        category_id: category,
      })
    ).then((val: any) => {
      if (val?.payload?.data?.data?.items?.length > 0) {
        let custom_fields = val?.payload?.data?.data?.items[0]?.custom_fields;
        // custom_fields = [
        //   {
        //     type: "FormList",
        //     name: "Products",
        //     label: "Products",
        //     initialValue: [""],
        //     form_elements: [
        //       {
        //         label: "Product",
        //         type: "Cascader",
        //         name: "ProductMeta",
        //         nameArray: ["Country", "Product", "SKU"],
        //         data: [
        //           {
        //             label: "Ghana",
        //             value: "Ghana",
        //             children: [
        //               {
        //                 label: "Betaloc ZOK",
        //                 value: "Betaloc ZOK",
        //                 children: [
        //                   {
        //                     label: "25mg Tab (Blister) 28's",
        //                     value: "25mg Tab (Blister) 28's",
        //                   },
        //                   {
        //                     label: "50mg Tab (Bottle) 28's",
        //                     value: "50mg Tab (Bottle) 28's",
        //                   },
        //                   {
        //                     label: "100mg Tab (Bottle) 28's",
        //                     value: "100mg Tab (Bottle) 28's",
        //                   },
        //                 ],
        //               },
        //             ],
        //           },
        //         ],
        //       },
        //       {
        //         label: "Quantity",
        //         type: "InputNumber",
        //         name: "Quantity",
        //       },
        //     ],
        //   },
        // ];
        custom_fields = custom_fields?.map((val: any) => {
          return {
            ...val,
            name: ["meta", val.name],
            // name:
            //   val.name?.isArray() && val?.name == "meta"
            //     ? val.name
            //     : ["meta", val.name],
            colSpan:
              val?.type == "TextArea" || val?.type == "FormList" ? 24 : 8,
          };
        });
        set_custom_fields(custom_fields);
        setTimeout(() => {
          if (custom_fields?.length > 0) {
            custom_fields?.map((val: any) => {
              if (val?.initialValue) {
                if (val?.type == "FormList")
                  form.setFieldValue([...val?.name, "data"], val?.initialValue);
                else form.setFieldValue(val?.name, val?.initialValue);
              }
            });
          }
        }, 200);
      }
    });
  };

  useEffect(() => {
    const leadCallLog =
      sourceList?.length > 0 &&
      sourceList?.filter((val: any) => {
        return val.name === "call_logs";
      });
    if (sourceList?.length > 0) {
      form.setFieldsValue({
        source_id: leadCallLog[0]?.value
          ? leadCallLog[0]?.value
          : myPermissions?.indexOf("task_module") !== -1 ||
            myPermissions?.indexOf(
              TASK_MODULE_ACTIONS_HIDE_DEFAULT_VALUE_SOURCE
            ) !== -1
            ? null
            : sourceList[0]?.value, //
      });
    }
  }, [sourceList]);

  useEffect(() => {
    if (branches.length > 0) {
      form.setFieldsValue({
        branch_id: branches[0].id,
      });
    }
  }, [branches]);

  useEffect(() => {
    if (priorityList.length > 0) {
      form.setFieldsValue({
        priority_id: priorityList[0].value,
      });
    }
  }, [priorityList]);
  useEffect(() => {
    if (statusList?.length > 0) {
      form.setFieldsValue({
        status_id: statusList[0].id,
      });
    }
  }, [statusList]);
  console.log("statusList:", statusList);
  useEffect(() => {
    if (assigneeList?.length > 0) {
      form.setFieldsValue({
        assignee: assigneeList[0].value,
      });
    }
  }, [assigneeList]);

  useEffect(() => {
    const { assign_to } = form.getFieldsValue();
    if (assign_to) {
      changeSelectedAssignType(assign_to);
    }
  }, [userList]);

  function changeSelectedAssignType(e: any) {
    if (e === "self") {
      form.setFieldsValue({
        assignee: "",
      });
      setAssigneeList([]);
    }
    if (e == "agent") {
      setAssigneeList(userList);
    }
    if (e == "team") {
      setAssigneeList(teamList);
    }
    form.setFieldsValue({
      assignee: null,
    });
  }

  function onChangeValues(data: any) {
    if (data?.type_id) {
      setSubType_id(null);
      form.setFieldsValue({
        sub_type_id: null,
        category_id: null,
      });
    }
    if (data?.sub_type_id) {
      form.setFieldsValue({
        category_id: null,
      });
    }
    if (data.status_id) {
      const statusName = statusList?.filter((val: any) => {
        return val.id === data.status_id;
      })[0]?.name;
      if (statusName === "follow-up") {
        setshowFollowUp(true);
      } else {
        setshowFollowUp(false);
      }
      if (statusName === "feedback_received") {
        setshowDoctorDetails(true);
      } else {
        setshowDoctorDetails(false);
      }
    }
  }

  const numbers = Array.from({ length: 10 }, (_, index) => index + 1);

  return (
    <>
      <Form
        form={form}
        name={"ticket_form"}
        className="crm-form"
        layout={"vertical"}
        onFinish={(values: any) => {
          try {
            processCustomFieldsValues(values, custom_fields);
          } catch (e) { }
          createTicket(values);
        }}
        initialValues={{
          due_date: dueDateDefault
            ? moment(dueDateDefault)
            : moment().add(24, "h"),
          // due_date: myPermissions?.indexOf("task_module") !== -1 || myPermissions?.indexOf(TASK_MODULE_ACTIONS_DISABLE_DUE_DATE_IN_CREATE_TICKET) !== -1 ? null : moment().add(24, "h"),
        }}
        onValuesChange={onChangeValues}
      >
        <Row gutter={16}>
          <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
            {myPermissions?.indexOf("task_module") !== -1 ||
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_TITLE) !== -1 ? (
              <>
                <Form.Item
                  name={"type_id"}
                  label={t("CRM.Tickets.Fields.Ticket Type")}
                  rules={[{ required: true, message: "Please select type" }]}
                >
                  <Select
                    allowClear={true}
                    showSearch
                    optionFilterProp="children"
                    onChange={(evt) => getSubCategory(evt)}
                  >
                    {taskTypesTicket.map((e: any) => {
                      return (
                        <Option key={e.id} value={e.id}>
                          {formatDropDownValue(e.name)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={"sub_type_id"}
                  label={t("CRM.Tickets.Fields.Ticket Sub Type")}
                  rules={[
                    { required: true, message: "Please select sub type" },
                  ]}
                >
                  <Select
                    allowClear={true}
                    showSearch
                    optionFilterProp="children"
                    disabled={type_id ? false : true}
                    onChange={(evt) => getCategory(evt)}
                  >
                    {taskSubTypes.map((e: any) => {
                      return (
                        <Option key={e.id} value={e.id}>
                          {formatDropDownValue(e.name)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item name={"title"} label="Title">
                  <Input />
                </Form.Item>

                <Form.Item
                  name={"type_id"}
                  label={t("CRM.Tickets.Fields.Ticket Type")}
                  rules={[{ required: true, message: "Please select type" }]}
                >
                  <Select
                    allowClear={true}
                    showSearch
                    optionFilterProp="children"
                    onChange={(evt) => getSubCategory(evt)}
                  >
                    {taskTypesTicket.map((e: any) => {
                      return (
                        <Option key={e.id} value={e.id}>
                          {formatDropDownValue(e.name)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </>
            )}
          </Col>
          <Col {...{ xs: 24, sm: 24, md: 16, lg: 16, xl: 16 }}>
            <Form.Item name={"ticket_description"} label="Description">
              <TextArea rows={5}></TextArea>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {myPermissions?.indexOf("task_module") !== -1 ||
            myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_TITLE) !== -1
            ? []
            : [
              <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                <Form.Item
                  name={"sub_type_id"}
                  label={t("CRM.Tickets.Fields.Ticket Sub Type")}
                  rules={[
                    { required: true, message: "Please select sub type" },
                  ]}
                >
                  <Select
                    allowClear={true}
                    showSearch
                    optionFilterProp="children"
                    disabled={type_id ? false : true}
                    onChange={(evt) => getCategory(evt)}
                  >
                    {taskSubTypes.map((e: any) => {
                      return (
                        <Option key={e.id} value={e.id}>
                          {formatDropDownValue(e.name)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>,
            ]}
          <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
            <Form.Item
              name={"category_id"}
              label={t("CRM.Tickets.Fields.Ticket Category")}
              rules={[{ required: true, message: "Please select category" }]}
            >
              <Select
                allowClear={true}
                showSearch
                optionFilterProp="children"
                onChange={(evt) => onChangeCategory(evt)}
                disabled={sub_type_id ? false : true}
              >
                {taskCategories.map((e: any) => {
                  return (
                    <Option key={e.id} value={e.id}>
                      {formatDropDownValue(e.name)}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          {/* {myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_STATUS) !==
            -1 ? null : (
            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              <Form.Item name={"status_id"} label="Status">
                <Select
                  allowClear={true}
                  showSearch
                  optionFilterProp="children"
                >
                  {statusList?.map((e: any) => {
                    return (
                      <Option key={e.id} value={e.id}>
                        {formatDropDownValue(e.name)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          )} */}
          <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
            <Form.Item name={"status_id"} label="Status">
              <Select allowClear={true} showSearch optionFilterProp="children">
                {statusList?.map((e: any) => {
                  return (
                    <Option key={e.id} value={e.id}>
                      {formatDropDownValue(e.name)}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {showFollowUp && (
            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              <Form.Item
                name={"follow_up_date"}
                label="Follow Up Date"
                rules={[
                  { required: true, message: "Please select Follow Up Date" },
                ]}
              >
                <DatePicker
                  showTime={{ format: "hh:mm A" }}
                  format="DD MMM'YY hh:mm A"
                  className={"full-width"}
                  disabledDate={(d: any) => {
                    return d < moment(moment().format("YYYY-MM-DD"));
                  }}
                ></DatePicker>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          {showDoctorDetails && (
            <>
              <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                <Form.Item name={"doctor_rating"} label="Doctor Rating">
                  <Select defaultValue="1">
                    {numbers.map((number) => (
                      <Option key={number} value={number.toString()}>
                        {number}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                <Form.Item name={"doctor_remarks"} label="Doctor Remarks">
                  <Input />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        {type == 1 && (
          <Row gutter={32}>
            <Divider></Divider>
            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              <Form.Item name={"due_date"} label="Due Date">
                <DatePicker
                  disabled={
                    myPermissions?.indexOf("task_module") !== -1 ||
                      myPermissions?.indexOf(
                        TASK_MODULE_ACTIONS_DISABLE_DUE_DATE_IN_CREATE_TICKET
                      ) !== -1
                      ? true
                      : false
                  }
                  showTime={{ format: "hh:mm A" }}
                  format="DD MMM'YY hh:mm A"
                  className={"full-width"}
                  disabledDate={(d: any) => {
                    return d < moment(moment().format("YYYY-MM-DD"));
                  }}
                ></DatePicker>
              </Form.Item>
            </Col>
            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              {priorityList && (
                <Form.Item name={"priority_id"} label="Priority">
                  <Select
                    allowClear={true}
                    showSearch
                    optionFilterProp="children"
                  >
                    {priorityList.map((item: any) => {
                      return (
                        <Option value={item.value}>
                          {formatDropDownValue(item.name)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
            </Col>
            {branches &&
              !selectedService &&
              myPermissions?.indexOf("task_module") == -1 &&
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_BRANCH) == -1 && (
                <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                  <Form.Item
                    name={"branch_id"}
                    label={t("CRM.Common.Fields.Hospital")}
                    rules={
                      IS_JARVIS
                        ? []
                        : [
                          {
                            required: true,
                            message: "Please select hospital",
                          },
                        ]
                    }
                  >
                    <Select
                      allowClear={true}
                      showSearch
                      optionFilterProp="children"
                      className="capitalize"
                    >
                      {branches.map((e: any) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {formatDropDownValue(e.name)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            {myPermissions?.indexOf("task_module") !== -1 ||
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_ATTACHMENT) !==
              -1 ? (
              ""
            ) : (
              <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                <Form.Item name={"file_upload_ids"} label={"Select File"}>
                  <Button
                    type={"default"}
                    onClick={showModal}
                    style={{ textDecoration: "underline" }}
                    disabled={
                      myPermissions?.indexOf("task_module") !== -1 ||
                        myPermissions?.indexOf(
                          TASK_MODULE_ACTIONS_DISABLE_ATTACHMENT
                        ) !== -1
                        ? true
                        : false
                    }
                  >
                    {`${documentName
                      ? "Attached File: " + documentName
                      : "Add Attachment"
                      } `}
                  </Button>
                </Form.Item>
              </Col>
            )}

            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              {sourceList && (
                <Form.Item
                  name={"source_id"}
                  label="Source"
                  rules={
                    IS_CIPLA
                      ? [{ required: true, message: "Please select Source" }]
                      : []
                  }
                >
                  <Select
                    allowClear={true}
                    disabled={
                      myPermissions?.indexOf("task_module") !== -1 ||
                        myPermissions?.indexOf(
                          TASK_MODULE_ACTIONS_DISABLE_SOURCE_IN_CREATE_TICKET
                        ) !== -1
                        ? true
                        : false
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    {sourceList?.map((item: any) => {
                      return (
                        <Option value={item.value}>
                          {formatDropDownValue(item.name)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
            </Col>
            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              <Form.Item name={"assign_to"} label="Assign To">
                <Select
                  disabled={
                    myPermissions?.indexOf("task_module") !== -1 ||
                      myPermissions?.indexOf(
                        TASK_MODULE_ACTIONS_DISABLE_ASSIGN_TO_IN_CREATE_TICKET
                      ) !== -1
                      ? true
                      : false
                  }
                  allowClear
                  onChange={changeSelectedAssignType}
                >
                  <Option key={"self"} value={"self"}>
                    Self
                  </Option>
                  <Option key={"agent"} value={"agent"}>
                    Agent
                  </Option>
                  <Option key={"team"} value={"team"}>
                    Team
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              <Form.Item name={"assignee"} label="Assignee">
                <Select
                  disabled={
                    myPermissions?.indexOf("task_module") !== -1 ||
                      myPermissions?.indexOf(
                        TASK_MODULE_ACTIONS_DISABLE_ASSIGNEE_IN_CREATE_TICKET
                      ) !== -1
                      ? true
                      : false
                  }
                  showSearch={true}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {assigneeList &&
                    assigneeList.map((e: any) => {
                      return (
                        <Option key={e.value} value={e.value}>
                          {formatDropDownValue(e.name)}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={32}>
          <GenericFormItems formElements={custom_fields} />
        </Row>

        <Row gutter={16} className="button-row-2">
          <Col span={24}>
            {" "}
            <Button
              type="primary"
              size="large"
              block
              color={"primary"}
              htmlType={"submit"}
            >
              {t("CRM.Common.Actions.Create Ticket")}
            </Button>{" "}
          </Col>
        </Row>
      </Form>
      <Modal
        title="Upload Document"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <UploadPrescription
          getDescription={getDescription}
          fileUploaded={fileUploaded}
          handleCancel={handleCancel}
        />
      </Modal>
    </>
  );
};

export default TicketForm;
