import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "../../shared/Utils/api-client";
import { METAALLTASKDROPDOWN } from "../../shared/routes/crm.routes.constants";
import {
  CALCULATE_DUE_DATE,
  CUSTOM_SLA_RULE,
  CUSTOM_SLA_RULE_ADD,
  CUSTOM_SLA_RULE_BY_ID,
  CUSTOM_SLA_RULE_UPDATE,
  DROPDOWN_MASTER_DATA_BY_ID,
  ENABLE_DISABLE_SLA_RULE,
  GET_CRM_TYPES_DATA,
  MASTER_CITIES,
  MASTER_DESIGNATIONS,
  MASTER_GENDER,
  MASTER_NATIONALITY,
  MASTER_PROCEDURE,
  MASTER_PROPERTY,
  MASTER_REGION,
  MASTER_RELIONSHIPS,
  MASTER_SPECIALITIES,
  MASTER_WORKING_DAY,
  TASK_CATEGORIES,
  TASK_DROP_DOWN,
  TASK_SUB_TYPES,
  TASK_TYPES_LEAD,
  TASK_TYPES_TICKET,
} from "../../shared/routes/route.constants";
const config = {
  name: "master",
};
export const fetchMasterDesignations: any = createAsyncThunk(
  `${config.name}/fetchMasterDesignations`,
  async () => {
    return await Client.get(MASTER_DESIGNATIONS);
  }
);

export const fetchMasterGender: any = createAsyncThunk(
  `${config.name}/fetchMasterGender`,
  async () => {
    return await Client.get(MASTER_GENDER);
  }
);

export const fetchMasterCities: any = createAsyncThunk(
  `${config.name}/fetchMasterCities`,
  async () => {
    return await Client.get(MASTER_CITIES);
  }
);
export const fetchMasterRegion: any = createAsyncThunk(
  `${config.name}/fetchMasterRegion`,
  async () => {
    return await Client.get(MASTER_REGION);
  }
);

export const fetchMasterNationality: any = createAsyncThunk(
  `${config.name}/fetchMasterNationality`,
  async () => {
    return await Client.get(MASTER_NATIONALITY);
  }
);

export const fetchMasterDays: any = createAsyncThunk(
  `${config.name}/fetchMasterDays`,
  async () => {
    return await Client.get(MASTER_WORKING_DAY);
  }
);

export const fetchRelationships: any = createAsyncThunk(
  `${config.name}/fetchRelationships`,
  async () => {
    return await Client.get(MASTER_RELIONSHIPS);
  }
);

export const fetchMasterProcedures: any = createAsyncThunk(
  `${config.name}/fetchMasterProcedures`,
  async () => {
    return await Client.get(MASTER_PROCEDURE);
  }
);

export const fetchMasterProperty: any = createAsyncThunk(
  `${config.name}/fetchMasterProperty`,
  async () => {
    return await Client.get(MASTER_PROPERTY);
  }
);

export const fetchMasterAllTaskDropdown: any = createAsyncThunk(
  `${config.name}/fetchMasterAllTaskDropdown`,
  async (params: any) => {
    return await Client.get(METAALLTASKDROPDOWN, { params });
  }
);

export const fetchCustomRule: any = createAsyncThunk(
  `${config.name}/fetchCustomRule`,
  async (params: any) => {
    return await Client.get(CUSTOM_SLA_RULE, { params });
  }
);
export const fetchCustomRuleById: any = createAsyncThunk(
  `${config.name}/fetchCustomRuleById`,
  async (id: any) => {
    return await Client.get(CUSTOM_SLA_RULE_BY_ID(id), {});
  }
);

export const addNewCustomRules: any = createAsyncThunk(
  `${config.name}/addNewCustomRules`,
  async (data: any) => {
    return await Client.post(CUSTOM_SLA_RULE_ADD, data);
  }
);

export const updateCustomRule: any = createAsyncThunk(
  `${config.name}/updateCustomRule`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.patch(CUSTOM_SLA_RULE_UPDATE(id), data);
  }
);
export const enableDisableSLA: any = createAsyncThunk(
  `${config.name}/enableDisableSLA`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.patch(ENABLE_DISABLE_SLA_RULE(id), data);
  }
);

export const fetchMasterTaskTypesLead: any = createAsyncThunk(
  `${config.name}/fetchMasterTaskTypesLead`,
  async (params: any) => {
    return await Client.get(TASK_TYPES_LEAD, { params });
  }
);

export const fetchMasterTaskTypesTicket: any = createAsyncThunk(
  `${config.name}/fetchMasterTaskTypesTicket`,
  async (params: any) => {
    return await Client.get(TASK_TYPES_TICKET, { params });
  }
);

export const fetchMasterTaskSubTypes: any = createAsyncThunk(
  `${config.name}/fetchMasterTaskSubTypes`,
  async (params: any) => {
    return await Client.get(TASK_SUB_TYPES, { params });
  }
);
export const fetchMasterCategories: any = createAsyncThunk(
  `${config.name}/fetchMasterCategories`,
  async (params: any) => {
    return await Client.get(TASK_CATEGORIES, { params });
  }
);

export const calculateDueDate: any = createAsyncThunk(
  `${config.name}/calculateDueDate`,
  async (params: any) => {
    return await Client.get(CALCULATE_DUE_DATE, { params });
  }
);

export const fetchMasterTaskDropDown: any = createAsyncThunk(
  `${config.name}/fetchMasterTaskDropDown`,
  async (params: any) => {
    return await Client.get(TASK_DROP_DOWN, { params });
  }
);

export const setSearchValue: any = createAsyncThunk(
  `${config.name}/setSearchValue`,
  async (data: any) => {
    return data;
  }
);
export const getDropdownMasterData: any = createAsyncThunk(
  `${config.name}/getDropdownMasterData`,
  async ({ id }: { id: string }) => {
    return await Client.get(DROPDOWN_MASTER_DATA_BY_ID(id));
  }
);

export const fetchMasterSpecialities: any = createAsyncThunk(
  `${config.name}/fetchMasterSpecialities`,
  async () => {
    return await Client.get(MASTER_SPECIALITIES);
  }
);

export const getCrmTypesData: any = createAsyncThunk(
  `${config.name}/getCrmTypesData`,
  async ({ id }: { id: string }) => {
    return await Client.get(GET_CRM_TYPES_DATA(id));
  }
);

export const masterSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    error: "" as string,
    designations: [],
    gender: [],
    nationality: [],
    workingDays: [],
    procedures: [],
    properties: [],
    searchValue: null as any,
    allTaskDropdown: [],
    taskTypesLead: [],
    taskTypesTicket: [],
    taskSubTypes: [],
    taskCategories: [],
    taskDropDown: [],
    cities: [],
    relationships: [],
    dropdownMasterData: [],
    specialities: [],
  },
  reducers: {
    designations: (state) => {
      state.loading = false;
      state.error = "";
      state.designations = [];
    },
    gender: (state) => {
      state.loading = false;
      state.error = "";
      state.gender = [];
    },
    nationality: (state) => {
      state.loading = false;
      state.error = "";
      state.nationality = [];
    },
    workingDays: (state) => {
      state.loading = false;
      state.error = "";
      state.workingDays = [];
    },
    procedure: (state) => {
      state.loading = false;
      state.error = "";
      state.procedures = [];
    },
    property: (state) => {
      state.loading = false;
      state.error = "";
      state.properties = [];
    },

    allTaskDropdownReset: (state) => {
      state.loading = false;
      state.error = "";
      state.allTaskDropdown = [];
    },
    taskSubTypes: (state) => {
      state.loading = false;
      state.error = "";
      state.taskSubTypes = [];
    },
    fetchMasterCategories: (state) => {
      state.loading = false;
      state.error = "";
      state.taskCategories = [];
    },
    taskDropDown: (state) => {
      state.loading = false;
      state.error = "";
      state.taskDropDown = [];
    },
    resetTaskTypesCascade: (state) => {
      state.taskTypesLead = [];
      state.taskTypesTicket = [];
      state.taskSubTypes = [];
      state.taskCategories = [];
    },
    specialities: (state) => {
      state.loading = false;
      state.error = "";
      state.specialities = [];
    },
  },
  extraReducers(builder) {
    builder
      // Designation  List
      .addCase(fetchMasterDesignations.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterDesignations.fulfilled, (state, action) => {
        state.loading = false;
        state.designations = action?.payload?.data?.data;
      })
      .addCase(fetchMasterDesignations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // dropdownmaster data  List
      .addCase(getDropdownMasterData.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getDropdownMasterData.fulfilled, (state, action) => {
        state.loading = false;
        state.dropdownMasterData = action?.payload?.data?.data;
      })
      .addCase(getDropdownMasterData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Gender list
      .addCase(fetchMasterGender.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterGender.fulfilled, (state, action) => {
        state.loading = true;
        state.gender = action?.payload?.data?.data;
      })
      .addCase(fetchMasterGender.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Nationality list
      .addCase(fetchMasterNationality.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterNationality.fulfilled, (state, action) => {
        state.loading = true;
        state.nationality = action?.payload?.data?.data;
      })
      .addCase(fetchMasterNationality.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Working Daya
      .addCase(fetchMasterDays.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterDays.fulfilled, (state, action) => {
        state.loading = true;
        state.workingDays = action?.payload?.data?.data;
      })
      .addCase(fetchMasterDays.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Master Procedures
      .addCase(fetchMasterProcedures.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterProcedures.fulfilled, (state, action) => {
        state.loading = true;
        state.procedures = action?.payload?.data?.data;
      })
      .addCase(fetchMasterProcedures.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Master Property
      .addCase(fetchMasterProperty.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterProperty.fulfilled, (state, action) => {
        state.loading = true;
        state.properties = action?.payload?.data?.data;
      })
      .addCase(fetchMasterProperty.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Search Value
      .addCase(setSearchValue.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(setSearchValue.fulfilled, (state, action) => {
        state.loading = false;
        state.searchValue = action?.payload;
      })
      .addCase(setSearchValue.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Task Types List Lead
      .addCase(fetchMasterTaskTypesLead.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterTaskTypesLead.fulfilled, (state, action) => {
        state.loading = false;
        state.taskTypesLead = action?.payload?.data?.data;
      })
      .addCase(fetchMasterTaskTypesLead.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Task Types List Ticket
      .addCase(fetchMasterTaskTypesTicket.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterTaskTypesTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.taskTypesTicket = action?.payload?.data?.data;
      })
      .addCase(fetchMasterTaskTypesTicket.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // fetchMasterAllTaskDropdown
      .addCase(fetchMasterAllTaskDropdown.pending, (state) => {
        // state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterAllTaskDropdown.fulfilled, (state, action) => {
        // state.loading = false;
        state.allTaskDropdown = action?.payload?.data?.data;
      })
      .addCase(fetchMasterAllTaskDropdown.rejected, (state, action) => {
        // state.loading = true;
        state.error = action.error.message;
      })

      // Task Sub Types List
      .addCase(fetchMasterTaskSubTypes.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterTaskSubTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.taskSubTypes = action?.payload?.data?.data;
      })
      .addCase(fetchMasterTaskSubTypes.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Task Category List
      .addCase(fetchMasterCategories.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.taskCategories = action?.payload?.data?.data;
      })
      .addCase(fetchMasterCategories.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Task Drop Downs
      .addCase(fetchMasterTaskDropDown.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterTaskDropDown.fulfilled, (state, action) => {
        state.loading = false;
        state.taskDropDown = action?.payload?.data?.data;
      })
      .addCase(fetchMasterTaskDropDown.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Cities
      .addCase(fetchMasterCities.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterCities.fulfilled, (state, action) => {
        state.loading = false;
        state.cities = action?.payload?.data?.data;
      })
      .addCase(fetchMasterCities.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // fetchRelationships
      .addCase(fetchRelationships.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchRelationships.fulfilled, (state, action) => {
        state.loading = false;
        state.relationships = action?.payload?.data?.data;
      })
      .addCase(fetchRelationships.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // speciality list
      .addCase(fetchMasterSpecialities.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterSpecialities.fulfilled, (state, action) => {
        state.loading = true;
        state.specialities = action?.payload?.data?.data;
      })
      .addCase(fetchMasterSpecialities.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      });
  },
});

export const { resetTaskTypesCascade } = masterSlice.actions;

export default masterSlice.reducer;
