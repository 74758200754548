import {
  CloseOutlined,
  LinkOutlined,
  SendOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Input,
  Row,
  Select,
  Skeleton,
  Tag,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ChatIcon } from "../../../assets/icons/chat-icon.svg";
import {
  askQuestionKnowledgeBank,
  fetchKnowledgeBankDocumentURL,
  fetchKnowledgeBankList,
  getAnswerByQuestionIdKnowledgeBank,
  getRecentQuestionsKnowledgeBank,
  getSimilarQuestionsKnowledgeBank,
} from "../../../redux/reducers/knowledge-bank.slice";
import { formatEscapedText } from "../../Utils/utilities";
import { RAG_MODULE_PERMISSIONS } from "../../components/permission.constants";
import { BOT_DATA, RootState } from "../../constants";
import { ROUTE_CRM_BOT_CHAT } from "../../routes/crm.routes.constants";
import TabsLayout from "../tabs.layout";
import TextCopier from "../text-copier.layout";
// import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";
const { Paragraph, Text } = Typography;
const { Panel } = Collapse;

function CrmChatWidget() {
  const [open, setOpen] = useState(false as any);
  const [wideView, setWideView] = useState(false as any);
  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;
  return (
    <>
      {open && (
        <Conversation
          open={open}
          setOpen={setOpen}
          wideView={wideView}
          setWideView={setWideView}
        />
      )}
      {(myPermissions?.indexOf(RAG_MODULE_PERMISSIONS.ask_question) === -1 &&
        myPermissions?.indexOf(
          RAG_MODULE_PERMISSIONS.ask_question?.split(".")[0]
        ) === -1) ||
      window?.location?.href?.indexOf(ROUTE_CRM_BOT_CHAT) !== -1 ? (
        <></>
      ) : (
        <Launcher open={open} setOpen={setOpen} />
      )}
    </>
  );
}
export default CrmChatWidget;

const defaultMessage = (
  userName: string,
  botName: string,
  bankName: string
) => {
  return {
    type: "system", // user
    text: `Hi ${userName},\nWelcome to ${botName}.\nPlease type your queries regarding ${bankName}.`,
    template: "basic", // answer
  };
};

export function Conversation({
  open,
  setOpen,
  wideView,
  setWideView,
  pageView = false,
}: any) {
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  const dispatch = useDispatch();
  const { profile } = useSelector((state: RootState) => state.profile);
  const [referenceMode, setReferenceMode] = useState(false);
  const [extendedMode, setExtendedMode] = useState(
    pageView && screenType?.desktop
  );
  const [kbListData, setKbListData] = useState([] as any);
  const [inputText, setInputText] = useState("" as any);
  const [selectedBankId, setSelectedBankId] = useState(null as any);
  const [similarQuestions, setSimilarQuestions] = useState([] as any);
  const [recentQuestions, setRecentQuestions] = useState([] as any);
  const [loadinAns, setLoadingAns] = useState(false as any);
  const [debugMode, setDebugMode] = useState(false as any);
  const divRef = useRef(null as any);

  const [messages, setMessages] = useState([defaultMessage("", "", "")] as any);

  function getDefaultMessage() {
    if (kbListData?.length > 0 && selectedBankId) {
      const selectedBankName = kbListData?.filter(
        (val: any) => val?.bank_id == selectedBankId
      )?.[0]?.name;
      return defaultMessage(
        `${profile?.first_name ? profile?.first_name : ""}  ${
          profile?.last_name ? profile?.last_name : ""
        }`,
        BOT_DATA?.chat_title,
        selectedBankName
      );
    } else {
      let userName = `${profile?.first_name ? profile?.first_name : ""}  ${
        profile?.last_name ? profile?.last_name : ""
      }`;
      return {
        type: "system", // user
        text: `Hi ${userName},\nWe are <strong style="font-size:18px">Coming Soon!</strong>\n<span class='capitalize'>${BOT_DATA?.chat_title}</span> is currently not available for you.\nThank you for interacting with us.`,
        template: "basic", // answer
      };
    }
  }
  useEffect(() => {
    if (selectedBankId && profile) {
      setMessages([getDefaultMessage()]);
      localStorage?.setItem("selected_bank_id", selectedBankId);
      init();
    }
  }, [selectedBankId, profile]);
  async function init() {
    let bankId = await localStorage?.getItem("selected_bank_id");
    const chatBotMessages = await localStorage?.getItem("chat_bot_messages");
    if (chatBotMessages && bankId) {
      try {
        let allData = JSON.parse(chatBotMessages);
        setMessages(allData?.[bankId] || [getDefaultMessage()]);
        if (selectedBankId != bankId) {
          setSelectedBankId(parseInt(bankId));
        }
      } catch (e) {}
    } else {
      if (kbListData?.length > 0 && !selectedBankId) {
        setSelectedBankId(kbListData?.[0]?.bank_id);
      } else {
        setMessages([getDefaultMessage()]);
      }
    }
  }
  async function cacheMessages(m: any) {
    let bankId = selectedBankId;
    const chatBotMessages = await localStorage?.getItem("chat_bot_messages");
    // if (chatBotMessages) {
    try {
      let allData = JSON.parse(chatBotMessages || "{}");
      allData[bankId] = m;
      localStorage.setItem("chat_bot_messages", JSON.stringify(allData));
    } catch (e) {}
    // }
  }
  useEffect(() => {
    kbListData?.length > 0 && init();
  }, [kbListData]);
  // const resetMessages = () => {
  //   setMessages([defaultMessage]);
  //   setSimilarQuestions([]);
  // };
  useEffect(() => {
    selectedBankId &&
      dispatch(
        getRecentQuestionsKnowledgeBank({ knowledge_bank_id: selectedBankId })
      ).then((val: any) => {
        if (val?.payload?.data) {
          let ans = val?.payload?.data?.questions;
          setRecentQuestions(
            ans?.map((val: any) => {
              let ans = val;
              return {
                ...ans,
                answer: {
                  ...ans,
                  type: "system",
                  template: "answer",
                  text: ans?.text || ans?.gpt_answer || ans?.chunk_text || "",
                  snippet: ans?.chunk_text,
                  enable_snippet:
                    ans?.gpt_answer && ans?.gpt_answer != "" ? true : false,
                },
              };
            })
          );
          setSimilarQuestions([]);
        }
      });
  }, [selectedBankId]);
  useEffect(() => {
    if (open) {
      getKBList();
    }
  }, [open]);
  const getKBList = () => {
    dispatch(fetchKnowledgeBankList()).then((val: any) => {
      if (val?.payload?.data?.data) {
        let allList = val?.payload?.data?.data?.filter(
          (val: any) => !val?.disabled
        );
        setKbListData(allList);
        if (allList?.length == 0) {
          setMessages([getDefaultMessage()]);
        }
        // setSelectedBankId(allList?.[0]?.bank_id);
      }
    });
  };
  const onAskQuestion = () => {
    if (!screenType?.desktop) {
      setExtendedMode(false);
    }
    let m = JSON.parse(JSON.stringify(messages));
    m?.push({
      type: "user", // user
      text: inputText,
      template: "basic", // answer
    });
    setMessages(m);
    // localStorage.setItem("chat_bot_messages", JSON.stringify(m));
    cacheMessages(m);
    setInputText("");
    setTimeout(() => {
      scrollToBottom();
    }, 200);
    setLoadingAns(true);
    dispatch(
      askQuestionKnowledgeBank({
        body: { question: inputText },
        params: { knowledge_bank_id: selectedBankId },
      })
    ).then((val: any) => {
      if (val?.payload?.data?.data?.length > 0) {
        let ans = val?.payload?.data?.data?.[0];
        let obj = {
          ...ans,
          type: "system",
          template: "answer",
          text: ans?.gpt_answer || ans?.chunk_text,
          snippet: ans?.chunk_text,
          enable_snippet:
            ans?.gpt_answer && ans?.gpt_answer != "" ? true : false,
        };
        m.push(obj);
        cacheMessages(m);
        setTimeout(() => {
          setMessages(m);
          setLoadingAns(false);
          setTimeout(() => {
            scrollToBottom();
          }, 200);
        }, 200);
      } else {
        let userName = `${profile?.first_name ? profile?.first_name : ""}  ${
          profile?.last_name ? profile?.last_name : ""
        }`;
        let obj = {
          type: "system", // user
          text: `Hi ${userName},\nWe couldn't understand your query. Please frame your query in more detail.`,
          template: "basic", // answer
        };
        m.push(obj);
        cacheMessages(m);
        setTimeout(() => {
          setMessages(m);
          setLoadingAns(false);
          setTimeout(() => {
            scrollToBottom();
          }, 200);
        }, 200);
      }
    });

    dispatch(
      getSimilarQuestionsKnowledgeBank({
        question: inputText,
        knowledge_bank_id: selectedBankId,
      })
    ).then((val: any) => {
      if (val?.payload?.data) {
        let ans = val?.payload?.data;
        setSimilarQuestions(
          ans?.map((val: any) => {
            let ans = val;
            return {
              ...ans,
              answer: {
                ...ans,
                type: "system",
                template: "answer",
                text: ans?.text || ans?.gpt_answer || ans?.chunk_text || "",
                snippet: ans?.chunk_text,
                enable_snippet:
                  ans?.gpt_answer && ans?.gpt_answer != "" ? true : false,
              },
            };
          })
        );
      }
    });
  };
  const scrollToBottom = () => {
    setTimeout(() => {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };
  const getAnswerbyQuestionId = (question: any, type = "similar") => {
    dispatch(getAnswerByQuestionIdKnowledgeBank(question?.question_id))?.then(
      (val: any) => {
        if (val?.payload?.data) {
          if (type == "similar") {
            let allQs = JSON.parse(JSON.stringify(similarQuestions));
            setSimilarQuestions(
              allQs.map((qu: any) => {
                if (qu?.question_id == question?.question_id) {
                  let ans = val?.payload?.data?.[0] || {};
                  return {
                    ...qu,
                    answer: {
                      ...ans,
                      type: "system",
                      template: "answer",
                      text:
                        ans?.text || ans?.gpt_answer || ans?.chunk_text || "",
                      snippet: ans?.chunk_text,
                      enable_snippet:
                        ans?.gpt_answer && ans?.gpt_answer != "" ? true : false,
                    },
                  };
                }
                return { ...qu };
              })
            );
          }
          if (type == "recent") {
            let allQs = JSON.parse(JSON.stringify(recentQuestions));
            setRecentQuestions(
              allQs.map((qu: any) => {
                if (qu?.question_id == question?.question_id) {
                  let ans = val?.payload?.data?.[0] || {};
                  return {
                    ...qu,
                    answer: {
                      ...ans,
                      type: "system",
                      template: "answer",
                      text:
                        ans?.text || ans?.gpt_answer || ans?.chunk_text || "",
                      snippet: ans?.chunk_text,
                      enable_snippet:
                        ans?.gpt_answer && ans?.gpt_answer != "" ? true : false,
                    },
                  };
                }
                return { ...qu };
              })
            );
          }
        }
      }
    );
  };
  console.log("similarQuestions", similarQuestions);
  const allTabs = [
    {
      title:
        similarQuestions?.length > 0 ? "Similar Questions" : "Recent Questions",
      component: (
        <>
          <Row className="tab-row">
            <Col span={23}>
              {similarQuestions?.length > 0 &&
                similarQuestions?.map((val: any, index: number) => (
                  <Collapse
                    expandIconPosition="end"
                    className="mb-20"
                    onChange={(e) => {
                      // console.log("Opened ", val);
                      // if (val?.answer) {
                      //   return;
                      // }
                      // getAnswerbyQuestionId(val);
                    }}
                  >
                    <Panel header={val?.question_text} key={index + 1 + ""}>
                      {!val?.answer && <Skeleton />}
                      {val?.answer && (
                        // <span
                        //   dangerouslySetInnerHTML={{
                        //     __html: formatEscapedText(val?.text),
                        //   }}
                        // />
                        <Row className="chat-area">
                          <Col span={24}>
                            <AnswerTemplate
                              message={val?.answer}
                              referenceMode={false}
                              scrollToBottom={() => {}}
                            />
                          </Col>
                        </Row>
                      )}
                    </Panel>
                  </Collapse>
                ))}
              {similarQuestions?.length == 0 &&
                recentQuestions?.length > 0 &&
                recentQuestions?.map((val: any, index: number) => (
                  <Collapse
                    className="mb-20"
                    expandIconPosition="end"
                    onChange={(e) => {
                      console.log("Opened ", val);
                      if (val?.answer) {
                        return;
                      }
                      getAnswerbyQuestionId(val, "recent");
                    }}
                  >
                    <Panel header={val?.question_text} key={index + 1 + ""}>
                      {!val?.answer && <Skeleton />}
                      {val?.answer && (
                        // <span
                        //   dangerouslySetInnerHTML={{
                        //     __html: formatEscapedText(val?.text),
                        //   }}
                        // />
                        <Row className="chat-area">
                          <Col span={24}>
                            <AnswerTemplate
                              message={val?.answer}
                              referenceMode={false}
                              scrollToBottom={() => {}}
                            />
                          </Col>
                        </Row>
                      )}
                    </Panel>
                  </Collapse>
                ))}
              {/* <Collapse
                // onChange={onChange}
                expandIconPosition={"end"}
              >
                {qna?.map((row, index) => {
                  return (
                    <Panel header={row?.question} key={index + 1 + ""}>
                      <div className="answer-container">
                        {row?.answer}
                        <Button
                          //   size="small"
                          type="link"
                          className="copy-answer"
                          icon={<CopyOutlined />}
                        ></Button>
                      </div>
                      <Row>
                        <Col span={24}>
                          {referenceMode && (
                            <>
                              <Divider
                                type="horizontal"
                                style={{ margin: "5px 0px" }}
                              />
                              <small>1 Reference</small>
                              <br />
                              <Button type="link" icon={<LinkOutlined />}>
                                File Name.pdf{" "}
                              </Button>
                            </>
                          )}
                        </Col>
                        <Col span={24}>
                          <Divider
                            type="horizontal"
                            style={{ margin: "5px 0px" }}
                          />
                          <Button
                            size="small"
                            type="link"
                            icon={<LikeOutlined />}
                          ></Button>
                          <Button
                            size="small"
                            type="link"
                            icon={<DislikeOutlined />}
                          ></Button>
                          <Divider type="vertical"></Divider>
                          <Button
                            size="small"
                            type="link"
                            icon={<SnippetsOutlined />}
                          >
                            <small>&nbsp; Doc Snippet</small>
                          </Button>
                        </Col>
                        <Col span={20}>
                          <div className="snippet-container">
                            Snippet text here. Snippet text here. Snippet text
                            here. Snippet text here. Snippet text here.
                          </div>
                        </Col>
                      </Row>
                    </Panel>
                  );
                })}
              </Collapse> */}
            </Col>
            {/* <Col span={1}></Col> */}
            {/* <Col span={11}>
              <Collapse
                // onChange={onChange}
                expandIconPosition={"end"}
              >
                {qna?.map((row, index) => {
                  return (
                    <Panel header={row?.question} key={index + 1 + ""}>
                      <div>{row?.answer}</div>
                    </Panel>
                  );
                })}
              </Collapse>
            </Col> */}
          </Row>
        </>
      ),
    },
  ];
  console.log(messages);
  const model =
    kbListData?.length > 0 && selectedBankId
      ? kbListData?.filter((val: any) => val?.bank_id == selectedBankId)?.[0]
          ?.model
      : "";
  return (
    <>
      {screenType?.desktop ? (
        <div className="conversation">
          {(extendedMode || pageView) && <RelatedView allTabs={allTabs} />}
          <div className="card">
            <ChatHeaderArea
              {...{
                debugMode,
                setDebugMode,
                referenceMode,
                setReferenceMode,
                extendedMode,
                setExtendedMode,
                BOT_DATA,
                allTabs,
                setOpen,
                pageView,
                kbListData,
                selectedBankId,
                setSelectedBankId,
                screenType,
              }}
            />
            <Row className="chat-area">
              <Col span={24}>
                {messages?.length > 0 &&
                  messages?.map((message: any) => {
                    return (
                      <>
                        {message?.template == "basic" ? (
                          <Row
                            className="message-container"
                            justify={
                              message?.type == "system" ? "start" : "end"
                            }
                          >
                            <Col
                              className={
                                message?.type == "system" ? "left" : "right"
                              }
                              dangerouslySetInnerHTML={{
                                __html: formatEscapedText(message.text),
                              }}
                            ></Col>
                          </Row>
                        ) : (
                          <>
                            <AnswerTemplate
                              message={message}
                              referenceMode={referenceMode}
                              scrollToBottom={scrollToBottom}
                              model={model}
                              debugMode={debugMode}
                            />
                          </>
                        )}
                      </>
                    );
                  })}
                {loadinAns && (
                  <>
                    <div className="chat-loading">
                      <div className="dot-flashing"></div>
                      <div className="dot-flashing"></div>
                      <div className="dot-flashing"></div>
                    </div>
                  </>
                )}
                <div ref={divRef} />
              </Col>
            </Row>
            <Row className="question-area" gutter={5}>
              <Col span={22}>
                <Input.TextArea
                  onChange={(e) => setInputText(e?.target?.value)}
                  disabled={loadinAns || !selectedBankId}
                  value={inputText}
                  showCount={true}
                  maxLength={255}
                  onPressEnter={(evt) => {
                    if (evt.keyCode == 13 && evt.shiftKey) {
                    } else {
                      onAskQuestion();
                      evt.preventDefault();
                    }
                  }}
                />
              </Col>
              <Col span={2}>
                <Button
                  type="text"
                  size="large"
                  style={{ marginTop: "10px" }}
                  disabled={
                    !inputText || (inputText && inputText?.trim()?.length < 5)
                  }
                  onClick={onAskQuestion}
                  icon={<SendOutlined />}
                ></Button>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="conversation tab-view">
          {/* {extendedMode && <RelatedView allTabs={allTabs} />} */}
          <div className="card">
            {/* <Row className="header-area">
              <Col flex="auto">
                <p className="title">
                  {BOT_DATA?.icon ? (
                    <img
                      src={BOT_DATA?.icon}
                      style={{ width: 40, height: 40 }}
                    />
                  ) : (
                    <ChatIcon />
                  )}
                  &nbsp;&nbsp;&nbsp;
                  {BOT_DATA?.chat_title || "How can I help?"}
                  <Select
                    onChange={(bank_id: any) => setSelectedBankId(bank_id)}
                    value={selectedBankId}
                    className="module-selector"
                  >
                    {kbListData?.map((val: any) => (
                      <Select.Option value={val?.bank_id}>
                        {val?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </p>
                {!referenceMode && (
                  <Tag
                    className="btn"
                    onClick={() => {
                      setReferenceMode(true);
                    }}
                  >
                    References: Off
                  </Tag>
                )}
                {referenceMode && (
                  <Tag
                    className="btn"
                    color="blue"
                    onClick={() => {
                      setReferenceMode(false);
                    }}
                  >
                    References: On
                  </Tag>
                )}
                {(!pageView || !screenType?.desktop) && !extendedMode && (
                  <Tag
                    className="btn"
                    onClick={() => {
                      setExtendedMode(true);
                    }}
                  >
                    Extended Mode: Off
                  </Tag>
                )}
                {(!pageView || !screenType?.desktop) && extendedMode && (
                  <Tag
                    className="btn"
                    color="green"
                    onClick={() => {
                      setExtendedMode(false);
                    }}
                  >
                    Extended Mode: On
                  </Tag>
                )}
                {!debugMode && (
                  <Tag
                    className="btn"
                    onClick={() => {
                      setDebugMode(true);
                    }}
                  >
                    Debug Mode: Off
                  </Tag>
                )}
                {debugMode && (
                  <Tag
                    className="btn"
                    color="purple"
                    onClick={() => {
                      setDebugMode(false);
                    }}
                  >
                    Debug Mode: On
                  </Tag>
                )}
              </Col>
              {!pageView && (
                <Col flex="none">
                  <Button
                    onClick={() => setOpen(false)}
                    type="text"
                    icon={<CloseOutlined />}
                  ></Button>
                </Col>
              )}
            </Row> */}
            <ChatHeaderArea
              {...{
                debugMode,
                setDebugMode,
                referenceMode,
                setReferenceMode,
                extendedMode,
                setExtendedMode,
                BOT_DATA,
                allTabs,
                setOpen,
                pageView,
                kbListData,
                selectedBankId,
                setSelectedBankId,
                screenType,
              }}
            />
            {!extendedMode && (
              <Row className="chat-area">
                <Col span={24}>
                  {messages?.length > 0 &&
                    messages?.map((message: any) => {
                      return (
                        <>
                          {message?.template == "basic" ? (
                            <Row
                              className="message-container"
                              justify={
                                message?.type == "system" ? "start" : "end"
                              }
                            >
                              <Col
                                className={
                                  message?.type == "system" ? "left" : "right"
                                }
                                dangerouslySetInnerHTML={{
                                  __html: formatEscapedText(message.text),
                                }}
                              ></Col>
                            </Row>
                          ) : (
                            <>
                              <AnswerTemplate
                                message={message}
                                referenceMode={referenceMode}
                                scrollToBottom={scrollToBottom}
                                model={model}
                                debugMode={debugMode}
                              />
                            </>
                          )}
                        </>
                      );
                    })}
                  {loadinAns && (
                    <>
                      <div className="chat-loading">
                        <div className="dot-flashing"></div>
                        <div className="dot-flashing"></div>
                        <div className="dot-flashing"></div>
                      </div>
                    </>
                  )}
                  <div ref={divRef} />
                </Col>
              </Row>
            )}
            {extendedMode && <RelatedView allTabs={allTabs} />}
            <Row className="question-area" gutter={5}>
              <Col span={22}>
                <Input.TextArea
                  onChange={(e) => setInputText(e?.target?.value)}
                  disabled={loadinAns || !selectedBankId}
                  value={inputText}
                  showCount={true}
                  maxLength={255}
                  onPressEnter={(evt) => {
                    if (evt.keyCode == 13 && evt.shiftKey) {
                    } else {
                      onAskQuestion();
                      evt.preventDefault();
                    }
                  }}
                />
              </Col>
              <Col span={2}>
                <Button
                  type="text"
                  size="large"
                  style={{ marginTop: "10px" }}
                  disabled={
                    !inputText || (inputText && inputText?.trim()?.length < 5)
                  }
                  onClick={onAskQuestion}
                  icon={<SendOutlined />}
                ></Button>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}

export function RelatedView({ allTabs }: any) {
  return (
    <div className="related-view crm-tabs">
      <TabsLayout AllTabs={allTabs}></TabsLayout>
    </div>
  );
}
function Launcher({ open, setOpen }: any) {
  return (
    <>
      <div className="launcher">
        {!open && (
          <Button
            icon={<ChatIcon />}
            size="large"
            type="primary"
            shape="circle"
            onClick={() => setOpen(true)}
          ></Button>
        )}
        {/* {open && (
          <Button
            icon={
              <img
                src={require("../../../assets/icons/close white.png").default}
              />
            }
            size="large"
            type="primary"
            shape="circle"
            onClick={() => setOpen(false)}
          ></Button>
        )} */}
      </div>
    </>
  );
}

function AnswerTemplate({
  message,
  referenceMode,
  scrollToBottom,
  model = "",
  debugMode = false,
}: any) {
  const dispatch = useDispatch();
  const [showSnippet, setShowSnippet] = useState(false as any);
  return (
    <>
      {message && (
        <Row className="message-container" justify="start">
          <Col className="left">
            <span
              dangerouslySetInnerHTML={{
                __html: formatEscapedText(message?.text),
              }}
            />
            {/* <Button
      // size="small"
      type="link"
      className="copy-answer"
      onClick={() =>
        navigator.clipboard.writeText(message.text)
      }
      icon={<CopyOutlined />}
    ></Button> */}
            <TextCopier text={message?.text} />
            {referenceMode && (
              <>
                <Divider type="horizontal" style={{ margin: "5px 0px" }} />
                <small>1 Reference</small>
                <br />
                <Button
                  type="link"
                  icon={<LinkOutlined />}
                  onClick={() => {
                    dispatch(
                      fetchKnowledgeBankDocumentURL(message?.file_id)
                    ).then((val: any) => {
                      if (val?.payload?.data?.url) {
                        window.open(val?.payload?.data?.url);
                      }
                    });
                  }}
                >
                  {message?.file_name}
                </Button>
              </>
            )}
          </Col>
          <Col span={24}>
            {/* <small>10:00 AM</small>
        <Divider type="vertical"></Divider> */}
            {/* <Button size="small" type="link" icon={<LikeOutlined />}></Button>
            <Button
              size="small"
              type="link"
              icon={<DislikeOutlined />}
            ></Button> */}
            {message?.enable_snippet && (
              <>
                {/* <Divider type="vertical"></Divider> */}
                <Button
                  onClick={() => {
                    setShowSnippet(!showSnippet);
                    // scrollToBottom();
                  }}
                  size="small"
                  type="link"
                  icon={<SnippetsOutlined />}
                >
                  <small>&nbsp; Doc Snippet</small>
                </Button>
              </>
            )}
            {debugMode && model != "" && (
              <>
                <Divider type="vertical"></Divider>
                <Tag>{model}</Tag>
              </>
            )}
          </Col>
          {showSnippet && message?.enable_snippet && (
            <Col span={20}>
              <div className="snippet-container">{message.snippet}</div>
            </Col>
          )}
        </Row>
      )}
    </>
  );
}

export function ChatHeaderArea({
  debugMode,
  setDebugMode,
  referenceMode,
  setReferenceMode,
  extendedMode,
  setExtendedMode,
  BOT_DATA,
  allTabs,
  setOpen,
  pageView,
  kbListData,
  selectedBankId,
  setSelectedBankId,
  screenType,
}: any) {
  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;
  const debugModeAllowed = !(
    myPermissions?.indexOf(RAG_MODULE_PERMISSIONS.allow_debug_mode) === -1 &&
    myPermissions?.indexOf(
      RAG_MODULE_PERMISSIONS.allow_debug_mode?.split(".")[0]
    ) === -1
  );
  return (
    <>
      <Row className="header-area">
        <Col flex="auto">
          <Row align="middle" gutter={16}>
            {/* <Col>
              {pageView && (
                <Tooltip title="Go Back">
                  <a href="ZydusPaathsala://">
                    <Button
                      style={{ marginBottom: "8px" }}
                      type="text"
                      icon={<ArrowLeftOutlined />}
                    ></Button>
                  </a>
                </Tooltip>
              )}
            </Col> */}
            <Col>
              <p className="title">
                {BOT_DATA?.icon ? (
                  <img src={BOT_DATA?.icon} style={{ width: 40, height: 40 }} />
                ) : (
                  <ChatIcon />
                )}
                &nbsp;&nbsp;&nbsp;
                {BOT_DATA?.chat_title || "How can I help?"}
              </p>
            </Col>
          </Row>
          <Row align="bottom">
            <Col flex="auto">
              {!referenceMode && (
                <Tag
                  className="btn"
                  onClick={() => {
                    setReferenceMode(true);
                  }}
                >
                  References: Off
                </Tag>
              )}
              {referenceMode && (
                <Tag
                  className="btn"
                  color="blue"
                  onClick={() => {
                    setReferenceMode(false);
                  }}
                >
                  References: On
                </Tag>
              )}
              {(!pageView || !screenType?.desktop) && !extendedMode && (
                <Tag
                  className="btn"
                  onClick={() => {
                    setExtendedMode(true);
                  }}
                >
                  {allTabs?.[0]?.title || "Extended Mode"}: Off
                </Tag>
              )}
              {(!pageView || !screenType?.desktop) && extendedMode && (
                <Tag
                  className="btn"
                  color="green"
                  onClick={() => {
                    setExtendedMode(false);
                  }}
                >
                  {allTabs?.[0]?.title || "Extended Mode"}: On
                </Tag>
              )}
              {debugModeAllowed && !debugMode && (
                <Tag
                  className="btn"
                  onClick={() => {
                    setDebugMode(true);
                  }}
                >
                  Debug Mode: Off
                </Tag>
              )}
              {debugModeAllowed && debugMode && (
                <Tag
                  className="btn"
                  color="purple"
                  onClick={() => {
                    setDebugMode(false);
                  }}
                >
                  Debug Mode: On
                </Tag>
              )}
            </Col>
            <Col>
              {selectedBankId && (
                <Col>
                  <Row>
                    <Col span={24}>
                      <p className="module-selector-label">
                        {BOT_DATA?.module_select_label || "Please Select"}
                      </p>
                    </Col>
                    <Col span={24}>
                      <Select
                        onChange={(bank_id: any) => setSelectedBankId(bank_id)}
                        value={selectedBankId}
                        className="module-selector"
                        size="small"
                      >
                        {kbListData?.map((val: any) => (
                          <Select.Option value={val?.bank_id}>
                            {val?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Col>
              )}
            </Col>
          </Row>
        </Col>
        {!pageView && (
          <Col
            flex="none"
            style={{ position: "absolute", top: "7px", right: "10px" }}
          >
            <Button
              onClick={() => setOpen(false)}
              type="text"
              icon={<CloseOutlined />}
            ></Button>
          </Col>
        )}
      </Row>
    </>
  );
}
{
  /* <Row className="message-container" justify="start">
                <Col className="left">
                  Hi,
                  <br />
                  Please enter your query.
                </Col>
              </Row>
              <Row className="message-container" justify="end">
                <Col className="right">What is CRM?</Col>
              </Row> */
}
{
  /* <Row className="message-container" justify="start">
                <Col className="left">
                  <Paragraph
                    ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
                  >
                    CRM stands for Customer Relationship Management. It refers
                    to a system or strategy used by businesses to manage
                    interactions with current and potential customers. CRM
                    software helps companies streamline processes, improve
                    customer service, and increase sales by organizing and
                    automating customer-related activities.
                  </Paragraph>
                  <Button
                    // size="small"
                    type="link"
                    className="copy-answer"
                    icon={<CopyOutlined />}
                  ></Button>
                  {referenceMode && (
                    <>
                      <Divider
                        type="horizontal"
                        style={{ margin: "5px 0px" }}
                      />
                      <small>1 Reference</small>
                      <br />
                      <Button type="link" icon={<LinkOutlined />}>
                        File Name.pdf{" "}
                      </Button>
                    </>
                  )}
                </Col>
                <Col span={24}>
                  <small>10:00 AM</small>
                  <Divider type="vertical"></Divider>
                  <Button
                    size="small"
                    type="link"
                    icon={<LikeOutlined />}
                  ></Button>
                  <Button
                    size="small"
                    type="link"
                    icon={<DislikeOutlined />}
                  ></Button>
                  <Divider type="vertical"></Divider>
                  <Button size="small" type="link" icon={<SnippetsOutlined />}>
                    <small>&nbsp; Doc Snippet</small>
                  </Button>
                </Col>
                <Col span={20}>
                  <div className="snippet-container">
                    Snippet text here. Snippet text here. Snippet text here.
                    Snippet text here. Snippet text here.
                  </div>
                </Col>
              </Row> */
}
