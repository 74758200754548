import { message } from "antd";
import { Client } from "../../shared/Utils/api-client";
import { ACCOUNT_PREFIX } from "../constants";
import { CALL_INITIATE } from "../routes/crm.routes.constants";


interface CallData {
  [key: string]: any;
}

const initiateCallFn = async (phone: string, callData: CallData = {}): Promise<void> => {
  try {
    if (ACCOUNT_PREFIX) {
      await Client.post(CALL_INITIATE, { cli: phone, ...callData });
      message.success("Call initiated.");
    }
  } catch (error) {
    message.error("Failed to initiate call.");
    console.error("Call initiation error:", error);
  }
};

export default initiateCallFn;
