import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchActionMap } from "../../../../redux/reducers/actionmap.slice";
import { fetchAllAgents } from "../../../../redux/reducers/agents.slice";
import { fetchBranches } from "../../../../redux/reducers/branch.slice";
import {
  addNewContact,
  createContactFromPatient,
  fetchContactAndPatientByMobile,
} from "../../../../redux/reducers/contacts.slice";
import { createLead } from "../../../../redux/reducers/leads.slice";
import {
  fetchMasterGender,
  fetchMasterNationality,
  fetchMasterTaskDropDown,
  fetchMasterTaskSubTypes,
  fetchMasterTaskTypesLead,
  resetTaskTypesCascade
} from "../../../../redux/reducers/master.slice";
import { fetchTeams } from "../../../../redux/reducers/team.slice";
import {
  TASK_MODULE_ACTIONS_DISABLE_ASSIGN_TO_IN_CREATE_LEAD,
  TASK_MODULE_ACTIONS_DISABLE_ASSIGNEE_IN_CREATE_LEAD,
  TASK_MODULE_ACTIONS_DISABLE_ATTACHMENT,
  TASK_MODULE_ACTIONS_DISABLE_SOURCE_IN_CREATE_LEAD,
  TASK_MODULE_ACTIONS_DISABLE_STATUS_IN_CREATE_LEAD,
  TASK_MODULE_ACTIONS_HIDE_ATTACHMENT,
  TASK_MODULE_ACTIONS_HIDE_BRANCH,
} from "../../../../shared/components/permission.constants";
import { RootState } from "../../../../shared/constants";
import { GenericFormItems } from "../../../../shared/layouts/formbar.layout";
import ResultModalLayout from "../../../../shared/layouts/resultmodal.layout";
import UploadPrescription from "../../../../shared/layouts/uploadprescription";
import { ROUTE_CRM_LEADS_LIST } from "../../../../shared/routes/crm.routes.constants";
import { Contact } from "../../../../shared/types/contact.type";
import { countryCodes } from "../../../../shared/Utils/dial-codes";
import {
  checkPhoneNumber,
  dobToAge,
  formatDropDownValue,
} from "../../../../shared/Utils/utilities";
import { processCustomFieldsValues } from "../../Tickets/components/ticket-form";

const { Search } = Input;
const { Option } = Select;
const { TextArea } = Input;

type Props = {
  phone?: string;
  contacts?: Array<Contact>;
  defaultContactId?: string;
  leadFormVisible: boolean;
  setLeadFormVisible: (val: any) => void;
  saveLeadSuccess: () => void;
  callLogId?: any;
};

function CreateLeadForm({
  phone = "",
  leadFormVisible = false,
  setLeadFormVisible,
  defaultContactId = "",
  saveLeadSuccess,
  callLogId = null,
}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading_cp, cpCombined } = useSelector(
    (state: RootState) => state.contact
  );
  const { teams } = useSelector((state: RootState) => state.team);
  const { t } = useTranslation();
  const { agentsAll } = useSelector((state: RootState) => state.agents);
  const { profile } = useSelector((state: RootState) => state.profile);
  const { gender, nationality, taskTypesLead, taskSubTypes, taskDropDown } =
    useSelector((state: RootState) => state.master);
  const { branches } = useSelector((state: RootState) => state.branch);
  const [formLoading, setFormLoading] = useState(false);
  const [addNewContactVar, setaddNewContactVar] = useState(false);
  const [leadSource, setLeadSource] = useState(false as any);
  const [priorityList, setPriorityList] = useState(false as any);
  const [statusList, setStatusList] = useState(false as any);
  const [contactList, setContactList] = useState([] as any);
  const [opportunitiesList, setOpportunitiesList] = useState(false as any);
  const [phoneNumber, setPhoneNumber] = useState(phone as any);
  const [userList, setUserList] = useState(null as any);
  const [teamList, setTeamList] = useState(null as any);
  const [assigneeList, setAssigneeList] = useState([] as any);
  const [modalVisible, setModalVisible] = useState(false as any);
  const [leadData, setLeadData] = useState(false as any);
  const [form] = Form.useForm();
  const [countryCode, setCountryCode] = useState("+91");
  const [custom_fields, set_custom_fields] = useState([] as any);

  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setfiles] = useState(null as any);
  const [documentName, setdocumentName] = useState(null as any);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fileUploaded = async (response: any) => {
    handleCancel();
    setfiles(response);
  };
  const getDescription = (value: any) => {
    setdocumentName(value);
  };



  useEffect(() => {
    (async () => {
      phone !== "" && await dispatch(fetchContactAndPatientByMobile(phone));
      await dispatch(fetchMasterTaskTypesLead({ mode: "lead" }));
      await dispatch(fetchAllAgents({ limit: 500 }));
      await dispatch(fetchTeams({ limit: 500 }));
      taskDropDown.length == 0 && await dispatch(fetchMasterTaskDropDown());
      gender?.length == 0 && await dispatch(fetchMasterGender({}));
      nationality?.length == 0 && await dispatch(fetchMasterNationality({}));
      branches?.length == 0 && await dispatch(fetchBranches({}));

    })()
    return () => {
      dispatch(resetTaskTypesCascade());
    };
  }, [phone]);
  useEffect(() => {
    if (agentsAll.length > 0) {
      let vals = agentsAll.map((val: any) => {
        return {
          name: `${val?.user?.first_name && val?.user?.first_name !== "null"
            ? val?.user?.first_name
            : ""
            } ${val.user?.last_name && val.user?.last_name !== "null"
              ? val.user?.last_name
              : ""
            }`,
          value: val.user_id,
        };
      });
      setUserList(vals);
    }
  }, [agentsAll]);
  useEffect(() => {
    if (teams.length > 0) {
      let vals = teams.map((val: any) => {
        return { name: formatDropDownValue(val.name), value: val.id };
      });
      setTeamList(vals);
    }
  }, [teams]);

  useEffect(() => {
    if (phoneNumber !== "") {
      setContactList(
        cpCombined.map((val: any) => {
          return {
            ...val,
            id: val.id ? val.id : val.patient_id ? val.patient_id : val.uhid,
          };
        })
      );
      setaddNewContactVar(false);
    }
  }, [cpCombined]);

  const validatePhone = (e: any) => {
    if (checkPhoneNumber(e.key)) {
      e.preventDefault();
    }
  };

  const onPhoneSearch = (value: any) => {
    if (value.length < 6 || value.length > 13) {
      message.error("Please provide valid phone number!");
      return;
    }
    setPhoneNumber(value);
    value && dispatch(fetchContactAndPatientByMobile(value));
  };
  const onChangePhone = () => {
    setContactList([] as any);
    form.setFieldsValue({ contact_id: null });
  };

  const handleContactChange = (value: any) => {
    if (value == "new") {
      setaddNewContactVar(true);
    } else {
      setaddNewContactVar(false);
    }
  };
  const handleSVTypeChange = (value: any) => {
    value && dispatch(fetchMasterTaskSubTypes({ task_type_id: value }));
  };
  const handleSVSubTypeChange = async (value: any) => {
    getAgentList();
    console.log("form.getFieldValue", form.getFieldValue("svc_type_id"))
    if (form.getFieldValue("svc_type_id")) {
      await dispatch(
        fetchActionMap({
          mode: "lead",
          type_id: form.getFieldValue("svc_type_id"),
          sub_type_id: value,
        })
      ).then((val: any) => {
        if (val?.payload?.data?.data?.items?.length > 0) {
          let custom_fields = val?.payload?.data?.data?.items[0]?.custom_fields;
          custom_fields = custom_fields?.map((val: any) => {
            return {
              ...val,
              name: ["meta", val.name],
              colSpan:
                val?.type == "TextArea" || val?.type == "FormList" ? 24 : 8,
            };
          });
          set_custom_fields(custom_fields);
          setTimeout(() => {
            if (custom_fields?.length > 0) {
              custom_fields?.map((val: any) => {
                if (val?.initialValue) {
                  if (val?.type == "FormList")
                    form.setFieldValue([...val?.name, "data"], val?.initialValue);
                  else form.setFieldValue(val?.name, val?.initialValue);
                }
              });
            }
          }, 200);
        }
      });
    }
  };
  const onSaveContact = (values: any) => {
    values = { ...values, country_code: "91" }.then((val: any) => {
      if (val?.payload?.data?.statusCode == 200) {
        const contact_id = val?.payload?.data?.data?.id;
      }
    });
  };
  const onSaveLead = async (value: any) => {
    setFormLoading(true);
    value.country_code = countryCode.replace("+", "");
    let contact_id = value.contact_id;
    const contactData = contactList?.filter((val: any) => {
      return val.id === contact_id;
    })[0];
    if (contactData?.source === "patient") {
      const res = await dispatch(
        createContactFromPatient({
          patient_id: contact_id,
          uhid: contactData?.uhid,
        })
      );
      if (res?.payload?.data?.statusCode == 200) {
        contact_id = res?.payload?.data?.data?.id;
      } else {
        return;
      }
    }
    if (contact_id == "new" && addNewContactVar) {
      const values = {
        country_code: countryCode.replace("+", ""),
        first_name: value.first_name,
        last_name: value.last_name,
        phone: value.phone_number,
        gender_id: value.gender_id,
        dob: value.dob,
        email: value.email_id,
        nationality_id: value.nationality_id,
      };
      const res = await dispatch(addNewContact(values));
      if (res?.payload?.data?.statusCode == 200) {
        contact_id = res?.payload?.data?.data?.id;
      } else {
        return;
      }
    }

    let data = {
      contact_id: contact_id,
      svc_type_id: value.svc_type_id,
      svc_sub_type_id: value.svc_sub_type_id,
      branch_id: value.branch_id,
      status_id: value.status_id,
      source: callLogId !== null ? "call_logs" : null,
      source_id: value.source,
      priority_id: value.priority_id,
      assigned_user_id:
        value.assign_to == "agent"
          ? value.assignee
          : value.assign_to == "self"
            ? profile.id
            : null,
      assigned_team_id: value.assign_to == "team" ? value.assignee : null,
      due_date: null,
      lead_description: value.lead_description,
      opportunities: value?.opportunities?.map((val: string) => {
        return { opportunity_id: val };
      }),
    } as any;
    if (value?.meta) {
      data.meta = value?.meta;
    }
    if (callLogId !== null) {
      data = { ...data, call_log_id: callLogId };
    }

    if (files) {
      data.file_upload_ids = [files];
    }

    // console.log("post data", data)
    // console.log("post data 2", value?.assign_to, value?.assignee, profile?.id)
    dispatch(createLead(data)).then((val: any) => {
      setFormLoading(false);
      if (val?.payload?.data?.statusCode == 200) {
        setLeadData(val.payload.data.data);
        setModalVisible(true);
      }
    });
  };
  const onCancelModal = () => {
    saveLeadSuccess();
    setModalVisible(false);
    // history.push(ROUTE_CRM_LEAD_DETAILS(leadData?.id));
    history.push(ROUTE_CRM_LEADS_LIST);
  };
  const onSaveLeadFailed = (value: any) => {
    console.log(value);
  };

  let genderObj: any = {};
  gender?.forEach((val: any) => {
    genderObj[val?.id] = val?.name;
  });
  let nationalityObj: any = {};
  nationality?.forEach((val: any) => {
    nationalityObj[val?.id] = val?.name;
  });
  useEffect(() => {
    if (!loading_cp && phoneNumber !== "" && cpCombined.length > 0) {
      let setId = cpCombined[0].id;
      if (defaultContactId !== "") {
        let temp = cpCombined.filter((val: any) => {
          return defaultContactId == val.id;
        });
        if (temp.length > 0) {
          setId = temp[0].id;
        }
      }
      form.setFieldsValue({ contact_id: setId });
    } else {
      form.setFieldsValue({ contact_id: null });
    }
  }, [cpCombined]);

  useEffect(() => {
    if (!loading_cp && taskDropDown.length > 0) {
      let temp = taskDropDown?.filter((val: any) => {
        return val.name == "source" && val.mode == "lead";
      });
      setLeadSource(temp[0]?.values);
      temp = taskDropDown?.filter((val: any) => {
        return val.name == "priority" && val.mode == "lead";
      });
      setPriorityList(temp[0]?.values);
      temp = taskDropDown?.filter((val: any) => {
        return val.name == "status" && val.mode == "lead";
      });
      setStatusList(temp[0]?.values);
      temp = taskDropDown?.filter((val: any) => {
        return val.name == "opportunity" && val.mode == "lead";
      });
      setOpportunitiesList(temp[0]?.values);
    }
  }, [taskDropDown]);

  useEffect(() => {
    const leadCallLog =
      leadSource?.length > 0 &&
      leadSource?.filter((val: any) => {
        return val.name === "call_logs";
      });
    if (leadSource?.length > 0) {
      form.setFieldsValue({
        source: leadCallLog[0]?.id ? leadCallLog[0]?.id : leadSource[0]?.id,
      });
    }
  }, [leadSource]);

  useEffect(() => {
    if (priorityList.length > 0) {
      form.setFieldsValue({
        priority_id: priorityList[0].id,
      });
    }
  }, [priorityList]);
  useEffect(() => {
    if (statusList?.length > 0) {
      form.setFieldsValue({
        status_id: statusList[0]?.id,
      });
    }
  }, [statusList]);
  useEffect(() => {
    if (nationality.length > 0) {
      form.setFieldsValue({
        nationality_id: nationality
          .filter((val: any) => {
            return val.name === "indian";
          })
          .map((val: any) => {
            return val.id;
          })
          .toString(),
      });
    }
  }, [nationality]);
  useEffect(() => {
    if (!loading_cp && taskTypesLead?.length > 0) {
      form.setFieldsValue({
        svc_type_id: taskTypesLead[0].id,
      });
      handleSVTypeChange(taskTypesLead[0].id);
    }
  }, [taskTypesLead]);

  console.log("taskTypesLead", taskTypesLead)

  useEffect(() => {

    if (!loading_cp && taskSubTypes?.length > 0) {
      form.setFieldsValue({
        svc_sub_type_id: taskSubTypes[0].id,
      });
      taskSubTypes?.length && handleSVSubTypeChange(taskSubTypes[0].id);
    }
  }, [taskSubTypes]);

  // console.log("contactList", contactList)

  useEffect(() => {
    if (branches.length > 0) {
      form.setFieldsValue({
        branch_id: branches[0].id,
      });
    }
  }, [branches]);
  useEffect(() => {
    if (assigneeList?.length > 0) {
      form.setFieldsValue({
        assignee: assigneeList[0].value,
      });
    }
  });
  const prefixSelector = (
    // <Form.Item name="country_code" noStyle initialValue={countryCode}>
    <Select
      showSearch
      // disabled
      style={{ width: 100 }}
      value={countryCode}
      onChange={(evt) => setCountryCode(evt)}
      className={"noborder"}
      defaultValue={countryCode}
    >
      {countryCodes.map((item) => {
        return (
          item.show === true && (
            <Select.Option value={item.dial_code}>
              <Space>
                <span>{item.flag}</span>
                <span>{item.dial_code}</span>
              </Space>
            </Select.Option>
          )
        );
      })}
    </Select>
  );
  useEffect(() => {
    setCountryCode(
      cpCombined.length > 0 && cpCombined[0]?.country_code
        ? "+" + cpCombined[0]?.country_code
        : "+91"
    );
  }, [cpCombined]);
  function getAgentList() {
    // const {svc_type_id, svc_sub_type_id} = form.getFieldsValue();
    // if(svc_type_id && svc_sub_type_id){
    //
    //     // dispatch(fetchAllAgents({limit: 500, mode:'lead', type_id : svc_type_id, sub_type_id : svc_sub_type_id}))
    //     dispatch(fetchAllAgents({limit: 500}))
    //     // dispatch(fetchAgents({limit: 500}))
    // }
    //
    // // const svc_type_id = form.getFieldsValue('svc_type_id')
  }
  function changeSelectedAssignType(e: any) {
    if (e === "self") {
      form.setFieldsValue({
        assignee: "",
      });
      setAssigneeList([]);
    }
    if (e == "agent") {
      setAssigneeList(userList);
    }
    if (e == "team") {
      setAssigneeList(teamList);
    }
  }

  return (
    <>
      <Modal
        title="Create Lead"
        centered
        visible={leadFormVisible}
        onCancel={() => setLeadFormVisible(false)}
        width={1000}
        footer={false}
        className="modalFormButton form-modal"
        style={{ paddingBottom: "0px" }}
      >
        <Form
          form={form}
          name={"lead_form"}
          className="paddingx20 crm-form full-width cover create-lead-form"
          // onFinish={onSaveLead}
          onFinish={(values: any) => {
            try {
              processCustomFieldsValues(values, custom_fields);
            } catch (e) { }
            onSaveLead(values);
          }}
          onFinishFailed={onSaveLeadFailed}
          initialValues={{
            phone_number: phone,
            assign_to: "",
          }}
        >
          <Row gutter={32}>
            <Col>
              <h4 className="text-dark">Choose Contact</h4>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col {...{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}>
              <Form.Item
                name={"phone_number"}
                label="Phone Number"
                rules={[
                  { required: true, message: "Please enter phone number" },
                ]}
              >
                <Search
                  placeholder="input search text"
                  onChange={onChangePhone}
                  onSearch={onPhoneSearch}
                  maxLength={12}
                  addonBefore={prefixSelector}
                  onKeyPress={validatePhone}
                />
              </Form.Item>
            </Col>
            <Col {...{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}>
              <Form.Item
                name={"contact_id"}
                label="Contact Name"
                rules={[{ required: true, message: "Please select contact" }]}
              >
                <Select
                  onChange={handleContactChange}
                  placeholder={"Choose Contact"}
                  allowClear={true}
                  showSearch
                  optionFilterProp="children"
                >
                  {contactList &&
                    contactList.map((e: any) => {
                      return (
                        <Option key={e.id} value={e.id}>
                          {e.first_name} {e.last_name}{" "}
                          {e.dob || e.gender ? "(" : ""}
                          {e.dob && (
                            <small className="text-secondary">
                              {dobToAge(e.dob) + "yrs, "}
                            </small>
                          )}
                          {e.gender && (
                            <small className="text-secondary">
                              {e?.gender && e?.gender?.split("")[0]}
                            </small>
                          )}
                          {e.dob || e.gender ? ")" : ""}
                        </Option>
                      );
                    })}
                  <Option key="new" value="new">
                    Add New Contact
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {addNewContactVar && (
            <>
              <Row>
                <Col>
                  <h4 className="text-dark">Add Contact Details</h4>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                  <Form.Item
                    name={"first_name"}
                    label="First Name"
                    rules={[
                      { required: true, message: "Please enter first name" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                  <Form.Item
                    name={"last_name"}
                    label="Last Name"
                    rules={[
                      { required: true, message: "Please enter last name" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                  <Form.Item
                    name={"gender_id"}
                    label="Gender"
                  // rules={[
                  //   { required: true, message: "Please select Gender" },
                  // ]}
                  >
                    <Select>
                      {gender.map((e: any) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {formatDropDownValue(e.name)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                  <Form.Item
                    name={"email_id"}
                    label="Email ID"
                  // rules={[
                  //   { required: true, message: "Please enter Email Id" },
                  // ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                  <Form.Item
                    name={"dob"}
                    label="D.O.B."
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select date of birth",
                  //   },
                  // ]}
                  >
                    <DatePicker
                      disabledDate={(d: any) => {
                        return d > moment(moment().format("YYYY-MM-DD"));
                      }}
                      format="DD MMM'YY"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                  <Form.Item
                    name={"nationality_id"}
                    label={t("CRM.Common.Fields.Nationality")}
                    rules={[
                      { required: true, message: "Please select nationality" },
                    ]}
                  >
                    <Select>
                      {nationality.map((e: any) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {formatDropDownValue(e.name)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Divider></Divider>
          {/* <Row gutter={32}>
                    <Col>
                        <h4 className="text-dark">Select Service</h4>
                    </Col>
                </Row> */}
          <Row gutter={32}>
            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              <Form.Item
                name={"svc_type_id"}
                label="Service Type"
                rules={[
                  { required: true, message: "Please select service type" },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  onChange={handleSVTypeChange}
                >
                  {taskTypesLead?.map((e: any) => {
                    return (
                      <Option key={e.id} value={e.id}>
                        {formatDropDownValue(e.name)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              <Form.Item
                name={"svc_sub_type_id"}
                label="Service Sub Type"
                rules={[
                  { required: true, message: "Please select service sub type" },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  onChange={handleSVSubTypeChange}
                >
                  {taskSubTypes.map((e: any) => {
                    return (
                      <Option key={e.id} value={e.id}>
                        {formatDropDownValue(e.name)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              {opportunitiesList && (
                <Form.Item name={"opportunities"} label="Opportunities">
                  <Select
                    mode="multiple"
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    placeholder={"Select Multiple"}
                  >
                    {opportunitiesList.map((e: any) => {
                      return (
                        <Option key={e.id} value={e.id}>
                          {formatDropDownValue(e.name)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
            </Col>
            {/* </Row>
          <Row gutter={32}> */}
            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              {leadSource && (
                <Form.Item
                  name={"source"}
                  label="Source"
                  rules={[{ required: true, message: "Please select source" }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    disabled={
                      myPermissions?.indexOf("task_module") !== -1 ||
                        myPermissions?.indexOf(
                          TASK_MODULE_ACTIONS_DISABLE_SOURCE_IN_CREATE_LEAD
                        ) !== -1
                        ? true
                        : false
                    }
                  >
                    {leadSource.map((e: any) => {
                      return (
                        <Option key={e.id} value={e.id}>
                          {formatDropDownValue(e.name)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
            </Col>
            {myPermissions?.indexOf("task_module") !== -1 ||
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_BRANCH) !== -1 ? (
              ""
            ) : (
              <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                <Form.Item
                  name={"branch_id"}
                  label="Hospital"
                  rules={[
                    { required: true, message: "Please select hospital" },
                  ]}
                >
                  <Select showSearch allowClear optionFilterProp="children">
                    {branches.map((e: any) => {
                      return (
                        <Option key={e.id} value={e.id}>
                          <span className="capitalize">{e.name}</span>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {myPermissions?.indexOf("task_module") !== -1 ||
              myPermissions?.indexOf(TASK_MODULE_ACTIONS_HIDE_ATTACHMENT) !==
              -1 ? (
              ""
            ) : (
              <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
                <Form.Item name={"files"} label={"Select File"}>
                  <Button
                    disabled={
                      myPermissions?.indexOf("task_module") !== -1 ||
                        myPermissions?.indexOf(
                          TASK_MODULE_ACTIONS_DISABLE_ATTACHMENT
                        ) !== -1
                        ? true
                        : false
                    }
                    type={"default"}
                    onClick={showModal}
                    style={{ textDecoration: "underline" }}
                  >
                    {`${documentName
                      ? "Attached File: " + documentName
                      : "Add Attachment"
                      } `}
                  </Button>
                </Form.Item>
              </Col>
            )}

            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              {statusList && (
                <Form.Item
                  name={"status_id"}
                  label="Status"
                  rules={[{ required: true, message: "Please select status" }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    disabled={
                      myPermissions?.indexOf("task_module") !== -1 ||
                        myPermissions?.indexOf(
                          TASK_MODULE_ACTIONS_DISABLE_STATUS_IN_CREATE_LEAD
                        ) !== -1
                        ? true
                        : false
                    }
                  >
                    {statusList?.map((e: any) => {
                      if (formatDropDownValue(e.name) != "Follow-up") {
                        return (
                          <Option key={e.id} value={e.id}>
                            {formatDropDownValue(e.name)}
                          </Option>
                        );
                      }
                    })}
                  </Select>
                </Form.Item>
              )}
            </Col>
            {/* </Row>
          <Row gutter={32}> */}
            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              {priorityList && (
                <Form.Item
                  name={"priority_id"}
                  label="Priority"
                  rules={[
                    { required: true, message: "Please select priority" },
                  ]}
                >
                  <Select showSearch allowClear optionFilterProp="children">
                    {priorityList.map((e: any) => {
                      return (
                        <Option key={e.id} value={e.id}>
                          {formatDropDownValue(e.name)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
            </Col>
            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              <Form.Item name={"assign_to"} label="Assign To">
                <Select
                  allowClear
                  onChange={changeSelectedAssignType}
                  disabled={
                    myPermissions?.indexOf("task_module") !== -1 ||
                      myPermissions?.indexOf(
                        TASK_MODULE_ACTIONS_DISABLE_ASSIGN_TO_IN_CREATE_LEAD
                      ) !== -1
                      ? true
                      : false
                  }
                >
                  <Option key={"self"} value={"self"}>
                    Self
                  </Option>
                  <Option key={"agent"} value={"agent"}>
                    Agent
                  </Option>
                  <Option key={"team"} value={"team"}>
                    Team
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}>
              <Form.Item name={"assignee"} label="Assignee">
                <Select
                  disabled={
                    myPermissions?.indexOf("task_module") !== -1 ||
                      myPermissions?.indexOf(
                        TASK_MODULE_ACTIONS_DISABLE_ASSIGNEE_IN_CREATE_LEAD
                      ) !== -1
                      ? true
                      : false
                  }
                  showSearch={true}
                  optionFilterProp="children"
                  allowClear
                  filterOption={(input, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {assigneeList &&
                    assigneeList.map((e: any) => {
                      return (
                        <Option key={e.value} value={e.value}>
                          {formatDropDownValue(e.name)}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={24}>
              <Form.Item name={"lead_description"} label="Lead Description">
                <TextArea style={{ height: 100 }} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <GenericFormItems formElements={custom_fields} />
          </Row>
          <Row className="button-row">
            <Col span={24}>
              {/* <Row justify="center">
                <Col flex="none">
                  <Form.Item name={"consent"} required>
                    <Checkbox checked={true} disabled>
                      <span className="text-dark">
                        I have taken verbal consent of the patient to access
                        his/her Wockhardt Health Records
                      </span>
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row> */}
              <Form.Item name={"submit"}>
                <Button
                  loading={formLoading}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Upload Document"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <UploadPrescription
          getDescription={getDescription}
          fileUploaded={fileUploaded}
          handleCancel={handleCancel}
        />
      </Modal>
      {modalVisible && (
        <ResultModalLayout
          type="success"
          modalVisible={modalVisible}
          message=""
          setModalVisible={onCancelModal}
          title={"Lead #" + leadData?.display_id + " created"}
          subtitle=""
        />
      )}
    </>
  );
}
export default CreateLeadForm;
