import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  autoLoginTpt,
  fetchAccountByDomain,
  fetchApplications,
  fetchMyPermissions,
  fetchSelf,
  setToken,
  switchApplication,
} from "../../redux/reducers/auth.slice";
import { fetchProfile } from "../../redux/reducers/profile.slice";
import { RootState } from "../../shared/constants";
import { getAllowedPageByPermissions } from "../../shared/layouts/crm.sidebar";
import { _BASE_URL } from "../../shared/Utils/api-client";
import { getURLParams } from "../../shared/Utils/utilities";

function AuoLoginTPTPage() {
  const urlParams = getURLParams();
  const RouteParams: any = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { account, applications } = useSelector(
    (state: RootState) => state.auth
  );
  const [baseUrl, setBaseUrl] = useState(_BASE_URL);
  useEffect(() => {
    if (urlParams?.token) {
      dispatch(fetchAccountByDomain());
    }
  }, []);
  useEffect(() => {
    account?.id && localStorage.setItem("account_id", account?.id);
    account?.slug && localStorage.setItem("account_slug", account?.slug);
    account?.id && dispatch(fetchApplications());
  }, [account]);
  useEffect(() => {
    if (applications?.length > 0 && account?.id) {
      const application = applications?.filter((val: any) => {
        return val.slug == "crm" && val.account_id === account?.id;
      });

      if (application?.length > 0) {
        dispatch(
          switchApplication({
            slug: application[0].slug,
            application_id: application[0].id,
          })
        );
        localStorage.setItem("auth-type", "local");
        initLogin();
      }
    }
  }, [applications]);
  async function initLogin() {
    if (urlParams?.token) {
      const res1 = await dispatch(autoLoginTpt({ tptToken: urlParams?.token }));
      if (res1?.payload?.data?.data?.access_token) {
        await dispatch(setToken(res1?.payload?.data?.data?.access_token));
        await dispatch(fetchSelf());
        await dispatch(fetchProfile("users"));
        const permissionsRes = await dispatch(fetchMyPermissions());
        const allowedPages = getAllowedPageByPermissions(
          permissionsRes?.payload?.data?.data?.scope_values?.actions
        );
        if (allowedPages?.length > 1 && allowedPages?.[0]?.key == "chatbot") {
          history.push(allowedPages[1]?.route);
          return;
        }
        allowedPages?.length > 0 && history.push(allowedPages[0]?.route);
      }
    }
  }
  return (
    <Row justify="center" align="middle" style={{ height: "90vh" }}>
      <Col>
        <Spin size="large" />
      </Col>
    </Row>
  );
}

export default AuoLoginTPTPage;
