import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
} from "antd";
import { useForm } from "antd/es/form/Form";
import Title from "antd/lib/typography/Title";
import { Auth } from "aws-amplify";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchMyPermissions,
  fetchSelf,
  setToken,
  setUser,
} from "../../../../redux/reducers/auth.slice";
import { fetchProfile } from "../../../../redux/reducers/profile.slice";
import { RootState } from "../../../../shared/constants";
import { ROUTE_CRM_TICKET_LIST } from "../../../../shared/routes/crm.routes.constants";
import { countryCodes } from "../../../../shared/Utils/dial-codes";
import { checkEmailOrMobile } from "../../../../shared/Utils/utilities";
import SignupConfirmForm from "./SignupConfirmForm";
import SignupForm from "./SignupForm";

export const CognitoPage = () => {
  const { account, applications } = useSelector(
    (state: RootState) => state.auth
  );

  const RouteParams: any = useParams();
  const history: any = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState("login");
  const [form] = useForm();
  const [formFP] = useForm();
  const [loggingIn, setLoggingIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+91" as string);
  const [credentialsData, setCredentialsData] = useState({} as any);
  const [userData, setUserData] = useState(null as any);
  const [username, setUsername] = useState("");
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  const attemptLogin = async (credentials: any) => {
    setLoggingIn(true);

    const checkUsername = checkEmailOrMobile(credentials?.username);

    if (
      credentials?.username.indexOf("+") === -1 &&
      checkUsername === "mobile"
    ) {
      credentials.username = "+91" + credentials.username;
    }

    try {
      const data = await Auth.signIn(
        credentials.username,
        credentials.password
      );

      if (
        data &&
        data.signInUserSession &&
        data.signInUserSession.accessToken &&
        data.signInUserSession.accessToken.jwtToken
      ) {
        localStorage.setItem("auth-type", "cognito");
        await dispatch(setToken(data.signInUserSession.idToken.jwtToken));
        const exp = data.signInUserSession?.idToken?.payload?.exp;
        const iat = data.signInUserSession?.idToken?.payload?.iat;
        sessionStorage.setItem("cognito-validity", exp);
        sessionStorage.setItem("cognito-timestamp", iat);
        sessionStorage.setItem("cognito-refresh-times", "0");

        dispatch(setUser(data));
        await dispatch(fetchSelf());
        await dispatch(fetchMyPermissions());
        const response = await dispatch(fetchProfile("users"));
        let dashboardRoute = ROUTE_CRM_TICKET_LIST;
        if (
          response.payload.status === 201 ||
          response.payload.status === 200
        ) {
          if (RouteParams["source"] === "crm") {
            dashboardRoute = ROUTE_CRM_TICKET_LIST;
          }
          const redirectUrl = localStorage.getItem("redirect_url") || "";
          if (redirectUrl && redirectUrl != "") {
            localStorage.removeItem("redirect_url");
            dashboardRoute = redirectUrl;
          }
          window.location.href = dashboardRoute;
        }
        setLoggingIn(false);
      } else {
        if (data && data.challengeName === "NEW_PASSWORD_REQUIRED") {
          notification.info({ message: "Set new password" });
          setUserData(data);
          setCredentialsData(credentials);
          setPage("resetPassword");
        } else {
          notification.error({ message: "Unable to login" });
        }
        setLoggingIn(false);
      }
    } catch (e: any) {
      setLoggingIn(false);
      notification.error({ message: e.message || "Unable to login" });
    }
  };
  const resetPassword = async (passwords: any) => {
    setLoggingIn(true);
    try {
      await Auth.completeNewPassword(userData, passwords.password, {
        name: "Demo",
      });
      setLoggingIn(false);
      setPage("login");
      notification.info({
        message:
          "Password changed successfully! Please login again with new password",
      });
    } catch (e: any) {
      setLoggingIn(false);
      notification.error({ message: e.message || "Unable to change Password" });
    }
  };
  const forgotPasswordSendCode = async (credentials: any) => {
    setPage("forgetPasswordConfirm");
    return;
  };
  // const handleSendCode = async () => {
  //   try {
  //     await Auth.forgotPassword(email);
  //     setStep('confirmCode');
  //   } catch (err) {
  //     setError(err.message);
  //   }
  // };

  // const handleConfirmCode = async () => {
  //   try {
  //     await Auth.forgotPasswordSubmit(email, code, newPassword);
  //     alert("Password reset successful");
  //   } catch (err) {
  //     setError(err.message);
  //   }
  // };
  const prefixSelector = (
    <Form.Item name="country_code" noStyle initialValue={countryCode}>
      <Select
        showSearch
        disabled
        style={{ width: 100 }}
        value={countryCode}
        onChange={(evt) => setCountryCode(evt)}
        className={"noborder"}
      >
        {countryCodes.map((item: any) => {
          return (
            <Select.Option value={item.dial_code}>
              <Space>
                <span>{item.flag}</span>
                <span>{item.dial_code}</span>
              </Space>
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
  if (page === "signup") {
    if (username) {
      return (
        <>
          <SignupConfirmForm
            username={username}
            onVerify={async ({ code }) => {
              setLoading(true);
              try {
                const response = await Auth.confirmSignUp(username, code);
                if (response) {
                  notification.success({
                    message: "Register successfully! Please login now",
                  });
                  setPage("login");
                } else {
                  notification.error({ message: "Unable to confirm code" });
                }
                setLoading(false);
              } catch (e: any) {
                setLoading(false);
                notification.error({
                  message: e.message || "Unable to confirm code",
                });
              }
            }}
            loading={loading}
          />
          <Button onClick={() => setPage("login")}>Login</Button>
        </>
      );
    }
    return (
      <>
        <SignupForm
          onRegister={async ({
            username,
            password,
            phone_number,
            email,
            name,
          }) => {
            setLoading(true);
            try {
              const { user } = await Auth.signUp({
                username,
                password,
                attributes: {
                  email,
                  name,
                  phone_number,
                },
              });
              if (user) {
                notification.success({
                  message:
                    "You must have received a code on you mobile number " +
                    phone_number,
                });
                setUsername(username);
              } else {
                notification.error({ message: "Unable to register" });
              }
              setLoading(false);
            } catch (e: any) {
              setLoading(false);
              notification.error({
                message: e.message || "Unable to register",
              });
            }
          }}
          loading={loading}
        />
        <Button onClick={() => setPage("login")}>Login</Button>
      </>
    );
  }
  return (
    <>
      {page === "login" && (
        <>
          <Title
            className={screenType.desktop ? "mt50" : "mt20" + "  bold"}
            level={3}
          >
            Login with your Username and Password
          </Title>

          <Form
            name="number-login"
            className="login-form"
            form={form}
            onFinish={attemptLogin}
            autoComplete="off"
          >
            <Form.Item
              name="username"
              rules={[
                {
                  message: "Please input valid Username!",
                  required: true,
                },
              ]}
            >
              <Input
                size={"large"}
                type={"text"}
                style={{ pointerEvents: "auto", height: "60px" }}
                placeholder={"Enter Username"}
                className="noborderWrapper doctor-app-box-shadow height60px border-radius overflow-hidden"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  message: "Please input valid password!",
                  required: true,
                },
              ]}
            >
              <Input
                style={{ height: "60px" }}
                size={"large"}
                type={"password"}
                placeholder={"Enter Password"}
                className="noborderWrapper doctor-app-box-shadow height60px border-radius overflow-hidden paddingl20"
              />
            </Form.Item>
            <Row gutter={32} align="middle">
              <Col span={12}>
                {/* <a onClick={() => setPage("forgetPassword")}>
                  Forgot Password?
                </a> */}
              </Col>
              <Col span={12}>
                <Button
                  size="large"
                  className={`pd15 doctor-secondary-button hauto btn-shadow`}
                  block
                  loading={loggingIn}
                  htmlType={"submit"}
                  style={{ float: "right" }}
                >
                  Login
                </Button>
              </Col>
            </Row>
            {/* <Button onClick={() => setPage("signup")}>Register</Button> */}
          </Form>
        </>
      )}
      {page === "resetPassword" && (
        <>
          <Title
            className={screenType.desktop ? "mt50" : "mt20" + "  bold"}
            level={3}
          >
            Set New Password
          </Title>

          <Form
            name="number-login"
            className="login-form"
            form={formFP}
            onFinish={resetPassword}
            autoComplete="off"
          >
            <Form.Item
              name="password"
              rules={[
                {
                  message: "Please input valid password!",
                  required: true,
                },
              ]}
            >
              <Input
                size={"large"}
                type={"password"}
                placeholder={"Enter New Password"}
                style={{ height: "60px" }}
                className="noborderWrapper doctor-app-box-shadow height60px border-radius overflow-hidden paddingl20"
              />
            </Form.Item>
            <Form.Item
              name="confirm-password"
              rules={[
                {
                  message: "Please input valid password!",
                  required: true,
                },
              ]}
            >
              <Input
                size={"large"}
                type={"password"}
                placeholder={"Confirm New Password"}
                style={{ height: "60px" }}
                className="noborderWrapper doctor-app-box-shadow height60px border-radius overflow-hidden paddingl20"
              />
            </Form.Item>
            <Row gutter={32}>
              <Col span={12}></Col>
              <Col span={12}>
                <Button
                  size="large"
                  className={`pd15 doctor-secondary-button hauto btn-shadow`}
                  block
                  loading={loggingIn}
                  htmlType={"submit"}
                  style={{ float: "right" }}
                >
                  Reset Password
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
      {page === "forgetPassword" && (
        <>
          <Title
            className={screenType.desktop ? "mt50" : "mt20" + "  bold"}
            level={3}
          >
            Forgot Password
          </Title>

          <Form
            name="number-login"
            className="forgotpass-form"
            form={formFP}
            onFinish={forgotPasswordSendCode}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              name="username"
              label="Reset your password by entering your username"
              rules={[
                {
                  message: "Please input valid Username!",
                  required: true,
                },
              ]}
            >
              <Input
                size={"large"}
                type={"text"}
                style={{ pointerEvents: "auto", height: "60px" }}
                placeholder={"Enter Username"}
                className="noborderWrapper doctor-app-box-shadow height60px border-radius overflow-hidden"
              />
            </Form.Item>
            <Row gutter={32} align="middle">
              <Col span={12}>
                <a onClick={() => setPage("login")}>Back</a>
              </Col>
              <Col span={12}>
                <Button
                  size="large"
                  className={`pd15 doctor-secondary-button hauto btn-shadow`}
                  block
                  loading={loggingIn}
                  htmlType={"submit"}
                  style={{ float: "right" }}
                >
                  Send OTP
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
      {page === "forgetPasswordConfirm" && (
        <>
          <Title
            className={screenType.desktop ? "mt50" : "mt20" + "  bold"}
            level={3}
          >
            Reset Password
          </Title>

          <Form
            name="number-login"
            className="forgotpassconfirm-form"
            form={formFP}
            onFinish={forgotPasswordSendCode}
            autoComplete="off"
            layout="vertical"
          >
            {/* <Form.Item
            
              name="otp"
              label="Enter One Time Password sent to your mobile number/email"
              rules={[
                {
                  len: 6,
                  message: "Please enter valid OTP!",
                  required: true,
                },
              ]}
            >
              <Input
                size={"large"}
                type={"number"}
                style={{ pointerEvents: "auto" }}
                placeholder={"XXXXXX"}
                className="noborderWrapper doctor-app-box-shadow height60px border-radius overflow-hidden paddingl20"
              />
            </Form.Item> */}
            <Form.Item
              name="otp"
              className="mb-20"
              rules={[{ required: true, message: "Please input your OTP!" }]}
              label="Enter OTP sent to your mobile number/email"
            >
              <OtpInput
                // value={optValue}
                inputStyle={"ant-input-otp"}
                // onChange={(evt: any) => setOptValue(evt)}
                numInputs={6}
                isInputNum={true}
                separator={<span></span>}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Enter New Password"
              rules={[
                {
                  message: "Please input valid password!",
                  required: true,
                },
              ]}
            >
              <Input
                size={"large"}
                style={{ height: "60px" }}
                type={"password"}
                placeholder={"Enter New Password"}
                className="noborderWrapper doctor-app-box-shadow height60px border-radius overflow-hidden paddingl20"
              />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirm-password"
              rules={[
                {
                  message: "Please input valid password!",
                  required: true,
                },
              ]}
            >
              <Input
                style={{ height: "60px" }}
                size={"large"}
                type={"password"}
                placeholder={"Confirm New Password"}
                className="noborderWrapper doctor-app-box-shadow height60px border-radius overflow-hidden paddingl20"
              />
            </Form.Item>
            <Row gutter={32} align="middle">
              <Col span={12}></Col>
              <Col span={12}>
                <Button
                  size="large"
                  className={`pd15 doctor-secondary-button hauto btn-shadow`}
                  block
                  loading={loggingIn}
                  htmlType={"submit"}
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};
export default CognitoPage;
