import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Modal, Row, Select, Space, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchMasterTaskDropDown } from "../../../../redux/reducers/master.slice";
import { addTaskCategory, fetchLabelList, fetchTaskCategory, fetchTaskSubTypeById, fetchTaskType } from '../../../../redux/reducers/metadata.slice';
import { checkString, formatDropDownValue } from "../../../../shared/Utils/utilities";
import { RootState } from '../../../../shared/constants';


const { Option } = Select;
type Props = {
    onSaveContact: (val: any) => void,
    onEditContact: (val: any) => void,
    onSaveContactFailed: (val: any) => void,
    modalVisible: boolean,
    setmodalVisible: (val: any) => void,
    formLoading: boolean,
    gender: any,
    nationality: any
    typess: any
    initialValues?: any
    editData: any
}

function CreateAction({ editData, typess, onSaveContact, onEditContact, onSaveContactFailed, modalVisible, setmodalVisible, formLoading, gender, nationality, initialValues }: Props) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();


    const [mode, setmode] = useState(typess === 'edit' ? editData?.mode : '')
    const [taskTypeName, settaskTypeName] = useState('')
    const [taskSubTypeName, settaskSubTypeName] = useState('')
    const [teamTypeRadio, setteamTypeRadio] = useState('teamType')
    const [taskTypeArray, settaskTypeArray] = useState([] as any)
    const [taskSubTypeArray, settaskSubTypeArray] = useState([] as any)
    const [taskCategoryArray, settaskCategoryArray] = useState([] as any)
    let { loading, metadata, pagination } = useSelector((state: RootState) => state.metadata);
    const { taskType } = useSelector((state: RootState) => state.metadata);
    const { taskSubTypeById } = useSelector((state: RootState) => state.metadata);
    const { taskCategory } = useSelector((state: RootState) => state.metadata);

    const [newNameType, setnewNameType] = useState(null as any)
    const [newNameSubType, setnewNameSubType] = useState(null as any)
    const [newNameCategory, setnewNameCategory] = useState(null as any)
    const [modeType, setmodeType] = useState(null as any)
    const { taskDropDown } = useSelector((state: RootState) => state.master);
    const [opportunitiesList, setOpportunitiesList] = useState([] as any);



    const [typeId, settypeId] = useState(null as any)
    const [subTypeId, setsubTypeId] = useState(null as any)


    const [tagsStateUI, settagsStateUI] = useState({
        tags: [] as any,
        inputVisible: false,
        inputValue: '',
    })

    const [tagsStateComm, settagsStateComm] = useState({
        tagsComm: [] as any,
        inputVisibleComm: false,
        inputValueComm: '',
    })

    console.log("editsss", editData)

    initialValues = {
        mode: typess === 'edit' ? editData?.mode : null,
        type_id: typess === 'edit' ? editData?.type_id : null,
        sub_type_id: typess === 'edit' ? editData?.sub_type_id : null,
        category_id: typess === 'edit' ? editData?.category_id : null,
        action_name: typess === 'edit' ? editData?.action_name : null,
        event_name: typess === 'edit' ? editData?.event_name : null,
        display_name: typess === 'edit' ? editData?.display_name : null,
        ui_actions_dropdown: typess === 'edit' ? editData?.ui_actions_dropdown : [],
        comm_actions: typess === 'edit' ? editData?.comm_actions : [],
        custom_fields: editData?.custom_fields ? JSON.stringify(editData?.custom_fields) : null,
        opportunities: typess === 'edit' ? editData?.opportunities?.map((val: any) => {
            return val.opportunity_id;
        }) : [],


    }

    useEffect(() => {
        if (typess === 'edit') {
            settagsStateUI({
                ...tagsStateUI,
                tags: editData?.ui_actions_dropdown,
            })
            settagsStateComm({
                ...tagsComm,
                tagsComm: editData?.comm_actions
            });

        }
    }, [typess])


    console.log("ccccccccccccc", taskTypeArray)

    const onTypeNameChange = (event: any) => {
        setnewNameType(event.target.value);
    };
    const onSubTypeNameChange = (event: any) => {
        setnewNameSubType(event.target.value);
    };
    const onCategoryNameChange = (event: any) => {
        setnewNameCategory(event.target.value);
    };

    // const onSelectType = (value: any) => {
    //     console.log("vvvvvvvvv", value)
    //     settypeId(value)
    // }

    // const addType = (e: any) => {
    //     e.preventDefault();
    //     if (mode && checkString(newNameType)) {
    //         const newTaskName = newNameType.replace(" ", "_");
    //         newTaskName && mode && dispatch(addTaskType({ name: newTaskName, mode: mode })).then((val: any) => {
    //             if (val.payload.data.statusCode === 200) {
    //                 settaskTypeArray([...taskTypeArray, { id: val.payload.data.data.id, name: newTaskName, mode: mode }])
    //                 setnewNameType(null as any);
    //             }
    //         })
    //     }

    // };


    // const addSubType = (e: any) => {
    //     e.preventDefault();
    //     if (typeId && checkString(newNameSubType)) {
    //         const newTaskName = newNameSubType.replace(" ", "_");
    //         newTaskName &&
    //             typeId && dispatch(addTaskSubType({ name: newTaskName, task_type_id: typeId })).then((val: any) => {
    //                 if (val.payload.data.statusCode === 200) {
    //                     settaskSubTypeArray([...taskSubTypeArray, { id: val.payload.data.data.id, name: newTaskName }])
    //                     setnewNameSubType(null as any);
    //                 }
    //             })
    //     }

    // };
    const addCategory = (e: any) => {
        e.preventDefault();
        if (subTypeId && checkString(newNameCategory)) {

        }
        const newTaskName = newNameCategory.replace(" ", "_");
        subTypeId && dispatch(addTaskCategory({ name: newTaskName, task_sub_type_id: subTypeId })).then((val: any) => {
            if (val.payload.data.statusCode === 200) {
                settaskCategoryArray([...taskCategoryArray, { id: val.payload.data.data.id, name: newTaskName }])
                setnewNameCategory(null as any);
            }
        })
    };
    const handleChangeMode = (value: any) => {
        setmode(value)
        if (value !== editData?.mode) {
            form.setFieldsValue({ type_id: null });
            form.setFieldsValue({ source_id: null });
            form.setFieldsValue({ sub_type_id: null });
            form.setFieldsValue({ category_id: null });
        }
        else {
            form.setFieldsValue({ type_id: editData?.type_id });
            form.setFieldsValue({ source_id: editData?.source_id });
            form.setFieldsValue({ sub_type_id: editData?.sub_type_id });
            form.setFieldsValue({ category_id: editData?.category_id });
        }

    }

    const handleChangeTaskType = (value: any) => {
        value && dispatch(fetchTaskSubTypeById({ id: value }));
        settypeId(value)
        if (value !== editData?.type_id) {
            form.setFieldsValue({ sub_type_id: null });
            form.setFieldsValue({ category_id: null });
        }
        else {
            form.setFieldsValue({ sub_type_id: editData?.sub_type_id });
            form.setFieldsValue({ category_id: editData?.category_id });
        }

    }
    const handleChangeTaskSubType = (value: any) => {
        value && dispatch(fetchTaskCategory({ id: value }));
        setsubTypeId(value)
        if (value !== editData?.sub_type_id) {
            form.setFieldsValue({ category_id: null });
        }
        else {
            form.setFieldsValue({ category_id: editData?.category_id });
        }
    }


    const handleCloseModal = () => {
        setmodalVisible(false)
    }


    const handleClose = (removedTag: any, type: any) => {
        if (type === 'UITags') {
            const tags = tagsStateUI?.tags?.filter((tag: any) => tag !== removedTag);
            settagsStateUI({ ...tagsStateUI, tags })
        }
        else {
            const tagsComm = tagsStateComm?.tagsComm?.filter((tag: any) => tag !== removedTag);
            settagsStateComm({ ...tagsStateComm, tagsComm })
        }

    }
    const showInput = (e: any, type: any) => {
        if (type === 'UITags') {
            settagsStateUI({ ...tagsStateUI, inputVisible: true })
        }
        else {
            settagsStateComm({ ...tagsStateComm, inputVisibleComm: true })
        }

    }

    const handleInputChange = (e: any, type: any) => {
        if (type === 'UITags') {
            settagsStateUI({ ...tagsStateUI, inputValue: e.target.value });
        }
        else {
            settagsStateComm({ ...tagsStateComm, inputValueComm: e.target.value });
        }


    }

    const handleInputConfirm = (e: any, type: any) => {
        if (type === 'UITags') {
            const stateUi = { ...tagsStateUI };
            const inputValueUi = stateUi.inputValue;
            let tags = stateUi.tags;

            if (inputValueUi && tags.indexOf(inputValueUi) === -1) {
                tags = [...tags, inputValueUi];
            }
            settagsStateUI({
                tags,
                inputVisible: false,
                inputValue: '',
            });

        }
        else {
            const stateComm = { ...tagsStateComm };
            const inputValueCom = stateComm.inputValueComm;
            let tagsComm = stateComm.tagsComm;

            if (inputValueCom && tagsComm.indexOf(inputValueCom) === -1) {
                tagsComm = [...tagsComm, inputValueCom];
            }
            settagsStateComm({
                tagsComm,
                inputVisibleComm: false,
                inputValueComm: '',
            });
        }

    }

    console.log("tagsComm", typess, editData)

    useEffect(() => {
        if (typess === 'edit') {
            editData?.type_id && dispatch(fetchTaskSubTypeById({ id: editData && editData?.type_id ? editData?.type_id : '' }));
            editData && editData.sub_type_id ? dispatch(fetchTaskCategory({ id: editData.sub_type_id })) : editData?.type_id && dispatch(fetchTaskSubTypeById({ id: editData && editData.type_id ? editData.type_id : '' }));
        }
    }, [typess, editData, mode])


    useEffect(() => {
        settaskTypeArray(taskType?.filter((item: any) => {
            return item.mode === mode
        }))
    }, [taskType, mode])
    useEffect(() => {
        taskSubTypeById.length > 0 && settaskSubTypeArray(taskSubTypeById)
    }, [taskSubTypeById])

    useEffect(() => {
        taskCategory.length > 0 && settaskCategoryArray(taskCategory)
    }, [taskCategory])



    useEffect(() => {
        dispatch(fetchLabelList({}));
        dispatch(fetchTaskType({}));
        setmode(typess === 'edit' ? editData?.mode : '')
    }, [typess])

    useEffect(() => {
        !taskDropDown?.length && dispatch(fetchMasterTaskDropDown());
    }, [])


    useEffect(() => {
        const temp = taskDropDown.filter((val: any) => {
            return val.name == "opportunity" && val.mode == "lead";
        });
        if (temp.length > 0) {
            setOpportunitiesList(
                temp[0].values.map((val: any) => {
                    return { name: formatDropDownValue(val.name), value: val.id };
                })
            );
        }
    }, [taskDropDown])



    const { tags, inputVisible, inputValue } = tagsStateUI;
    const { tagsComm, inputVisibleComm, inputValueComm } = tagsStateComm;

    const onSaveContactLocal = (e: any) => {
        onSaveContact({ ...e, tagsStateUI, tagsStateComm, custom_fields: e.custom_fields ? JSON.parse(e.custom_fields) : null })
    }
    const onEditContactLocal = (e: any) => {
        onEditContact({ ...e, tagsStateUI, tagsStateComm, custom_fields: e.custom_fields ? JSON.parse(e.custom_fields) : null })
    }
    return (
        <Modal
            title={typess === 'edit' ? "Edit Action Details" : "Action Details"}
            centered
            visible={modalVisible}
            onCancel={() => handleCloseModal()}
            // width={1000}
            footer={null}
            className="modalFormButton"
            style={{ paddingBottom: '0px' }}
            destroyOnClose={true}
        >
            <Form
                form={form}
                className="paddingx20 paddingtop20 crm-form full-width"
                initialValues={ initialValues }
                onFinish={typess === 'edit' ? onEditContactLocal : onSaveContactLocal}
                onFinishFailed={onSaveContactFailed}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item name='mode' label="Mode" rules={[{ required: true, message: 'Please choose Mode' }]}>
                            <Select showSearch={true}
                                optionFilterProp="children"
                                allowClear
                                onChange={handleChangeMode}>
                                <Option value="ticket">Ticket</Option>
                                <Option value="lead">Lead</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name='type_id' label="Type" rules={[{ required: true, message: 'Please choose Type' }]}>
                            <Select
                                showSearch={true}
                                optionFilterProp="children"
                                allowClear
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider
                                            style={{
                                                margin: '8px 0',
                                            }}
                                        />
                                        <Space
                                            style={{
                                                padding: '0 8px 4px',
                                            }}
                                        >
                                            <Input
                                                placeholder="Please enter item"
                                                value={newNameType}
                                                onChange={onTypeNameChange}
                                            />

                                        </Space>
                                    </>
                                )}
                                // defaultValue={[editData?.type_id]}
                                onChange={handleChangeTaskType}>
                                {
                                    taskTypeArray?.map((val: any) => {
                                        return <Option value={val.id}>{val.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name='sub_type_id' label="Sub Type" rules={[{ required: true, message: 'Please choose Subtype' }]}>
                            <Select showSearch={true}
                                optionFilterProp="children"
                                allowClear
                                // defaultValue={[editData?.sub_type_id]}
                                onChange={handleChangeTaskSubType}
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider
                                            style={{
                                                margin: '8px 0',
                                            }}
                                        />
                                        <Space
                                            style={{
                                                padding: '0 8px 4px',
                                            }}
                                        >
                                            <Input
                                                placeholder="Please enter item"
                                                value={newNameSubType}
                                                onChange={onSubTypeNameChange}
                                            />

                                        </Space>
                                    </>
                                )}
                            >

                                {
                                    taskSubTypeById?.map((val: any) => {
                                        return <Option value={val.id}>{val.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={24}>
                        {
                            mode && mode === 'ticket' ? <>
                                <Form.Item name='category_id' label="Category" rules={[{ required: true, message: 'Please choose Category' }]}>
                                    <Select showSearch={true}
                                        optionFilterProp="children"
                                        allowClear
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider
                                                    style={{
                                                        margin: '8px 0',
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: '0 8px 4px',
                                                    }}
                                                >
                                                    <Input
                                                        placeholder="Please enter item"
                                                        value={newNameCategory}
                                                        onChange={onCategoryNameChange}
                                                    />
                                                    <Button type="text" icon={<PlusOutlined />} onClick={addCategory}>
                                                        Add Category
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        {
                                            taskCategory?.map((val: any) => {
                                                return <Option value={val.id}>{val.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item></> : <></>
                            // <Form.Item name={"opportunities"} label="Opportunities">
                            //     <Select
                            //         mode="multiple"
                            //         showSearch
                            //         optionFilterProp="children"
                            //         allowClear
                            //         placeholder={"Select Multiple"}
                            //     >
                            //         {opportunitiesList.map((e: any) => {
                            //             return (
                            //                 <Option key={e.value} value={e.value}>
                            //                     {formatDropDownValue(e.name)}
                            //                 </Option>
                            //             );
                            //         })}
                            //     </Select>
                            // </Form.Item>

                        }

                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name='action_name' label="Action Name">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item name='event_name' label="Event Name">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name='display_name' label="Display Name">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>

                    <Col span={24}>
                        <div>
                            <Form.Item label='UI Actions'>
                                {tags?.map((tag: any, index: any) => {
                                    const tagElem = (
                                        <Tag key={tag} closable onClose={() => handleClose(tag, 'UITags')} >
                                            {tag}
                                        </Tag>
                                    );
                                    return tagElem;
                                })}
                            </Form.Item>
                            {inputVisible && (
                                <Input
                                    // ref={saveInputRef}
                                    type="text"
                                    size="small"
                                    style={{ width: 78 }}
                                    value={inputValue}
                                    onChange={(e) => { handleInputChange(e, 'UITags') }}
                                    onBlur={(e) => { handleInputConfirm(e, 'UITags') }}
                                    onPressEnter={(e) => { handleInputConfirm(e, 'UITags') }}
                                    autoFocus
                                />
                            )}
                            {!inputVisible && <Button size="small" type="dashed" onClick={(e) => { showInput(e, 'UITags') }} >+ New UI Action</Button>}
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <div>
                            <Form.Item label='Communication Actions'>
                                {tagsComm?.map((tag: any, index: any) => {
                                    const tagElem = (
                                        <Tag key={tag} closable onClose={() => handleClose(tag, 'CommTags')} >
                                            {tag}
                                        </Tag>
                                    );
                                    return tagElem;
                                })}
                            </Form.Item>
                            {inputVisibleComm && (
                                <Input
                                    // ref={saveInputRef}
                                    type="text"
                                    size="small"
                                    style={{ width: 78 }}
                                    value={inputValueComm}
                                    onChange={(e) => { handleInputChange(e, 'CommTags') }}
                                    onBlur={(e) => { handleInputConfirm(e, 'CommTags') }}
                                    onPressEnter={(e) => { handleInputConfirm(e, 'CommTags') }}
                                    autoFocus
                                />
                            )}
                            {!inputVisibleComm && <Button size="small" type="dashed" onClick={(e) => { showInput(e, 'CommTags') }} >+ New Comm. Action</Button>}
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <Form.Item name='custom_fields' label="Custom Fields JSON">
                            <Input.TextArea rows={10} />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row className="button-row">
                    <Col span={24}>
                        <Form.Item>
                            <Button loading={loading} size='large' type='primary' htmlType="submit" block> {typess === 'edit' ? `Update` : `Save`} </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {/* <FormBar name={'CreateAction'} loading={formLoading} onFinish={formElements.onFinish} onFinishFailed={formElements.onFinishFailed} formElements={formElements.formElements} initialValues={formElements.initialValues} buttonText={'Save'}></FormBar> */}
        </Modal>
    );
}

export default CreateAction;
