import {
  EditOutlined,
  FileSearchOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  PageHeader,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  message,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import {
  createKnowledgeBank,
  fetchKnowledgeBankList,
  fetchKnowledgeBankModels,
  updateKnowledgeBank,
} from "../../../redux/reducers/knowledge-bank.slice";
import { formatDate } from "../../../shared/Utils/utilities";
import TableLayout from "../../../shared/layouts/table.layout";
import { ROUTE_CRM_KNOWLEDGE_BANK_DOCUMENTS } from "../../../shared/routes/crm.routes.constants";
const { Panel } = Collapse;
function KnowledgeBankListPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [listData, setListData] = useState([] as any);
  const [modelsList, setModelsList] = useState([] as any);
  const [promptsList, setPromptsList] = useState([] as any);
  const [newBankModal, setNewBankModal] = useState(false as any);
  const [selectedBank, setSelectedBank] = useState(null as any);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    total: 0,
  });
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  useEffect(() => {
    loadList();
    dispatch(fetchKnowledgeBankModels())?.then((val: any) => {
      if (val?.payload?.data?.members) {
        setModelsList(val?.payload?.data?.members);
      }
    });
    // dispatch(fetchKnowledgeBankPrompts())?.then((val: any) => {
    //   if (val?.payload?.data) {
    //     setPromptsList(val?.payload?.data);
    //   }
    // });
  }, []);
  const loadList = () => {
    dispatch(fetchKnowledgeBankList()).then((val: any) => {
      if (val?.payload?.data?.data) {
        setListData(val?.payload?.data?.data);
        setPagination({
          offset: 0,
          limit: 10,
          total: val?.payload?.data?.data?.length,
        });
      }
    });
  };
  //   const [columns, setColumns] = useState([] as any);
  //   const [filters, setFilters] = useState({} as any);
  const columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (a: any) => {
        return a?.map((val: any) => (
          <>
            <Tag>{val}</Tag>
          </>
        ));
      },
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (a: any) => formatDate(a),
    },
    {
      title: "Enabled",
      dataIndex: "disabled",
      key: "disabled",
      render: (a: any) => (a ? "No" : "Yes"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: any) => (
        <>
          <Space>
            <Tooltip title="View Documents">
              <Button
                onClick={() => {
                  history.push(
                    ROUTE_CRM_KNOWLEDGE_BANK_DOCUMENTS(
                      record?.name,
                      record?.bank_id
                    )
                  );
                }}
                type="link"
                icon={<FileSearchOutlined />}
              ></Button>
            </Tooltip>
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() => {
                  setSelectedBank(record);
                  setNewBankModal(true);
                }}
                icon={<EditOutlined />}
              ></Button>
            </Tooltip>
            {/* <Tooltip title="Add New Document">
              <Button type="link" icon={<FileAddOutlined />}></Button>
            </Tooltip> */}
          </Space>
        </>
      ),
    },
  ];
  const loading = false;

  //   const listData = [] as any;
  const formElements = {
    onFinish: () => {},
    onFinishFailed: () => {},
    onValuesChange: () => {},

    filtersApplied: 0,
    initialValues: {},
    setValues: {} as any,
    formElements: [],
  };

  const onCreateKnowledgeBase = (values: any) => {
    const payload = {
      ...values,
      // knowledge_type: "document",
      tags: values?.tags && values?.tags !== "" ? values?.tags?.split(",") : [],
      // disabled: false,
    };
    dispatch(createKnowledgeBank(payload)).then((val: any) => {
      if (val?.payload?.data) {
        setNewBankModal(false);
        setSelectedBank(null);
        message.success("Knowledge Bank Created Successfully");
        loadList();
      }
    });
  };

  const onUpdateKnowledgeBase = (values: any) => {
    const payload = {
      ...values,
      tags: values?.tags && values?.tags !== "" ? values?.tags?.split(",") : [],
      // knowledge_type: "document",
      // tags: [],
      // disabled: false,
    };
    dispatch(
      updateKnowledgeBank({ id: selectedBank?.bank_id, body: payload })
    ).then((val: any) => {
      if (val?.payload?.data) {
        setNewBankModal(false);
        setSelectedBank(null);
        message.success("Knowledge Bank Updated Successfully");
        loadList();
      }
    });
  };

  return (
    <>
      <Fragment>
        <PageHeader
          ghost={false}
          title={
            <Row
              style={screenType.mobile ? { paddingLeft: "25px" } : {}}
              align="middle"
            >
              <Col className="title-text">Knowledge Banks</Col>
            </Row>
          }
          extra={
            <Row align="middle">
              <Col className="title-text">
                <Button
                  key="1"
                  className="ant-btn-hover"
                  onClick={() => {
                    setNewBankModal(true);
                  }}
                >
                  Add New Bank
                </Button>
              </Col>
            </Row>
          }
        ></PageHeader>

        <Row className="padding10 paddingx20 ">
          <Col span={24}>
            {columns && (
              <TableLayout
                // defaultCurrentPage={pagination.offset / pagination.limit + 1}
                loading={loading}
                buttonEvt={() => {}}
                dataSource={listData}
                // filters="multiple"
                columns={columns}
                // onChange={(pageNumber, pageSize) =>
                //   onPageChange(pageNumber, pageSize)
                // }
                type="null"
                total={pagination.total}
                showTotal={true}
                pagination={{
                  position: ["topRight"],
                  pageSize: pagination.limit,
                }}
                // formData={formElements}
                maxWidth={1000}
                defaultPageSize={10}
                showSizeChangerFlag={false}
              />
            )}
          </Col>
        </Row>
        {newBankModal && (
          <Modal
            visible={newBankModal}
            onCancel={() => {
              setNewBankModal(false);
              setSelectedBank(null);
            }}
            footer={false}
            className="form-modal crm-form modalFormButton"
            title="Add New Knowledge Bank"
            width={750}
            style={{ paddingBottom: "0px" }}
          >
            <Form
              onFinish={(values: any) =>
                selectedBank
                  ? onUpdateKnowledgeBase(values)
                  : onCreateKnowledgeBase(values)
              }
              initialValues={{
                disabled: false,
                ...selectedBank,
                tags:
                  selectedBank?.tags?.length > 0
                    ? selectedBank?.tags?.join(",")
                    : null,
                knowledge_type: selectedBank?.knowledge_type || "document",
                section_delimitor:
                  selectedBank?.section_delimitor || "---section---",
                gpt_prompt_id: selectedBank?.gpt_prompt_id || 1,
              }}
              className="paddingx20 crm-form full-width cover"
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    label="Knowledge Base Name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="tags" label="Tags">
                    <Input placeholder="Tag1,Tag2,Tag3" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="knowledge_type"
                    label="Type"
                    rules={[{ required: true }]}
                  >
                    <Select>
                      <Select.Option value="document">Document</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="disabled"
                    label="Enabled"
                    rules={[{ required: true }]}
                  >
                    <Select>
                      <Select.Option value={false}>Yes</Select.Option>
                      <Select.Option value={true}>No</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true }]}
                  >
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
              <Collapse expandIconPosition="end">
                <Panel header="Advanced Settings" key={"1"}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name="model" label="Model">
                        <Select>
                          {modelsList?.length > 0 &&
                            modelsList?.map((val: any) => (
                              <Select.Option
                                value={val?.value
                                  ?.toLowerCase()
                                  .split("_")
                                  .join("-")}
                              >
                                {val?.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                      <Form.Item name="chunk_size" label="Chunk Size">
                        <InputNumber type="number" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col> */}
                    <Col span={12}>
                      <Form.Item
                        name="section_delimitor"
                        label={
                          <>
                            Section Delimiter&nbsp;&nbsp;&nbsp;
                            <Tooltip title="This delimiter will be used to separate sections in the document">
                              <InfoCircleFilled />
                            </Tooltip>
                          </>
                        }
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    {/* <Col span={24}>
                      <Form.Item name="gpt_prompt_id" label="Select Prompt">
                        <Select disabled={selectedBank}>
                          {promptsList?.length > 0 &&
                            promptsList?.map((val: any) => (
                              <Select.Option value={val?.id}>
                                {val?.prompt_text}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col> */}
                  </Row>
                </Panel>
              </Collapse>
              <Row className="button-row">
                <Col span={24}>
                  <Form.Item style={{ paddingTop: "20px" }}>
                    <Button type="primary" htmlType="submit" block>
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Fragment>
    </>
  );
}
export default KnowledgeBankListPage;
